import React, { useState, useEffect } from 'react';
import { useStore } from '../utils/Store';
import { Table, Card, Dimmer, Button, Grid, Header, Form, Alert, Icon, Dropdown } from 'tabler-react';
import Axios from 'axios';
import settings from '../app.config';
import Popup from 'reactjs-popup';
const Scheduler = {
    Home: () => {
        const [store, updateStore] = useStore();
        const [formBusy, setBusy] = useState(false);
        const [periods, setPeriod] = useState([{ timeFrom: '', timeTo: '' }]);
        const [showError, setError] = useState({ display: false, busy: false, type: 'danger', message: '' });
        const [showVerPop, setVerPop] = useState(false);
        const [allSlots, setSlots] = useState([]);
        const [activeSlot, setActiveSlot] = useState({ id: null, name: null, slots: [] });
        const [showSchedulerNamePop, setSchedulerNamePop] = useState(false);

        useEffect(() => {
            const el = document.getElementsByClassName('master-nav-list');
            for (const element in el) {
                const node = el[element];
                if (node.classList) { node.classList.remove('active'); }
            };

            const actEl = document.getElementById('master-nav-scheduler');
            if (actEl) { actEl.classList.add('active'); }



            Axios.get(settings.server + '/master/scheduler/slots/all')
                .then(res => {
                    if (res.data.success) {
                        setSlots(res.data.data);
                    }
                })
        }, []);

        useEffect(() => {
            if (showSchedulerNamePop) {
                saveChanges()
            }
            addNewSlot(false);
        }, [activeSlot]);

        function addPeriod() {
            const newp = { timeFrom: '', timeTo: '' };
            setPeriod([...periods, newp]);
        }

        function delRow(index) {
            const list = [...periods];
            list.splice(index, 1);
            setPeriod(list);
        }


        function updatePeriod(event) {
            const pos = event.name.split('-');
            if (pos[0] == 't1') {
                periods[pos[1]] = { ...periods[pos[1]], timeFrom: event.value };
            } else {
                periods[pos[1]] = { ...periods[pos[1]], timeTo: event.value };
            }

            setPeriod([...periods]);
        }

        function saveChanges() {
            if (formBusy) {
                return;
            }

            if (!activeSlot || !activeSlot.id) {
                addNewSlot(true);
                return;
            }

            setBusy(true);
            Axios.post(settings.server + '/master/scheduler/periods/update', { data: periods, id: activeSlot.id })
                .then(res => {
                    setBusy(false);
                    if (res.data.success) {
                        // fixme update current list
                        setError({ display: true, busy: false, type: 'success', message: 'Periods updated' });
                    } else {
                        setError({ display: true, busy: false, type: 'danger', message: res.data.message });
                    }

                }, err => {
                    setBusy(false);
                    setError({ display: true, busy: false, type: 'danger', message: 'Network Error. Try again' });
                });
        }

        function showVersions() {
            setVerPop(!showVerPop);
        }

        function addNewSlot(show = !showSchedulerNamePop) {
            setSchedulerNamePop(show);
        }

        function selectSlotTemplate(data) {
            let slots = [];

            if (data.slots && data.slots.length) {
                slots = data.slots.reduce((acc, val) => {
                    const time = val.time.split('-');
                    if (acc) { acc.push({ timeFrom: time[0].trim(), timeTo: time[1].trim() }); }
                    return acc;
                }, []);
            } else {
                slots = [{ timeFrom: '', timeTo: '' }];
            }
            setActiveSlot({ id: data.id, name: data.name });
            setPeriod(slots);
        }

        function getNewSlot(data) {
            setSlots([...allSlots, data]);
            selectSlotTemplate(data);
        }

        const setupSubjects = <Card>
            <Card.Header>
                <Card.Title>Distribute period timing</Card.Title>
                <Card.Options>
                    <Dropdown triggerContent="Select">
                        <Dropdown.Menu>
                            {
                                allSlots.map(item => <Dropdown.Item onClick={() => selectSlotTemplate(item)}>{item.name}</Dropdown.Item>)
                            }
                            <Dropdown.ItemDivider />
                            <Dropdown.Item onClick={() => addNewSlot()}>Add New</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Card.Options>
            </Card.Header>
            <Card.Body style={{ padding: 0 }}>
                <Dimmer active={formBusy} loader>
                    <Table>
                        <Table.Header>
                            <Table.ColHeader>Period</Table.ColHeader>
                            <Table.ColHeader>Start Time</Table.ColHeader>
                            <Table.ColHeader>End Time</Table.ColHeader>
                            <Table.ColHeader></Table.ColHeader>
                        </Table.Header>
                        <Table.Body>
                            {periods.map((period, key) =>
                                <Table.Row>
                                    <Table.Col>{key + 1}</Table.Col>
                                    <Table.Col>
                                        <Form.Input type="time" value={period.timeFrom} name={'t1-' + key} onChange={(event) => updatePeriod(event.target)} />
                                    </Table.Col>
                                    <Table.Col>
                                        <Form.Input type="time" value={period.timeTo} name={'t2-' + key} onChange={(event) => updatePeriod(event.target)} />
                                    </Table.Col>
                                    <Table.Col>
                                        {periods.length > 1 ? <Icon name="delete cursor" onClick={() => delRow(key)} /> : ''}
                                    </Table.Col>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Dimmer>
            </Card.Body>
            <Card.Footer>
                <Button color="success" disabled={formBusy} loading={formBusy} onClick={saveChanges}>Save</Button>
                <Button color="secondary" onClick={addPeriod}>Add more period</Button>
            </Card.Footer>
        </Card >;


        const view = (
            <Grid.Row cards deck justifyContent='center'>
                <Grid.Col md={6} >
                    <div>
                        {showError.display ? (<Alert type={showError.type} icon={showError.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                            {showError.message}
                        </Alert>) : ''}
                        {setupSubjects}
                    </div>
                </Grid.Col>
                <Grid.Col md={6} >
                    <Scheduler.Sections slots={allSlots} />
                </Grid.Col>
            </Grid.Row>
        );
        return <>
            <div className="title-bar mb-5">
                <div></div>
                <div>
                    <Button color="primary" onClick={showVersions}>
                        <Icon name="git" /> Manage Version
                    </Button>
                </div>
            </div>
            {view}
            {showVerPop ? <Scheduler.VersionControl close={showVersions} /> : ''}
            {showSchedulerNamePop ? <Scheduler.AddSlot close={() => addNewSlot(false)} success={getNewSlot} /> : ''}

        </>;
    },
    Sections: (props) => {
        const [store, updateStore] = useStore();
        const [sections, setSections] = useState([]);
        const [allSlots, setSlots] = useState([]);
        const [formBusy, setBusy] = useState(false);
        const [showError, setError] = useState({ display: false, busy: false, type: 'danger', message: '' });

        useEffect(() => {
            if (store && store.sections) {
                setSections(store.sections);
                if (props && props.slots && props.slots.length) {
                    setSlots(props.slots);
                }
            }
        }, []);

        useEffect(() => {
            if (props && props.slots && props.slots.length) {
                setSlots(props.slots);
            }
        }, [props.slots]);

        function updatePeriod(event, pos) {
            sections[pos] = { ...sections[pos], slot_id: event.value };

            setSections([...sections]);
        }

        function saveChanges() {
            if (formBusy) {
                return;
            }
            setBusy(true);
            Axios.post(settings.server + '/master/sections/update', { data: sections })
                .then(res => {
                    setBusy(false);
                    if (res.data.success) {
                        updateStore({ ...store, sections: res.data.data });
                        setError({ display: true, busy: false, type: 'success', message: 'Sections updated' });
                    } else {
                        setError({ display: true, busy: false, type: 'danger', message: res.data.message });
                    }

                }, err => {
                    setBusy(false);
                    setError({ display: true, busy: false, type: 'danger', message: 'Network Error. Try again' });
                });
        }

        const view = <div style={{ width: '100%' }}>
            {showError.display ? (<Alert type={showError.type} icon={showError.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                {showError.message}
            </Alert>) : ''}<Card>
                <Card.Header>
                    <Card.Title>Allocate duration with sections</Card.Title>
                </Card.Header>
                <Card.Body style={{ padding: 0 }}>
                    <Dimmer active={formBusy} loader>
                        <Table>
                            <Table.Header>
                                <Table.ColHeader>Section</Table.ColHeader>
                                <Table.ColHeader>TIme Slot</Table.ColHeader>
                            </Table.Header>
                            <Table.Body>
                                {sections.map((section, key) =>
                                    <Table.Row>
                                        <Table.Col>{section.name}</Table.Col>
                                        <Table.Col>
                                            <Form.Select name="slot" value={section.slot_id} onChange={(event) => updatePeriod(event.target, key)}>
                                                <option value=''>Select</option>
                                                {
                                                    allSlots.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)
                                                }
                                            </Form.Select>
                                        </Table.Col>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </Dimmer>
                </Card.Body>
                <Card.Footer>
                    <Button color="success" disabled={formBusy} loading={formBusy} onClick={saveChanges}>Save</Button>
                </Card.Footer>
            </Card></div>;

        return view;
    },
    VersionControl: ({ close }) => {
        const [showError, setError] = useState({ display: false, busy: false, type: 'danger', message: '' });

        const [form, setForm] = useState({ name: '', version: '', default: true });
        const [createNew, setNew] = useState(false);
        const [allVersions, setVersions] = useState([]);

        useEffect(() => {
            Axios.get(settings.server + '/master/versions/all')
                .then(res => {
                    if (res.data.success) {
                        res.data.data.map(item => item.active == 1 ? setForm({ ...form, version: item.id }) : '');
                        setVersions(res.data.data);
                    }
                })
        }, []);

        function handleChange(event) {
            const d = { ...form };
            d[event.target.name] = event.target.value;
            setForm(d);
        }

        function saveVersion() {
            setError({ ...setError, busy: true });
            Axios.post(settings.server + '/version/create', { name: form.name, default: form.default })
                .then(res => {
                    if (res.data.success) {
                        setError({ display: true, busy: false, type: 'success', message: 'Version added' });
                        setTimeout(() => close(), 1500);
                    } else {
                        setError({ display: true, busy: false, type: 'danger', message: 'Something went wrong. Please try again.' });
                    }
                }, err => {
                    setError({ display: true, busy: false, type: 'danger', message: 'Network Error' });
                });
        }

        function setDefault() {
            setError({ ...setError, busy: true });
            Axios.post(settings.server + '/master/version/default', { id: form.version })
                .then(res => {
                    if (res.data.success) {
                        setError({ display: true, busy: false, type: 'success', message: 'Default version updated' });
                        setTimeout(() => close(), 1500);
                    } else {
                        setError({ display: true, busy: false, type: 'danger', message: 'Something went wrong. Please try again.' });
                    }
                }, err => {
                    setError({ display: true, busy: false, type: 'danger', message: 'Network Error' });
                });

        }

        const createNewView =
            <Card>
                <Card.Header>
                    <Icon name="edit" /> <span className="card-label">Version Control</span>
                </Card.Header>
                <Card.Body>
                    <Grid.Row>
                        <Grid.Col>
                            {showError.display ? (<Alert type={showError.type} icon={showError.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                                {showError.message}
                            </Alert>) : ''}
                            <Form.Group label="Version name" isRequired>
                                <Form.Input name="name" value={form.name} onChange={handleChange.bind(this)} />
                            </Form.Group>
                        </Grid.Col>
                    </Grid.Row>
                </Card.Body>
                <Card.Footer>
                    <div>
                        <Button color="secondary" outline onClick={close}>Cancel</Button>
                        <Button color="success" disabled={showError.busy} loading={showError.busy} icon="save" onClick={() => saveVersion()}>Save</Button>
                    </div>
                </Card.Footer>
            </Card>;

        const selectVer = <Card>
            <Card.Header>
                <Icon name="edit" /> <span className="card-label">Version Control</span>
            </Card.Header>
            <Card.Body>
                <Grid.Row>
                    <Grid.Col>
                        {showError.display ? (<Alert type={showError.type} icon={showError.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                            {showError.message}
                        </Alert>) : ''}

                        <Form.Select name='version' value={form.version} onChange={handleChange.bind(this)}>
                            {
                                allVersions.map((item, i) => <option value={item.id} key={i}>{item.name}</option>)
                            }
                        </Form.Select>
                    </Grid.Col>
                </Grid.Row>
            </Card.Body>
            <Card.Footer>
                <div>
                    <Button color="secondary" outline onClick={close}>Cancel</Button>
                    <Button color="success" disabled={showError.busy} loading={showError.busy} icon="save" onClick={() => setDefault()}>Set Default</Button>
                </div>
                <div>
                    <Button color="primary" icon="plus" outline onClick={() => setNew(true)}>Add New</Button>
                </div>
            </Card.Footer>
        </Card>


        return <Popup open={true} closeOnDocumentClick={false} onClose={close}>
            <div className="modal">
                {createNew ? createNewView : selectVer}
            </div>
        </Popup>;
    },
    AddSlot: ({ close, success }) => {
        const [showError, setError] = useState({ display: false, busy: false, type: 'danger', message: '' });

        const [form, setForm] = useState({ name: '', version: '', default: true });

        function handleChange(event) {
            const d = { ...form };
            d[event.target.name] = event.target.value;
            setForm(d);
        }

        function saveVersion() {
            setError({ ...setError, busy: true });
            Axios.post(settings.server + '/master/scheduler/slots/create', { name: form.name, default: form.default })
                .then(res => {
                    if (res.data.success) {
                        setError({ display: true, busy: false, type: 'success', message: 'Slot added' });
                        setTimeout(() => success(res.data.data), 1000);
                    } else {
                        setError({ display: true, busy: false, type: 'danger', message: 'Something went wrong. Please try again.' });
                    }
                }, err => {
                    setError({ display: true, busy: false, type: 'danger', message: 'Network Error' });
                });
        }


        const createNewView =
            <Card>
                <Card.Header>
                    <Icon name="edit" /> <span className="card-label">Save Slots as Template</span>
                </Card.Header>
                <Card.Body>
                    <Grid.Row>
                        <Grid.Col>
                            {showError.display ? (<Alert type={showError.type} icon={showError.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                                {showError.message}
                            </Alert>) : ''}
                            <Form.Group label="Name" isRequired>
                                <Form.Input name="name" value={form.name} onChange={handleChange.bind(this)} />
                            </Form.Group>
                        </Grid.Col>
                    </Grid.Row>
                </Card.Body>
                <Card.Footer>
                    <div>
                        <Button color="secondary" outline onClick={close}>Cancel</Button>
                        <Button color="success" disabled={showError.busy} loading={showError.busy} icon="save" onClick={() => saveVersion()}>Save</Button>
                    </div>
                </Card.Footer>
            </Card>;

        return <Popup open={true} closeOnDocumentClick={false} onClose={close}>
            <div className="modal">
                {createNewView}
            </div>
        </Popup>;
    }
}

export default Scheduler;