import React from 'react';
import { Page, Icon, List } from 'tabler-react';

import './Styles.css';
import Class from './Class';
import Exams from './Exams';
import Subjects from './Subjects';

import {
    Switch,
    Route,
    useHistory, useRouteMatch
} from "react-router-dom";
import Teachers from './Teachers';
import Formulae from './Formulae';
import Scheduler from './Scheduler';
import Amenities from './Amenities';

const master = {
    Home: () => {
        let history = useHistory();
        let match = useRouteMatch();

        function openSection(path) {
            history.replace({ pathname: '/home' + path });
        }

        const items = [
            {
                name: 'Levels',
                id: 'master-nav-class',
                path: '/master/class',
                route: 'class',
                home: Class.Home
            },
            {
                name: 'Scheduler',
                id: 'master-nav-scheduler',
                path: '/master/scheduler',
                route: 'scheduler',
                home: Scheduler.Home
            },
            {
                name: 'Subjects',
                id: 'master-nav-subject',
                path: '/master/subjects',
                route: 'subjects',
                home: Subjects.Home
            },
            {
                name: 'Activities',
                id: 'master-nav-amenities',
                path: '/master/amenities',
                route: 'amenities',
                home: Amenities.Home
            },
            // {
            //     name: 'Exams',
            //     id: 'master-nav-exam',
            //     path: '/master/exams',
            //     route: 'exams',
            //      home: Exams.Home
            // },
            {
                name: 'Teachers',
                id: 'master-nav-teachers',
                path: '/master/teachers',
                route: 'teachers',
                home: Teachers.Home
            },
            // {
            //     name: 'Formulae',
            //     id: 'master-nav-formulae',
            //     path: '/master/formulae',
            //      route: 'formulae',
            //      home: Formulae.Home
            // }
        ]

        return (
            <div>
                <Page.Title>
                    <Icon name="globe" /> Manage Master
                </Page.Title>
                <div className="master-container">
                    <div>
                        <List.Group className="nav-list">
                            {items.map(item =>
                                <List.GroupItem action active key={item.id} id={item.id} className="item master-nav-list" onClick={() => openSection(item.path)}>
                                    {item.name} <Icon name="chevron-right" className="hover-icon" />
                                </List.GroupItem>
                            )
                            }
                        </List.Group>
                    </div>
                    <div className="contents">
                        <Switch>
                            {items.map(item =>
                                <Route path={`${match.path}/${item.route}`}>
                                    <item.home />
                                </Route>
                            )}
                            <Route path={match.path}>
                                <Class.Home />
                            </Route>
                        </Switch>
                    </div>
                </div>

            </div>
        )
    }


}

export default master;