import React, { useState } from 'react';
import { Card, Button, Form, Grid, Icon } from 'tabler-react';
import axios from 'axios';
import settings from '../app.config';

const CreateStudent = () => {
    const [currentStep, setStep] = useState(0);
    const [form, setForm] = useState({ busy: false, error: false, success: false, sid: '', firstname: '', lastname: '', dob: '07/23/1996', gender: 'Neutral' });
    let studentId = null;
    const createStudent = () => {
        if (form.busy) {
            return;
        }
        setForm({ ...form, busy: true });
        axios.post(settings.server + '/students/create', { sid: form.sid, firstname: form.firstname, lastname: form.lastname, dob: form.dob, gender: form.gender })
            .then(res => {
                setForm({ ...form, busy: false });
                if (res.data.success) {
                    studentId = res.data.data.id;
                    setStep(1);
                }
            }, err => {
                setForm({ ...form, busy: false });
            });
    };

    const updateStudent = () => {
        if (form.busy) {
            return;
        }
        setForm({ ...form, busy: true });
        axios.post(settings.server + '/students/update', { id: studentId, sid: form.sid, firstname: form.firstname, lastname: form.lastname, dob: form.dob, gender: form.gender })
            .then(res => {
                setForm({ ...form, busy: false });
                if (res.data.success) {
                }
            }, err => {
                setForm({ ...form, busy: false });
            });
    };

    function handleSID(event) {
        setForm({ ...form, sid: event.target.value });
    }
    function handleFirstname(event) {
        setForm({ ...form, firstname: event.target.value });
    }
    function handleLastname(event) {
        setForm({ ...form, lastname: event.target.value });
    }
    function handleDOB(event) {
        // setForm({ ...form, dob: event.target.value });
    }
    function handleGender(event) {
        setForm({ ...form, gender: event.target.value });
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title>
                    <Icon name="plus" /> Add New Student</Card.Title>
            </Card.Header>
            <Card.Body>
                <Form.FieldSet>
                    <Grid.Row>
                        <Grid.Col md={6}>
                            <Form.Group label="Unique Student Identification Number" isRequired>
                                <Form.Input name="first-name" value={form.sid} onChange={handleSID.bind(this)} />
                            </Form.Group>
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Col md={6}>
                            <Form.Group label="First name" isRequired>
                                <Form.Input name="first-name" value={form.firstname} onChange={handleFirstname.bind(this)} />
                            </Form.Group>
                        </Grid.Col>
                        <Grid.Col md={6}>
                            <Form.Group label="Last name" isRequired>
                                <Form.Input name="last-name" value={form.lastname} onChange={handleLastname.bind(this)} />
                            </Form.Group>
                        </Grid.Col>
                        <Grid.Col md={3}>
                            <Form.Group label="Gender" isRequired>
                                <Form.Select value={form.gender} onChange={handleGender.bind(this)}>
                                    <option>Neutral</option>
                                    <option>Female</option>
                                    <option>Male</option>
                                </Form.Select>
                            </Form.Group>
                        </Grid.Col>
                        <Grid.Col md={3}>
                            <Form.Group label="Date of birth" isRequired>
                                <Form.DatePicker name="dob" onChange={handleDOB.bind(this)} />
                            </Form.Group>
                        </Grid.Col>
                        <Grid.Col md={6}>
                            <Form.Group label="Mother's name">
                                <Form.Input name="mother-name" />
                            </Form.Group>
                        </Grid.Col>
                        <Grid.Col md={6}>
                            <Form.Group label="Father's name">
                                <Form.Input name="father-name" />
                            </Form.Group>
                        </Grid.Col>
                        <Grid.Col md={6}>
                            <Form.Group label="Guardian">
                                <Form.Input name="mother-name" />
                            </Form.Group>
                        </Grid.Col>
                    </Grid.Row>
                </Form.FieldSet>
            </Card.Body>
            {
                currentStep > 0 ? (<Card.Body>This is standard card footer</Card.Body>) : ''
            }

            <Card.Footer>
                <div className="edit-profile-footer">
                    <Button color="success" icon="save" disabled={form.busy} onClick={currentStep === 0 ? createStudent : updateStudent}>{currentStep === 0 ? 'Create' : 'Save Changes'}</Button>
                    <Button color="secondary">Cancel</Button>
                </div>
            </Card.Footer>
        </Card>
    );
}

export default CreateStudent;
