import React, { useState, useEffect } from 'react';
import { Card, Grid, Icon, Header, Form, Table, Button, Dimmer, Badge, Dropdown, Alert } from 'tabler-react';
import { useStore } from '../utils/Store';
import Axios from 'axios';
import settings from '../app.config';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { interval, of, switchMap } from 'rxjs';
import { Subscribe } from 'react-with-observable';
import FreePeriodTypeList from '../data/FreePeriodType';

const Schedule = {

    Index: () => {
        const [store, updateStore] = useStore();
        const [busy, setBusy] = useState(true);

        useEffect(() => {
            updateStore({ ...store, scheduler: {} });
            Axios.get(settings.server + '/batch/' + store.batch.id + '/scheduler')
                .then(res => {
                    //if (res.data.success) {
                    updateStore({ ...store, scheduler: res.data.data });
                    //}
                    setTimeout(() => setBusy(false));
                });
        }, [])

        const page = store.scheduler && store.scheduler.scheduler && store.scheduler.scheduler.length ? <Schedule.Preview data={store.scheduler} batch={store.batch.id} /> : <Schedule.Wizard scheduler={store.scheduler} />;

        return busy ? '' : page;
    },
    Wizard: ({ scheduler }) => {
        const [store, updateStore] = useStore();
        const [form, setForm] = useState({ days: 5, setInterval: 0, setIntervalName: '', setIntervalTutor: '', interval: [], version: null });
        const [formBusy, setBusy] = useState(false);
        const [subjects, setSubjects] = useState([]);

        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const daysMin = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

        function generateTable() {
            setBusy(true);
            Axios.post(settings.server + '/batch/scheduler/generate', { batch: store.batch.id, subjects, ...form, version: form.version })
                .then(res => {
                    setBusy(false);
                    if (res.data.success) {
                        updateStore({ ...store, scheduler: res.data.data });
                    }
                }, err => { setBusy(false); })
        }

        useEffect(() => {
            if (store.batch && store.batch.subjects) {
                // const hour = Math.ceil(form.hours / store.batch.subjects.length);
                const hour = form.days;
                let param = form;

                if (scheduler && scheduler.options) {
                    param = { ...param, interval: scheduler.options.interval };
                }

                if (scheduler && scheduler.version) {
                    param = { ...param, version: scheduler.version.id };
                }

                setForm(param);

                const data = store.batch.subjects.map(item => {
                    if (!scheduler.subjects) {
                        item.hour = hour;
                    } else {
                        const savedHrs = scheduler.subjects.filter(j => j.id == item.id);
                        if (savedHrs.length) {
                            item.hour = savedHrs[0].hour;
                        }
                    }
                    return item;
                });

                const d2 = store.batch.amenities.map(item => {
                    if (!scheduler.subjects) {
                        item.hour = hour;
                    } else {
                        const savedHrs = scheduler.subjects.filter(j => j.id == item.id);
                        if (savedHrs.length) {
                            item.hour = savedHrs[0].hour;
                        }
                    }
                    return item;
                });

                setSubjects(data.concat(d2));
            }
        }, []);

        const handleForm = (event) => {
            form[event.name] = event.value;
            setForm({ ...form })
        }

        function updateSubject(id, hour) {
            const data = subjects.map(item => {
                if (item.id === id) {
                    item.hour = hour;
                }
                return item;
            });

            setSubjects(data);
        }

        function updateSubjectSlot(id, slot) {
            const data = subjects.map(item => {
                if (item.id === id) {
                    item.slot = slot;
                }
                return item;
            });

            setSubjects(data);
        }

        function setDayAllocation(id, day) {
            const data = subjects.map((item, pos) => {
                if (item.id === id) {
                    if (!item.allocation) {
                        item.allocation = [];
                    }
                    if (!item.allocationTemp) {
                        item.allocationTemp = [];
                    }

                    if (!item.allocationTemp[item.slot]) {
                        item.allocationTemp[item.slot] = [];
                    }

                    if (item.allocationTemp[item.slot][day] != null) {
                        item.allocationTemp[item.slot][day] = null;
                    } else {
                        item.allocationTemp[item.slot][day] = pos;
                    }

                    item.hour = 0;
                }
                return item;
            });
            setSubjects(data);
        }

        function addDistribution(id) {
            const data = subjects.map((item) => {
                if (item.id === id) {
                    item.allocation = JSON.parse(JSON.stringify(item.allocationTemp));
                    item.slot = '';

                }
                return item;
            });
            setSubjects(data);
        }

        function delDistribution(id, slot) {
            const data = subjects.map((item) => {
                if (item.id === id) {
                    item.allocationTemp = item.allocationTemp.reduce((acc, alloc, pos) => {
                        if (pos != slot) {
                            acc[pos] = alloc;
                        }
                        return acc;
                    }, []);
                    if (item.allocationTemp && item.allocationTemp.length) {
                        item.allocation = JSON.parse(JSON.stringify(item.allocationTemp));
                    } else {
                        item.allocation = null;
                    }
                }
                return item;
            });
            setSubjects(data);
        }

        function addIntSlot() {
            if (form.setInterval > 0 && form.setIntervalName != '') {
                setForm({ ...form, interval: [...form.interval, { pos: form.setInterval, name: form.setIntervalName, tutor: form.setIntervalTutor }] });
            }
        }


        function removeInstSlot(i) {
            const d = [...form.interval]
            d.splice(i, 1);
            setForm({ ...form, interval: d });
        }

        const setup = <Grid.Row gutters="xs" style={{ width: "100%" }}>
            <Grid.Col md={12}>
                <Header.H3>Getting Started</Header.H3>
                <Card icon="edit">
                    <Card.Header>
                        <Icon name="edit" /> <span className="card-label">Periods & Days</span>
                    </Card.Header>
                    <Card.Body>
                        <Form.Group label="Working days" isRequired>
                            <Form.Input name="days" value={form.days} onChange={(event) => handleForm(event.target)} />
                        </Form.Group>
                        <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '1fr 1fr 1fr auto', gridGap: '20px' }}>
                            <Form.Group label="Select free periods" isRequired>
                                <Form.Select name="setInterval" value={form.setInterval} onChange={(event) => handleForm(event.target)}>
                                    <option value="0">Select</option>
                                    {
                                        scheduler.slots.map((item, i) => <option value={item.slot} key={i}>{i + 1} ({item.time})</option>)
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group label="Type" isRequired>
                                <Form.Select name="setIntervalName" value={form.setIntervalName} onChange={(event) => handleForm(event.target)}>
                                    <option value="">Type</option>
                                    {FreePeriodTypeList.map(freePeriodItem => <option value={freePeriodItem}>{freePeriodItem}</option>)}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group label="In-Charge" isRequired>
                                <Form.Select name="setIntervalTutor" value={form.setIntervalTutor} onChange={(event) => handleForm(event.target)}>
                                    <option value="">Select Tutor</option>
                                    {subjects.map(item => <option value={item.tutor_id + '-' + item.tutor} key={item.tutor_id}>{item.tutor}</option>)}
                                </Form.Select>
                            </Form.Group>
                            <div style={{ paddingTop: '10px' }}>
                                <Button color="primary" onClick={addIntSlot}>Add</Button>
                            </div>
                        </div>
                        <Button.List>
                            {
                                form.interval.map((i, k) => <Button pill color="success" key={i} title="Click to remove" onClick={() => removeInstSlot(k)}>{i.name} at {i.pos}</Button>)
                            }
                        </Button.List>
                    </Card.Body>
                </Card>
            </Grid.Col>
        </Grid.Row>;


        const setupSubjects = <Card>
            <Card.Header>
                <Card.Title>Distribution per week</Card.Title>
            </Card.Header>
            <Card.Body style={{ padding: 0 }}>
                <Dimmer active={formBusy} loader>
                    <Table>
                        <Table.Header>
                            <Table.ColHeader>#</Table.ColHeader>
                            <Table.ColHeader>Subject/Room</Table.ColHeader>
                            <Table.ColHeader>Distribution</Table.ColHeader>
                            <Table.ColHeader>Periods/Week</Table.ColHeader>
                        </Table.Header>
                        <Table.Body>
                            {subjects.map((subject, key) =>
                                <Table.Row>
                                    <Table.Col>{key + 1}</Table.Col>
                                    <Table.Col>
                                        <span className="subject-pill" style={{ backgroundColor: subject.color }}>{subject.name}</span>
                                    </Table.Col>
                                    <Table.Col>
                                        <Form.Select value={subject.slot} onChange={(event) => updateSubjectSlot(subject.id, event.target.value)} >
                                            <option value="">{
                                                subject.allocation ? 'Custom' : 'Random'}</option>
                                            {
                                                scheduler.slots.map((item, i) => <option value={item.slot} key={i}>{i + 1} ({item.time})</option>)
                                            }
                                        </Form.Select>
                                        {
                                            subject.slot != null && subject.slot != '' && form.days && form.days > 0 ? new Array(form.days).fill(0).map((day, i) =>
                                                <Form.Checkbox value={i + 1} label={days[i]} onChange={(event) => setDayAllocation(subject.id, i)} />
                                            ) : null
                                        }
                                        {
                                            subject.slot != null && subject.slot != '' && form.days && form.days > 0 ?
                                                <Button className="mt-3" color="primary" block onClick={() => addDistribution(subject.id)}>Add</Button> : null

                                        }
                                    </Table.Col>
                                    <Table.Col>
                                        {
                                            subject.allocation ? subject.allocation.map((items, pos) => items ? <div className="mb-2">
                                                <b>Slot {pos} - </b>{
                                                    items.map((z, alctDay) =>
                                                        z != null ? <Badge color="success" className="mr-1">{daysMin[alctDay]}</Badge> : null
                                                    )
                                                }<Button color="danger" icon="trash" size="sm" onClick={() => delDistribution(subject.id, pos)} /></div> : null) : null
                                        }

                                        {
                                            (subject.slot == null || subject.slot == '') && !subject.allocation ? <Form.Input value={subject.hour} onChange={(event) => updateSubject(subject.id, event.target.value)} /> : null
                                        }

                                    </Table.Col>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Dimmer>
            </Card.Body>
            <Card.Footer>
                <Button color="success" disabled={formBusy} loading={formBusy} onClick={generateTable}>Generate</Button>
            </Card.Footer>
        </Card >;


        const view = (
            <Grid.Row cards deck justifyContent='center'>
                <Grid.Col md={12}>
                    <Card statusColor="yellow">
                        <Card.Body >
                            <Header.H2>Schedule</Header.H2>
                        </Card.Body>
                    </Card>
                </Grid.Col>
                <Grid.Col md={7} >
                    {setup}
                </Grid.Col>

                <Grid.Col md={7} >
                    {setupSubjects}
                </Grid.Col>
            </Grid.Row>
        );
        return view;
    },
    Preview: (props) => {
        const [store, updateStore] = useStore();
        const [slotSbj, setSlot] = useState({ available: [], used: [] });
        const [scheduler, setScheduler] = useState([]);
        const [showError, setError] = useState({ display: false, busy: false, type: 'danger', message: '' });
        const [version, setVersion] = useState({});
        const [form, setForm] = useState({ version: null });
        const [activeUsers, setActiveUsers] = useState([]);
        const [allSubjects, setSubjects] = useState([]);

        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const period = props.data.scheduler[0];

        useEffect(() => {
            setScheduler(props.data.scheduler);
            if (props.data && props.data.version) {
                updateVersion(props.data.version);
            }

            concurrentUsers();
            console.log(store.scheduler.subjects)
            setSubjects(store.scheduler.subjects)
        }, []);


        function concurrentUsers() {
            const getUserStatus$ = interval(5000);

            getUserStatus$.pipe(
                switchMap(res => Axios.get(settings.server + '/user/' + props.batch + '/scheduler/status?id=' + store.user.id))
            ).subscribe(res => {
                if (res && res.data && res.data.success) {
                    setActiveUsers(res.data.data);
                } else {
                    setActiveUsers([]);
                }
            });
        }



        function loadVersion(event) {
            handleChange(event);
            setScheduler([]);
            Axios.get(settings.server + '/batch/' + props.batch + '/scheduler/version/' + event.target.value)
                .then(res => {
                    if (res.data.success) {
                        setScheduler(res.data.data.scheduler);
                        updateVersion(res.data.data.version);
                    } else {
                        reset(res.data.data.version);
                    }
                });
        }

        function updateVersion(ver) {
            setVersion(ver);
            setForm({ ...form, version: ver.id });
        }

        function applyVersion() {

        }

        function handleChange(event) {
            const d = { ...form };
            d[event.target.name] = event.target.value;
            setForm(d);
        }

        function saveChanges() {
            if (version.id) {
                saveVersion(version.name);
            } else {
                setError({ display: true, busy: false, type: 'danger', message: 'No versions found. Please contact admin.' });
            }
        }

        function saveVersion(verName = null) {
            const param = [];
            setError({ ...showError, busy: true });

            scheduler.map((item, i) => {
                item.map(subject => {
                    param.push({ subject: subject.id, tutor: subject.tutor_id, day: i + 1, slot: subject.slot });
                })
            });

            Axios.post(settings.server + '/batch/' + props.batch + '/scheduler/update', { data: param, name: verName, version: version.id })
                .then(res => {
                    if (res.data.success) {
                        setError({ display: true, busy: false, type: 'success', message: 'Changes saved.' });
                        updateVersion(res.data.data.version);
                        setTimeout(() => setError({ display: false, busy: false, type: 'success', message: 'Changes saved.' }), 2000);
                    } else {
                        setError({ display: true, busy: false, type: 'danger', message: res.data.message });
                    }
                }, err => {
                    setError({ display: true, busy: false, type: 'danger', message: 'Network Error. Try again' });
                });
        }

        function reGenerate() {
            Axios.post(settings.server + '/batch/scheduler/generate', { batch: store.batch.id, subjects: props.data.subjects, ...props.data.options, version: form.version })
                .then(res => {

                    if (res.data.success) {
                        setScheduler(res.data.data.scheduler);
                        updateVersion(res.data.data.version);
                    }
                }, err => { })
        }

        function fetchAvailableReplace(day, subject) {
            setSlot({ available: [], used: [] });
            Axios.get(settings.server + '/batch/scheduler/slot/replace?batch=' + store.batch.id + '&day=' + day + '&time_from=' + subject.time_from + '&time_to=' + subject.time_to + '&ver=' + version.id)
                .then(res => {
                    if (res.data.success) {
                        setSlot({ available: res.data.data, used: res.data.used });
                    }
                });
        }

        function replaceSlot(day, pos, subject, slot) {
            const old = scheduler[day][pos];
            scheduler[day][pos] = { ...subject, time_from: old.time_from, time_to: old.time_to, slot };

            setScheduler([...scheduler]);
        }

        function replaceRecessSlot(day, pos, subject, slot) {
            const old = scheduler[day][pos];
            scheduler[day][pos] = { ...old, tutor: subject.tutor, tutor_id: subject.tutor_id };

            setScheduler([...scheduler]);
        }

        function reset(verData) {
            updateStore({ ...store, scheduler: { ...store.scheduler, scheduler: [], version: verData } });
        }

        function downloadFile(type) {
            const fileName = store.batch.class_name + ' - ' + store.batch.division + ' Timetable';

            if (type == 'pdf') {
                html2canvas(document.querySelector("#element-to-export")).then(canvas => {
                    const doc = new jsPDF();

                    doc.text(fileName, 10, 10);

                    doc.addImage(canvas, 'JPEG', 10, 20, 190, 210)
                    doc.save(fileName + ".pdf");
                });

                return;
            }

            const cellsName = ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

            const XlsxPopulate = require('xlsx-populate');
            XlsxPopulate.fromBlankAsync()
                .then(workbook => {

                    const sheetName = 'Sheet1';
                    workbook.sheet(sheetName).column('A').width(20);

                    props.data.slots.map((day, j) => {
                        workbook.sheet(sheetName).cell('A' + ((j * 2) + 2)).value(day.time).style("bold", true);
                        workbook.sheet(sheetName).row((j * 2) + 1).height(40);
                    });

                    scheduler.map((day, i) => {
                        workbook.sheet(sheetName).cell(cellsName[i] + '1').value(days[i]).style("bold", true);


                        day.map((subject, j) => {
                            let thisRow = (j * 2) + 2;

                            if (subject && (subject.name || subject.id == null || subject.id == '')) {

                                if (subject && subject.id) {
                                    workbook.sheet(sheetName).cell(cellsName[i] + (thisRow)).value(subject.short_name).style("fill", 'bfbfbf');
                                    workbook.sheet(sheetName).cell(cellsName[i] + (thisRow + 1)).value(subject.tutor);
                                }
                            } else {
                                workbook.sheet(sheetName).cell(cellsName[i] + (thisRow)).value(subject.id);
                            }

                        });
                    });

                    workbook.outputAsync()
                        .then(function (blob) {
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                // If IE, you must uses a different method.
                                window.navigator.msSaveOrOpenBlob(blob, fileName + ".xlsx");
                            } else {
                                var url = window.URL.createObjectURL(blob);
                                var a = document.createElement("a");
                                document.body.appendChild(a);
                                a.href = url;
                                a.download = fileName + ".xlsx";
                                a.click();
                                window.URL.revokeObjectURL(url);
                                document.body.removeChild(a);
                            }
                        });
                });
        }

        const subjects = <Card>
            <Card.Header>
                <Card.Title>Distribution per week</Card.Title>
            </Card.Header>
            <Card.Body style={{ padding: 0 }}>
                <Table>
                    <Table.Header>
                        <Table.ColHeader>#</Table.ColHeader>
                        <Table.ColHeader>Subject</Table.ColHeader>
                        <Table.ColHeader>Periods</Table.ColHeader>
                    </Table.Header>
                    <Table.Body>
                        {props.data.subjects.map((subject, key) =>
                            <Table.Row>
                                <Table.Col>{key + 1}</Table.Col>
                                <Table.Col>
                                    <span className="subject-pill" style={{ backgroundColor: subject.color }}>{subject.name}</span>
                                    <div>
                                        Tutor: <i>{subject.tutor}</i>
                                    </div></Table.Col>
                                <Table.Col>
                                    {subject.hour}
                                </Table.Col>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </Card.Body>
        </Card>;

        const versionsList = <Card>
            <Card.Header>
                <Card.Title>Versions</Card.Title>
            </Card.Header>
            <Card.Body>
                <Form.Group>
                    <Form.Select name="version" value={form.version} onChange={loadVersion.bind(this)}>
                        <option value=''>Choose a version</option>
                        {
                            props.data.versions_list.map(item => <option value={item.id} key={item.id}>{item.name}</option>)
                        }
                    </Form.Select>
                </Form.Group>
            </Card.Body>
        </Card>;

        return (<>
            <Grid.Row style={{ width: "100%" }}>
                <Grid.Col md={3}>
                    {props.data.versions_list.length ? versionsList : ''}
                    {subjects}
                </Grid.Col>
                <Grid.Col md={9}>
                    {
                        activeUsers.length ? <Alert type='warning'>Another user is currently making changes on this page
                            <br />Active Users: <b>{
                                activeUsers.join(', ')
                            }</b></Alert> : ''
                    }
                    {showError.display ? (<Alert type={showError.type} icon={showError.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                        {showError.message}
                    </Alert>) : ''}
                    <Dimmer active={!scheduler.length} loader>
                        <Card>
                            <Card.Header>
                                <Card.Title>
                                    Scheduler
                                    {version.id ? <Badge color="success" className="ml-3">{version.name}</Badge> : ''}
                                </Card.Title>

                                <Card.Options>
                                    {store.rights.scheduler_create ? <Button color="primary" size="sm" onClick={saveChanges}>Save Changes</Button> : null}
                                    <Dropdown triggerContent="More">
                                        <Dropdown.Menu>
                                            {store.rights.scheduler_create ? <><Dropdown.Item onClick={() => reset(version)}>Reset</Dropdown.Item>
                                                <Dropdown.Item onClick={reGenerate}>Re-generate</Dropdown.Item>
                                                <Dropdown.ItemDivider /></> : ''}
                                            <Dropdown.Item onClick={() => downloadFile('excel')}>Export to Excel</Dropdown.Item>
                                            <Dropdown.Item onClick={() => downloadFile('pdf')}>Export to PDF</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Card.Options>
                            </Card.Header>
                            <Card.Body>
                                <div id="element-to-export" className="schedular-table">
                                    <div className="day-col">
                                        <div className="day-header">&nbsp;</div>
                                        {
                                            props && props.data && props.data.slots ? props.data.slots.map((day) => <div className="col-header sub-col my-1">{day.time}</div>) : null
                                        }
                                    </div>
                                    {scheduler.map((day, i) => (<div className="day-col">
                                        <div className="day-header"><b>{days[i]}</b></div>
                                        {day.map((subject, j) =>
                                            subject && (subject.name || !FreePeriodTypeList.includes(subject.id) || subject.id == null || subject.id == '') ?
                                                <Dropdown className="sub-col subject-pill my-1" >
                                                    <div className="" onClick={() => fetchAvailableReplace(i + 1, subject)}>
                                                        <Dropdown.Trigger style={{ backgroundColor: subject.color }}>
                                                            {
                                                                subject && subject.id && subject.short_name ? <>
                                                                    <label >{subject.short_name || 'Undefined'}</label>
                                                                    <div className="label" title={subject.tutor}>{subject.tutor || 'Select'}</div>
                                                                </> : <Button pill color="warning" icon="alert-triangle" />
                                                            }
                                                        </Dropdown.Trigger>
                                                    </div>
                                                    {store.rights.scheduler_create ?
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item><b>Replace with</b></Dropdown.Item>
                                                            <Dropdown.ItemDivider />
                                                            {
                                                                slotSbj.available.map(sbj => <Dropdown.Item onClick={() => replaceSlot(i, j, sbj, subject.slot)}>{sbj.name}</Dropdown.Item>)
                                                            }
                                                            {
                                                                slotSbj.used.map(sbj => <Dropdown.Item key={sbj.id}><strike>{sbj.name}</strike></Dropdown.Item>)
                                                            }
                                                        </Dropdown.Menu> : ''}
                                                </Dropdown> :
                                                <Dropdown className="sub-col sub-col-interval my-1" >
                                                    <div >
                                                        <Dropdown.Trigger>
                                                            {subject.id}
                                                            <div className="sub-col-interval-tutor"><em>{subject.tutor}</em></div>
                                                        </Dropdown.Trigger>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item><b>Assign Tutor</b></Dropdown.Item>
                                                            <Dropdown.ItemDivider />
                                                            {
                                                                allSubjects.map(sbj => <Dropdown.Item onClick={() => replaceRecessSlot(i, j, sbj, subject.slot)} key={sbj.id}>{sbj.tutor}</Dropdown.Item>)
                                                            }
                                                        </Dropdown.Menu>
                                                    </div>
                                                </Dropdown>
                                        )}
                                    </div>))}
                                </div>
                            </Card.Body>
                        </Card>
                    </Dimmer>
                </Grid.Col>
            </Grid.Row>
        </>
        );
    }
}

export default Schedule;