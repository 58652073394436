import React, { useState, useEffect } from 'react';
import { Card, Icon, Button, Form, Grid, List, Header } from 'tabler-react';
import './Styles.css';
import { useStore } from '../utils/Store';
import axios from 'axios';
import settings from '../app.config';

import imgEmpty from '../assets/placeholders/exams_default.svg';

const Exams = {
    Home: () => {
        const [store] = useStore();

        useEffect(() => {
            const el = document.getElementsByClassName('master-nav-list');
            for (const element in el) {
                const node = el[element];
                if (node.classList) { node.classList.remove('active'); }
            };

            const actEl = document.getElementById('master-nav-exam');
            if (actEl) { actEl.classList.add('active'); }
        }, []);

        return (
            <div>
                <Grid.Row gutters="xs">
                    <Grid.Col>
                        <Card icon="edit">
                            <Card.Header>
                                <Icon name="edit" /> <span className="card-label">Manage Exams</span>
                            </Card.Header>
                            <Card.Body style={{ padding: "0" }}>
                                {
                                    store.exams && store.exams.length > 0 ? <Exams.All /> : (<div className="placeholder-img"><img src={imgEmpty} alt='no exams created' />
                                        <Header.H4 className="label">No exams created yet.</Header.H4>
                                    </div>)
                                }
                            </Card.Body>
                        </Card>
                    </Grid.Col>
                    <Grid.Col className="master-class-create">
                        <Exams.New />
                    </Grid.Col>
                </Grid.Row>
            </div>
        )
    },
    New: () => {
        const [form, setForm] = useState({ value: '', busy: '' });
        const [store, setStore] = useStore();

        const formHandler = (event) => {
            setForm({ ...form, value: event.target.value });
        };

        const addItem = () => {
            if (form.busy) {
                return;
            }
            setForm({ ...form, busy: true });
            axios.post(settings.server + '/exam/create', { name: form.value })
                .then(res => {
                    setForm({ ...form, busy: false });
                    if (res.data.success) {
                        setStore({ ...store, exams: store.exams.concat([{ ...res.data.data }]) })
                        setForm({ value: '', busy: false });
                    }
                }, err => {
                    setForm({ ...form, busy: false })
                });
        };


        return (
            <Card icon="edit">
                <Card.Header>
                    <Icon name="plus" /> <span className="card-label">Add New Exam</span>
                </Card.Header>
                <Card.Body>
                    <Grid.Row>
                        <Grid.Col>
                            <Form.Group label="Exam name" isRequired>
                                <Form.Input name="first-name" value={form.value} onChange={formHandler.bind(this)} />
                            </Form.Group>
                        </Grid.Col>
                    </Grid.Row>
                </Card.Body>
                <Card.Footer>
                    <div>
                        <Button color="success" icon="save" onClick={addItem} disabled={form.busy}>Create</Button>
                    </div>
                </Card.Footer>
            </Card>
        );
    },
    All: () => {
        const [store] = useStore();
        const list = store.exams.map(item => <List.GroupItem>{item.name}</List.GroupItem>);
        return (<List.Group>
            {list}
        </List.Group>);
    }
}


export default Exams;