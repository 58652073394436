

export const AdminFeedBackQuestions = [
    {
        title: 'Job Knowledge',
        description: 'Demonstrates the knowledge and skill necessary to perform effectively.',
        ratingBenchmark: [
            'Inconsistent in working independently, delays in completing given tasks and sub-standard quality. Shows unwillingness to learn new skills.',
            'Works independently and consistently to ensure that works is submitted on time and with acceptable quality. Willing to learn new skills and puts in extra efforts to achieve results.',
            'Understand the expectations of the job and stays current with new developments, technologies, methods, theories, approaches, and processes in area of responsibility. Seeks feedback on own performance and makes necessary changes to improve performance.',
            'Demonstrates interest in developing and extending professional knowledge. Applies knowledge effectively given circumstances of varying situations. Work is often used as model for others to emulate. Willing to help others learn and improve.'
        ]
    },
    {
        title: 'Quality & Commitment',
        description: 'of work',
        ratingBenchmark: [
            'Frequently delivers sub - standard works and not meeting deadlines. Shows a lack of commitment to complete given tasks.',
            'Inconsistently completes work in a timely manner.Need to be reminded and seldom uses time effectively.Lack of initiative in addressing problems.',
            'Accurately and thoroughly completes work in a timely manner.Works effectively, uses time effectively, takes initiative in addressing problems.Takes responsibility for meeting deadlines without sacrificing accuracy, quality, or service satisfaction.',
            'Assumes “ownership” when appropriate.Can be counted on by others to complete assigned responsibilities.Demonstrates commitment to the department and school mission.'
        ]
    },
    {
        title: 'Teamwork & Cooperation',
        description: 'works collaboratively with fellow employees and others to achieve identified goals and objectives.',
        ratingBenchmark: ['Does not establish cooperative relationships both inside and outside department. Seldom integrates own activities with larger group',
            'Does not establish cooperative relationships outside department. Inconsistently integrates own activities with larger group',
            'Readily gives and receives help.Values contributions made by others.Actively seeks and achieves group participation to improve work and solve problems.',
            'A productive team member, participating in discussions, contributing to the knowledge base of the team, and following through on group assignments in a timely manner.'
        ]
    },
    {
        title: ' Effective Communication',
        description: 'communicates openly, honestly and accurately. 1) Written – uses clear and appropriate language in writing. 2) Oral – verbally conveys information in a clear and accurate manner in a variety of situations.',
        ratingBenchmark: [
            'Seldom expresses ideas and information in writing and verbally in an effective manner that is complete, clear and concise, organized and appropriate to the audience.',
            'Inconsistently expresses ideas and information in writing and verbally in an effective manner that is complete, clear and concise, organized and appropriate to the audience.Sometimes conveys information to supervisors.',
            'Expresses ideas and information in writing and verbally in an effective manner that is complete, clear and concise, organized and appropriate to the audience.Conveys information to supervisors, peers and customers in a timely manner.',
            'Expresses ideas and information in writing and verbally in an effective manner that is complete, clear and concise, organized and appropriate to the audience.Listens to other  and is open to and evaluate suggestions form others.']
    },
    {
        title: 'Problem Analysis & Decision Making',
        description: 'gathers and analyses appropriate information before making decisions.',
        ratingBenchmark: [
            'Does not develops and implements effective solutions. Seldom seeks information and input from others.',
            'Seldom anticipates and identifies problems.Develops and implements effective solutions but does not evaluates alternative solutions.',
            'Frequently anticipates and identifies problems.Sometimes evaluates alternative solutions and is open to new or different solutions.',
            'Always anticipates and identifies problems.Evaluates alternative solutions and is open to new or different solutions.Takes or recommends appropriate actions and determines which problems to handle independently and which to refer.Follows up on problems and helps to bring about resolution.'
        ]
    },
    {
        title: ' Initiative & Creativity',
        description: 'generates ideas and creative solutions. ',
        ratingBenchmark: ['Lacks enthusiasm and flexibility for new ideas.',
            'Always shows enthusiasm and flexibility for new ideas, programs and procedures.Promotes / facilitates innovation and creativity on the part of others.',
            'Always shows enthusiasm and flexibility for new ideas, programs and procedures.Promotes / facilitates innovation and creativity on the part of others.',
            'Shows enthusiasm and flexibility for new ideas, programs and procedures.Promotes / facilitates innovation and creativity on the part of others.Is self- starting, resourceful and creative.Uses technology for analysis and assessment.'
        ]
    },
    {
        title: 'Composure',
        description: 'Acts professionally and calmly under stressful situation.',
        ratingBenchmark: [
            'Demonstrates lack of patience.Sometimes maintain focus on issues rather than the person involve in the situation.',
            'Demonstrates patience.Allows others opportunity to express their opposing views.',
            'Expresses opinion, ideas and information in a logical and rational manner.',
            'Able to mediate for others caught in a stressful situation.Often consulted by others to mediate a stressful situation.'
        ]
    },
    {
        title: 'Personal Responsibilities',
        description: 'Does not allow personal and other commitments to affect work. Punctual when reporting to work. Performs duties in a professional manner. Regular attendance.',
        ratingBenchmark: [
            'Always allow personal and other commitment to affect work. Latecoming to work more than 4 minutes – exceeds 10 times in 1 term.',
            'Occassionally allow personal and other commitment to affect work.  Latecoming to work more than 4 minutes – between 6 to 10 times in 1 term.',
            'Is punctual and reliable with paperwork, duties and assigned tasks, keeps accurate records. Latecoming to school more than 4 minutes – between 2 to 5 times in 1 term.',
            'Carries out assigned tasks conscientiously and punctually, keeps meticulous records and never late. Latecoming to school more than 4 minutes – 0 to 1 time in 1 term.'
        ]
    }
];
