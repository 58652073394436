import React, { useState, useEffect } from 'react';
import { Page, Button, Icon, Table, Form, Grid, Dropdown, Card, Alert, Header, Badge, List } from 'tabler-react';
import { Switch, Route, Link, useRouteMatch, useHistory } from "react-router-dom";
import { useStore } from '../utils/Store';
import Manage from './Manage';
import settings from '../app.config';
import Class from '../master/Class';
import imgDefault from '../assets/placeholders/add_content.svg';
import Popup from 'reactjs-popup';
import Axios from 'axios';

const Batch = {
    Home: () => {
        const [store] = useStore();
        const [form, setForm] = useState({ year: '', allYear: [] });
        const [batchList, setBatches] = useState([]);

        let match = useRouteMatch();

        useEffect(() => {
            Axios.get(settings.server + '/year').then(res => {
                setForm({ ...form, allYear: res.data.data })
            });

            setBatches(store.batches);
        }, []);

        function setYear(year) {
            setForm({ ...form, year });
            Axios.get(settings.server + '/batch/year?year=' + year).then(res => {
                if (res.data.success) {
                    setBatches(res.data.data);
                }
            });
        }

        function loadData() {

        }

        return (
            <div>
                <div className="title-bar">
                    <Page.Title>
                        <Icon name="grid" /> Manage Classess/Timetable</Page.Title>
                    <div className="filter-results">
                        <div className="search">
                            <Form.Group>
                                <Grid.Row gutters="xs">
                                    <Grid.Col>
                                        <Form.InputGroup>
                                            <Form.InputGroup prepend>
                                                <Dropdown triggerContent={form.year || 'Filter by year'}>
                                                    <Dropdown.Menu position="right">
                                                        {
                                                            form.allYear.map((item) => <Dropdown.Item key={item} onClick={() => setYear(item)}>{item}</Dropdown.Item>)
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Form.InputGroup>
                                        </Form.InputGroup>
                                    </Grid.Col>
                                    <Grid.Col auto>
                                        <Form.Input placeholder="Search by name" />
                                    </Grid.Col>
                                </Grid.Row>
                            </Form.Group>
                        </div>
                        {
                            store.rights && store.rights.class_create ? <Button.List align="right">
                                <Link to='/home/class/new'>
                                    <Button color="primary">
                                        <Icon name="plus" /> Add New Class
                                    </Button>
                                </Link>
                            </Button.List> : ''
                        }
                    </div>
                </div>
                <div>
                    <Switch>
                        <Route path={`${match.path}/view/:classID`}>
                            <Manage />
                        </Route>
                        {store.rights && store.rights.class_create ? <Route path={`${match.path}/new`}>
                            <Batch.CreateClass />
                        </Route> : ''}
                        <Route path={match.path}>
                            <BatchList batches={batchList} />
                        </Route>
                    </Switch>
                </div>
            </div >
        )
    },
    CreateClass: () => {
        const history = useHistory();
        const [store, setStore] = useStore();
        const [form, setForm] = useState({ class: '', teacher: '', batch: '', division: '' });
        const [errors, setError] = useState({ display: false, busy: false, type: 'danger', message: '' });

        const handleForm = (event) => {
            form[event.target.name] = event.target.value;
            setForm({ ...form })
        }

        const saveClass = () => {
            if (errors.busy) {
                return;
            }
            setError({ ...errors, busy: true });
            Axios.post(settings.server + '/batch/create', form)
                .then(res => {
                    if (res.data.success) {
                        store.batches.unshift(res.data.data);
                        setStore({ ...store, batches: store.batches });
                        setForm({ class: '', teacher: '', batch: '' });
                        setError({ display: true, busy: false, type: 'success', message: res.data.message });
                        setTimeout(() => {
                            history.replace({ pathname: '/home/class/view/' + res.data.data.id });
                        }, 1000);
                    } else {
                        setError({ display: true, busy: false, type: 'danger', message: res.data.message });
                    }
                }, err => {
                    setError({ display: true, busy: false, type: 'danger', message: 'Network Error. Try again' });
                });
        };

        return (
            <Grid.Row gutters="xs">
                <Grid.Col md={8}>
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                <Icon name="plus" /> Create New Batch</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {
                                errors.display ? (<Alert type={errors.type} icon={errors.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                                    {errors.message}
                                </Alert>) : ''
                            }
                            <Form.FieldSet>
                                <Grid.Row>
                                    <Grid.Col>
                                        <Form.Group label="Level" isRequired invalid='true'>
                                            <Form.Select value={form.class} name="class" onChange={handleForm.bind(this)}>
                                                <option value=''>Choose a class name</option>
                                                {
                                                    store.class.map(item => <option value={item.id} key={item.id}>{item.name}</option>)
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Form.Group label="Form Teacher" isRequired >
                                            <Form.Select name="teacher" value={form.teacher} onChange={handleForm.bind(this)}>
                                                <option value=''>Select a Teacher</option>
                                                {
                                                    store.teachers.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Form.Group label="Class Name" isRequired>
                                            <Form.Input name="division" placeholder="Enter class division" value={form.division} onChange={handleForm.bind(this)} />
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Form.Group label="Batch" isRequired>
                                            <Form.Input name="batch" placeholder="Enter year" value={form.batch} onChange={handleForm.bind(this)} />
                                        </Form.Group>
                                    </Grid.Col>
                                </Grid.Row>
                            </Form.FieldSet>
                        </Card.Body>
                        <Card.Footer>
                            <div className="edit-profile-footer">
                                <Button color="success" icon="save" onClick={() => { saveClass() }}>Create</Button>
                                <Button color="secondary">Cancel</Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </Grid.Col>
                <Grid.Col>
                    <Alert type="secondary" icon="heart">Quickly add new class to the list.</Alert>
                    <Class.NewClass />
                </Grid.Col>
            </Grid.Row>
        );
    },
    DuplicateClass: ({ id, close }) => {
        const history = useHistory();
        const [store, setStore] = useStore();
        const [batchData, setBatch] = useState({ busy: true, subjects: [] });
        const [form, setForm] = useState({ teacher: '', batch: '', subId: '', subTeacher: '', archive: false });
        const [errors, setError] = useState({ display: false, busy: false, type: 'danger', message: '' });

        useEffect(() => {
            Axios.get(settings.server + '/batch/' + id)
                .then(res => {
                    if (res.data.success) {
                        setBatch({ busy: false, success: true, ...res.data.data });
                    }
                }, err => {
                    setBatch({ busy: false, success: false });
                });
        }, []);

        const handleForm = (event) => {
            form[event.target.name] = event.target.value;
            setForm({ ...form })
        }

        function setArchive() {
            setForm({ ...form, archive: !form.archive });
        }

        function removeItem(index) {
            const subs = [...batchData.subjects];
            subs.splice(index, 1);

            setBatch({
                ...batchData,
                subjects: subs
            });
        }

        function removeItemAmmenity(index) {
            const subs = [...batchData.amenities];
            subs.splice(index, 1);

            setBatch({
                ...batchData,
                amenities: subs
            });
        }



        function addSubject() {
            if (!form.subTeacher || !form.subId) {
                return;
            }
            const subs = batchData.subjects;
            const tut = store.teachers.filter(tu => tu.id == form.subTeacher)[0]
            subs.push({
                ...store.subjects.filter(su => su.id == form.subId)[0],
                tutor: tut.name,
                tutor_id: tut.id
            });

            setBatch({
                ...batchData,
                subjects: subs
            });
            setForm({ ...form, subId: '', subTeacher: '' })
        }

        function saveClass() {
            Axios.post(settings.server + '/batch/duplicate/add', { id, subjects: batchData.subjects, amenities: batchData.amenities, teacher: form.teacher, batch: form.batch, archive: form.archive })
                .then(res => {
                    if (res.data.success) {
                        setError({ display: true, busy: false, type: 'success', message: res.data.message });
                        reload();
                        close();
                        setTimeout(() => {
                            history.replace({ pathname: '/home/class/view/' + res.data.data.id });
                        }, 1000);
                    } else {
                        setError({ display: true, busy: false, type: 'danger', message: res.data.message });
                    }
                }, err => {
                    setError({ display: true, busy: false, type: 'danger', message: 'Network Error. Try again' });
                });
        }


        function reload() {
            Axios.get(settings.server + '/batch/all')
                .then(res => {
                    if (res.data.success) {
                        setStore({ ...store, batches: res.data.data });
                    }
                })
        }


        return <Popup open={true} onClose={close}>
            <div className="modal">
                <Card>
                    <Card.Header>
                        <Card.Title><Icon name="copy" /> <span className="card-label">Duplicate {batchData.class_name} {batchData.division}</span></Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {
                            errors.display ? (<Alert type={errors.type} icon={errors.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                                {errors.message}
                            </Alert>) : ''
                        }
                        <Form.FieldSet>
                            <Grid.Row>
                                <Grid.Col>
                                    <Form.Group label="Form Teacher" isRequired >
                                        <Form.Select name="teacher" value={form.teacher} onChange={handleForm.bind(this)}>
                                            <option value=''>Select a Teacher</option>
                                            {
                                                store.teachers.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col>
                                    <Form.Group label="Batch" isRequired>
                                        <Form.Input name="batch" placeholder="Enter year" value={form.batch} onChange={handleForm.bind(this)} />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>
                        </Form.FieldSet>
                        <Form.FieldSet>
                            <b>Subjects</b>
                            {batchData.subjects && batchData.subjects.length ? <List.Group className="mt-2">
                                {
                                    batchData.subjects.map((val, key) =>
                                        <List.GroupItem style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }} key={key}>
                                            <div>{val.name}
                                                <div>Teacher: <i>{val.tutor}</i></div>
                                            </div>
                                            <div>
                                                <Badge color="danger" className="controls cursor" onClick={() => removeItem(key)}>Delete</Badge>
                                            </div>
                                        </List.GroupItem>
                                    )
                                }
                            </List.Group> : ''
                            }
                            {batchData.amenities && batchData.amenities.length ? <List.Group className="mt-2">
                                {
                                    batchData.amenities.map((val, key) =>
                                        <List.GroupItem style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }} key={key}>
                                            <div>{val.name}
                                                <div>Teacher: <i>{val.tutor}</i></div>
                                            </div>
                                            <div>
                                                <Badge color="danger" className="controls cursor" onClick={() => removeItemAmmenity(key)}>Delete</Badge>
                                            </div>
                                        </List.GroupItem>
                                    )
                                }
                            </List.Group> : ''
                            }
                            <List.Group>
                                <List.GroupItem active>
                                    <Form.Group label="Add new subject">
                                        <Grid.Row gutters="xs" alignItems='end'>
                                            <Grid.Col style={{ position: 'relative', }}>
                                                <Form.Select className="mt-4" name="subId" onChange={handleForm.bind(this)} >
                                                    <option value=''>Choose Subject</option>
                                                    {
                                                        store.subjects.map(item =>
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        )
                                                    }
                                                </Form.Select>
                                                {
                                                    form.subId ? <Form.Select className="mt-4" name="subTeacher" onChange={handleForm.bind(this)} >
                                                        <option value=''>Choose Teacher</option>
                                                        {
                                                            store.teachers.reduce((acc, item) => {
                                                                if (item.subjects.filter(s => s.id == form.subId).length > 0) {
                                                                    acc.push(<option value={item.id} key={item.id}>{item.name}</option>)
                                                                }
                                                                return acc;
                                                            }, [])
                                                        }
                                                    </Form.Select> : ''
                                                }

                                            </Grid.Col>
                                            <Grid.Col auto>
                                                <Button
                                                    color="success"
                                                    icon="plus" onClick={addSubject}
                                                />
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Form.Group>
                                </List.GroupItem>
                            </List.Group>
                        </Form.FieldSet>

                        <Form.FieldSet>
                            <Form.Checkbox
                                label="Archive and duplicate"
                                value={form.archive}
                                onChange={setArchive}
                            />
                        </Form.FieldSet>

                    </Card.Body>
                    <Card.Footer>
                        <div className="edit-profile-footer">
                            <Button color="success" icon="save" onClick={() => saveClass()}>Create</Button>
                            <Button color="secondary" onClick={close}>Cancel</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
        </Popup>;
    }
};


const BatchList = (props) => {
    let match = useRouteMatch();
    const [store] = useStore();
    const [dupModal, setModal] = useState();

    function duplicateClass(id) {
        setModal(id);
    }

    const listItems = props.batches.map((batch, key) =>
        <Table.Row key={key}>
            <Table.Col>{key + 1}</Table.Col>
            <Table.Col>{batch.class_name}</Table.Col>
            <Table.Col>{batch.division}</Table.Col>
            <Table.Col>{batch.teacher_name}</Table.Col>
            <Table.Col>{batch.students_count}</Table.Col>
            <Table.Col>{batch.year}</Table.Col>
            <Table.Col>
                <div className="view-student">
                    {store.user.user_type == 'ADMIN' ? <Dropdown triggerContent="More">
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => duplicateClass(batch.id)}>Duplicate</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> : ''
                    }
                    {
                        batch.teacher_id == store.user.id || store.user.user_type == 'ADMIN' ? <div className="ml-5"><Link to={`${match.url}/view/${batch.id}`}><Button color="warning">Manage</Button></Link></div> : ''
                    }

                    <div>
                        <Link to={`${match.url}/view/${batch.id}/schedule`}><Button color="success" >Timetable</Button></Link></div>
                </div>
            </Table.Col>
        </Table.Row>
    );

    return (
        <div className="students-list">
            {
                store.batches.length === 0 ? (<div className="placeholder-img"><img src={imgDefault} alt='no exams found' />
                    <Header.H4 className="label">You haven't created any batches yet.</Header.H4>
                    {
                        store.rights && store.rights.class_create ? <Link to='/home/class/new'>
                            <Button color="primary">
                                <Icon name="plus" /> Create Now
                            </Button>
                        </Link> : ''
                    }
                </div>) : ''
            }
            {
                store.batches.length > 0 ? (<Table>
                    <Table.Header>
                        <Table.ColHeader>#</Table.ColHeader>
                        <Table.ColHeader>Level</Table.ColHeader>
                        <Table.ColHeader>Class Name</Table.ColHeader>
                        <Table.ColHeader>Form Teacher</Table.ColHeader>
                        <Table.ColHeader>Total Students</Table.ColHeader>
                        <Table.ColHeader>Batch</Table.ColHeader>
                        <Table.ColHeader></Table.ColHeader>
                    </Table.Header>
                    <Table.Body>
                        {props.batches && props.batches.length ? listItems : <div className="p-5">No results found.</div>}
                    </Table.Body>
                </Table >) : ''
            }
            {dupModal ? <Batch.DuplicateClass id={dupModal} close={duplicateClass} /> : ''}
        </div >
    );

}

export default Batch;