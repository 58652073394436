












export const HodFeedBackQuestions = [
    {
        title: 'Subject Mastery',
        description: 'Displays a thorough knowledge of the curriculum and lesson materials.',
        ratingBenchmark: []
    },
    {
        title: '',
        description: 'Demonstrate a broad and comprehensive knowledge of the subject of instruction by expanding important aspects in the subject area. Deliver clear, effective and engaging instruction by integrating a variety of methods and developments in pedagogy and lesson content.',
        ratingBenchmark: []
    },
    {
        title: 'Developing Innovative Teaching',
        description: 'Use a variety of techniques and use questions to deepen students understanding of a concept.',
        ratingBenchmark: []
    },
    {
        title: '',
        description: 'Demonstrate examples of teaching using innovative learning strategies and management techniques for specialized subjects.',
        ratingBenchmark: []
    },
    {
        title: 'Assess Student Learning and Provide Evaluative Feedback',
        description: 'Provide opportunities & space to discuss with the Head of Department / teacher and interpret effective test results for students and parents.',
        ratingBenchmark: []
    },
    {
        title: '',
        description: 'Monitor student performance in the subjects taught; provide guidance to teachers for the improvement of their performance.',
        ratingBenchmark: []
    },
    {
        title: 'Have internal personal skills and communication with students',
        description: 'Maintain a positive environment and provide a climate that provides opportunities between Heads of Department, teachers and students to communicate effectively.',
        ratingBenchmark: []
    },
    {
        title: '',
        description: 'Design and lead the implementation of effective mentoring programs to support and motivate students to give their best',
        ratingBenchmark: []
    },
    {
        title: 'Supportive of the Madrasah Policy, Vision, Mission and Program',
        description: 'Participate in the development and review of madrasah rules and regulations.Develop activities and programs to align the goals of the madrasah with a broader vision.',
        ratingBenchmark: []
    },
    {
        title: '',
        description: 'Lead the entire teaching staff in initiatives and assignments that will bring about the effectiveness and development of the madrasah.Encourage open expression of ideas and opinions.',
        ratingBenchmark: []
    },
    {
        title: 'Effectiveness of Tasks in Teams',
        description: 'Recognize that the Head of Department/ teachers in the department have different views and suggestions.Advise teachers to perform tasks when needed.Provide professional development opportunities for fellow faculty members to step out of their comfort zone.',
        ratingBenchmark: []
    },
    {
        title: '',
        description: 'Display team spirit by collaborating with teachers and educators and overcoming obstacles in achieving common goals.Give credit to team members who have performed well.',
        ratingBenchmark: []
    },
    {
        title: 'Collaborate with parents',
        description: 'Provide a climate that encourages communication between teachers and parents.Provide opportunities to foster two - way communication towards working together to help students.Maintain parental involvement in madrasah programs and activities.',
        ratingBenchmark: []
    },
    {
        title: '',
        description: 'Build and maintain ongoing communication between the madrasah and parents to support the development and well - being of students.',
        ratingBenchmark: []
    },
    {
        title: 'Behavior Management',
        description: 'Be part of the disciplinary committee and provide good advice and support to the DM.Follow disciplinary policies and guidelines when dealing with students.',
        ratingBenchmark: []
    },
    {
        title: '',
        description: 'Fully support teachers in addressing all aspects of student behavior management.Monitor the progress and development of students as a whole within the department.',
        ratingBenchmark: []
    },
    {
        title: 'Communication',
        description: 'Distribute and share accurate and quality information on madrasah management. The information given to the teaching staff and shared is accurate and quality, can be channeled to the management and teaching staff in a timely manner.',
        ratingBenchmark: []
    },
    {
        title: '',
        description: 'Communicate information and ideas clearly through various media to all educators and help them understand and keep records of information received.',
        ratingBenchmark: []
    },
    {
        title: 'Curriculum Planning & Delivery',
        description: 'Able to review/ review the syllabus, provide work schemes, SOW and resources for teaching staff in the department.Keep up - to - date information on lessons and teaching methods.',
        ratingBenchmark: []
    },
    {
        title: '',
        description: 'Supervise and check teacher planning and teaching records, student work to ensure syllabus is completed.Review students work in each term to ensure that teachers complete lessons according to the weekly lesson plan.',
        ratingBenchmark: []
    },
    {
        title: 'Professional Leadership',
        description: 'Meet with teachers regularly - to get feedback and teacher input on student progress and development.Hold professional partnership sessions with teachers on a regular basis.',
        ratingBenchmark: []
    },
    {
        title: '',
        description: 'Observe teachers in formal and informal monitoring.Implement a Performance Management System(PMS) and act as a Reporting Officer and mentor to improve performance during the teaching review period.',
        ratingBenchmark: []
    },
    {
        title: '',
        description: 'Arranging relief teaching staff when teachers are not present. Ensure teaching materials and schedules are explained to the assistive teaching staff.',
        ratingBenchmark: []
    },
    {
        title: 'Department Administration',
        description: 'Assist the Vice Principal in planning the budget for the department.Divide the department\'s budget and ensure the department\'s expenditure according to the budget.Maintain records of purchases of departmental necessities.',
        ratingBenchmark: []
    },

    {
        title: '',
        description: 'Manage teachers personal files for attendance records, course records attended, leave records, training and etc.',
        ratingBenchmark: []
    },
    {
        title: 'Staff Development / Management',
        description: 'Manage training and professional development for instructors.Assist the Vice Principal in ensuring teacher development is identified and appropriate training is planned for teachers.Training grants are fully utilized.',
        ratingBenchmark: []
    },
    {
        title: '',
        description: 'Organize teacher positions in the department efficiently and effectively.Design and implement mentoring / induction programs for new instructors.Distribute tasks evenly.Provide space for teachers to show their best abilities.',
        ratingBenchmark: []
    },
    {
        title: 'Attitude and work commitment',
        description: 'Perform tasks professionally.Has a high level of work ethic.Do not allow personal issues and other commitments to affect job performance.',
        ratingBenchmark: []
    },
    {
        title: '',
        description: 'Demonstrate patience in all transactions.Uphold a high level of sincerity at all times.Perform tasks diligently and on time.Keep thorough records.Fully committed and trustworthy.',
        ratingBenchmark: []
    },
    {
        title: '',
        description: 'Be human capital on other teaching staff by showing sincerity in covering all aspects of their assignments.Demonstrates respect for the needs of others and promotes equality and diversity.',
        ratingBenchmark: []
    }, {
        title: 'Self Management and Development',
        description: 'Continuing development and developing one\'s own ability to achieve the goals of the madrasah.Always be prepared to take on responsibilities outside the classroom when needed.Motivated to complete various tasks and projects on time.',
        ratingBenchmark: []
    },
    {
        title: '',
        description: 'There is never a delay in reporting while on duty.Can be present on time on duty.Contact and report on delays and absences to the supervisor accordingly.',
        ratingBenchmark: []
    },
    {
        title: 'Managing Multiple Priorities',
        description: 'Demonstrates the ability to plan and organize work and time to achieve goals.Able to manage time efficiently and able to complete tasks based on priorities and time needs.',
        ratingBenchmark: []
    },
    {
        title: 'Problem Solving',
        description: 'Able to use appropriate methods to resolve departmental issues with effective solutions.Able to manage situations based on reference to own knowledge, experience and other resources.',
        ratingBenchmark: []
    },
    {
        title: 'Making decisions',
        description: 'Identify and understand the issues, problems, and opportunities available for departmental improvement.Able to use an effective approach in choosing actions or finding the right solution.Take consistent action based on available facts and restrictions.',
        ratingBenchmark: []
    }
];



