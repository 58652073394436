import React, { useState, useEffect } from 'react';
import { Grid, Card, Table, Button, Dropdown } from 'tabler-react';
import Axios from 'axios';
import settings from '../app.config';

const Calendar = {
    Index: (props) => {

        const [data, setData] = useState({ year: 0, month: 0, day: 0 });
        const [days, setDays] = useState([]);
        const [dayPlanner, setDayPlan] = useState([]);
        const [editDay, setEditDay] = useState(false);
        const [slotSbjTutors, setSlot] = useState([]);
        const [slotSubjects, setSlotSbj] = useState([]);

        useEffect(() => {
            const date = new Date();
            setMonth(date.getDate(), date.getMonth() + 1, date.getFullYear());

            pickDate();
        }, []);

        function setMonth(day, month, year) {
            const totalDays = new Date(year, month, 0).getDate();
            var pos = new Date(month + ' ' + 1 + ' ' + year).getDay();
            const daysArr = [];
            if (pos > 0) {
                for (let i = 0; i < pos; i++) {
                    daysArr.push(0);
                }
            }
            for (let i = 0; i < totalDays; i++) {
                daysArr.push(i + 1);
            }
            setData({ day, month, year });
            setDays([...daysArr]);
        }

        function nextMonth() {
            let month = data.month + 1;
            let year = data.year;
            if (month > 12) {
                month = 1;
                year++;
            }
            // setData({ ...data, month, year })
            setMonth(data.day, month, year);
        }

        function prevMonth() {
            let month = data.month - 1;
            let year = data.year;
            if (month < 1) {
                month = 12;
                year--;
            }
            // setData({ ...data, month, year })
            setMonth(data.day, month, year);
        }

        function pickDate(day = null) {
            let param = null;
            if (day) {
                param = day + '-' + data.month + '-' + data.year;
                setData({ ...data, day });
            }

            setDayPlan([]);

            Axios.get(settings.server + '/batch/' + props.batch.id + '/scheduler/day?date=' + param)
                .then(res => {
                    if (res.data.success) {
                        setDayPlan(res.data.data);
                    }
                }, err => {

                });
        }

        function setPanel(val) {
            setEditDay(val);
            if (!val) {
                pickDate(data.day);
            }
        }

        function fetchAvailableReplaceSubjects(slot) {
            if (!editDay) {
                return;
            }
            let day = new Date(data.month + ' ' + data.day + ' ' + data.year).getDay();
            if (day == 0) {
                day = 7;
            }
            setSlotSbj([]);
            Axios.get(settings.server + '/batch/scheduler/slot/replace?batch=' + props.batch.id + '&day=' + day + '&time_from=' + slot.time_from + '&time_to=' + slot.time_to)
                .then(res => {
                    if (res.data.success) {
                        setSlotSbj(res.data.data);
                    }
                });
        }

        function fetchAvailableReplace(slot) {
            if (!editDay) {
                return;
            }
            let day = new Date(data.month + ' ' + data.day + ' ' + data.year).getDay();
            if (day == 0) {
                day = 7;
            }
            setSlot([]);
            Axios.get(settings.server + '/batch/scheduler/slot/tutor/replace?batch=' + props.batch.id + '&day=' + day + '&time_from=' + slot.time_from + '&time_to=' + slot.time_to + '&subject=' + slot.id)
                .then(res => {
                    if (res.data.success) {
                        setSlot(res.data.data);
                    }
                });
        }

        function replaceSlotSubject(slot, subject) {
            dayPlanner[slot].id = subject.id;
            dayPlanner[slot].name = subject.name;
            dayPlanner[slot].short_name = subject.short_name;
            dayPlanner[slot].color = subject.color;

            setDayPlan([...dayPlanner]);
        }

        function replaceSlotTutor(slot, tutor) {
            dayPlanner[slot].tutor = tutor.name;
            dayPlanner[slot].tutor_id = tutor.id;
            dayPlanner[slot].substitute = true;

            setDayPlan([...dayPlanner]);
        }

        function delSubstitution(slot) {
            dayPlanner[slot].id = null;
            dayPlanner[slot].name = null;
            dayPlanner[slot].short_name = null;
            dayPlanner[slot].tutor = null;
            dayPlanner[slot].tutor_id = null;
            dayPlanner[slot].substitute = false;

            setDayPlan([...dayPlanner]);
        }


        function saveSubstitute() {
            Axios.post(settings.server + '/batch/scheduler/slot/replace', { batch: props.batch.id, date: data.day + '-' + data.month + '-' + data.year, subjects: dayPlanner })
                .then(res => {
                    if (res.data.success) {
                        setEditDay(false);
                    }
                });
        }

        const dayList = dayPlanner.map((subject, key) =>
            editDay ? <Table.Row>
                <Table.Col>{key + 1}</Table.Col>
                <Table.Col>
                    {
                        // subject.name ?
                        //     <span className="subject-pill" style={{ backgroundColor: subject.color }}>{subject.name}</span> : 'Free'
                    }
                    <Dropdown className="subject-pill" >
                        <div onClick={() => fetchAvailableReplaceSubjects(subject)}>
                            <Dropdown.Trigger style={{ backgroundColor: subject.color || '#000' }}>
                                {
                                    subject && subject.id ? <label >{subject.name || subject.id}</label> : 'Select'
                                }
                            </Dropdown.Trigger>
                        </div>
                        <Dropdown.Menu>
                            <Dropdown.Item><b>Replace with</b></Dropdown.Item>
                            <Dropdown.ItemDivider />
                            {
                                slotSubjects.map(sbj => <Dropdown.Item onClick={() => replaceSlotSubject(key, sbj)}>{sbj.name}</Dropdown.Item>)
                            }

                        </Dropdown.Menu>
                    </Dropdown>
                </Table.Col>
                <Table.Col>
                    <Dropdown className="subject-pilld" >
                        <div onClick={() => fetchAvailableReplace(subject)}>
                            <Dropdown.Trigger>
                                {subject.tutor}
                            </Dropdown.Trigger>
                        </div>
                        {subject.substitute ? <h5 style={{ cursor: 'pointer' }} onClick={() => delSubstitution(key)}>Cancel Substitute</h5> : ''}
                        <Dropdown.Menu>
                            <Dropdown.Item><b>Replace with</b></Dropdown.Item>
                            <Dropdown.ItemDivider />
                            {
                                slotSbjTutors.map(sbj => <Dropdown.Item onClick={() => replaceSlotTutor(key, sbj)}>{sbj.name}</Dropdown.Item>)
                            }

                        </Dropdown.Menu>
                    </Dropdown>
                </Table.Col>
            </Table.Row> :
                <Table.Row>
                    <Table.Col>{key + 1}</Table.Col>
                    <Table.Col>
                        <span className="subject-pill" style={{ backgroundColor: subject.color ? subject.color : '#000' }}>{subject.name ? subject.short_name : subject.id}</span>
                    </Table.Col>
                    <Table.Col>
                        {subject.tutor}
                        {subject.substitute ? <h5>(Substitute)</h5> : ''}
                    </Table.Col>
                </Table.Row>);

        return <Grid.Row cards deck>
            <Grid.Col md={8}>
                <Card><Card.Body style={{ padding: 0 }}><div className="calendar-view_wrapper">
                    <div className="head">Sunday</div>
                    <div className="head">Monday</div>
                    <div className="head">Tuesday</div>
                    <div className="head">Wednesday</div>
                    <div className="head">Thursday</div>
                    <div className="head">Friday</div>
                    <div className="head">Saturday</div>
                    {
                        days.map((item, k) => <div className="c-cell" key={k}>
                            {
                                item > 0 ? <div onClick={() => pickDate(item)} className={item == data.day ? 'active' : ''}>{item}</div> : ''
                            }
                        </div>)
                    }

                </div ></Card.Body>
                </Card>
            </Grid.Col>
            <Grid.Col md={4}>
                <Card>
                    <Card.Header>
                        <Card.Title>Day Planner
                            <h5 className="mt-2">{data.day}/{data.month}/{data.year}</h5>
                        </Card.Title>
                        <Card.Options>
                            {
                                dayPlanner.length > 0 && !editDay ?
                                    <Button color="primary" outline size="sm" onClick={() => setPanel(!editDay)}>Substitute</Button> : ''
                            }
                            {
                                editDay ?
                                    <><Button color="primary" outline size="sm" onClick={() => setPanel(!editDay)}>Cancel</Button> <Button color="success" size="sm" className="ml-2" onClick={saveSubstitute}>Save</Button></> : ''
                            }
                        </Card.Options>
                    </Card.Header>
                    <Card.Body>
                        {
                            dayPlanner.length > 0 ? <Table>
                                <Table.Header>
                                    <Table.ColHeader>Period</Table.ColHeader>
                                    <Table.ColHeader>Subject</Table.ColHeader>
                                    <Table.ColHeader>TUtor</Table.ColHeader>
                                </Table.Header>
                                <Table.Body>
                                    {dayList}
                                </Table.Body>
                            </Table> : ''
                        }
                    </Card.Body>
                </Card>
            </Grid.Col>
        </Grid.Row>
    }
};


export default Calendar;
