import React, { useState, useEffect } from 'react';
import { Switch, Route, Link, useRouteMatch, useParams, useHistory } from "react-router-dom";
import axios from 'axios';
import { Card, Button, Grid, Header, List, Table, Dimmer, Alert, Icon, Form, Badge, Dropdown, Tabs, Tab } from 'tabler-react';
import Attendance from './Attendance';
import Exam from './Exam';
import Student from './Student';
import settings from '../app.config';
import { useStore } from '../utils/Store';

import imgExams from '../assets/placeholders/exams_default.svg';
import imgSubjects from '../assets/placeholders/subjects_default.svg';
import imgStudents from '../assets/placeholders/students_default.svg';
import Schedule from './Schedule';
import Axios from 'axios';
import Calendar from './Calendar';
import Popup from 'reactjs-popup';

const Manage = () => {
    let match = useRouteMatch();
    let history = useHistory();
    let { classID } = useParams();
    const [store, updateStore] = useStore();
    const [batch, setBatch] = useState({ busy: true, success: false });
    const [delWarning, setDelAlert] = useState(false);
    const [showEditName, setEditNameDialog] = useState(false);
    const [editForm, setEditForm] = useState({ year: '', division: '' });
    const [showError, setError] = useState({ display: false, busy: false, type: 'danger', message: '' });

    function loadData() {
        axios.get(settings.server + '/batch/' + classID)
            .then(res => {
                if (res.data.success) {
                    updateStore({ ...store, batch: res.data.data });
                    setBatch({ busy: false, success: true, ...res.data.data });
                }
            }, err => {
                setBatch({ busy: false, success: false });
            });
    }

    useEffect(() => {
        loadData();
    }, batch.success);

    function deactClass() {
        Axios.post(settings.server + '/batch/status', { id: classID, active: false })
            .then(res => {
                if (res.data.success) {

                }
            });
    }

    function deleteClass() {
        setDelAlert(!delWarning);
    }

    function deleteClassConfirm() {
        Axios.post(settings.server + '/batch/remove', { id: classID })
            .then(res => {
                if (res.data.success) {
                    const update = store.batches.filter(item => item.id != classID);
                    updateStore({ ...store, batches: update });
                    history.replace({ pathname: '/home/class' });
                }
            });
    }

    function editClass(show = true) {
        setEditForm({ division: batch.division, year: batch.year });
        setEditNameDialog(show);
    }


    function handleChange(event) {
        const d = { ...editForm };
        d[event.target.name] = event.target.value;
        setEditForm(d);
    }

    function updateClassInfo() {
        Axios.post(settings.server + '/batch/info/update', { id: batch.id, division: editForm.division, year: editForm.year }).then(res => {
            if (res.data.success) {
                setEditNameDialog(false);
                setBatch({ ...batch, ...res.data.data });
            }
        });
    }

    const showBody = !delWarning ? (<div>
        <div className="class-name-title">
            <Header.H1>{batch.class_name} - {batch.division}</Header.H1>
            <div style={{ display: 'flex' }}>
                <Link to={`${match.url}/calendar`}>
                    <Button icon="calendar" color="success">Calendar</Button>
                </Link>
                {store.rights && store.rights.class_remove ? <Dropdown triggerContent="More">
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={editClass}>Edit</Dropdown.Item>
                        <Dropdown.Item onClick={deactClass}>Deactivate</Dropdown.Item>
                        <Dropdown.Item onClick={deleteClass}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> : ''}
            </div>
        </div>
        <Switch>
            <Route path={`${match.path}/attendance`}>
                <Attendance.Update batch={batch} />
            </Route>
            <Route path={`${match.path}/students/:studentID`}>
                <Student.Home batch={batch} />
            </Route>
            <Route path={`${match.path}/students`}>
                <Student.All batch={batch} />
            </Route>
            <Route path={`${match.path}/schedule`}>
                <Schedule.Index batch={batch} />
            </Route>
            <Route path={`${match.path}/calendar`}>
                <Calendar.Index batch={batch} />
            </Route>
            <Route path={`${match.path}/exam/:examID`}>
                <Exam.Home batch={batch} />
            </Route>
            <Route path={`${match.path}`}>
                <ClassModules.Overview batch={batch} />
                <Grid.Row cards >
                    <Grid.Col md="6">
                        <ClassModules.Timetable />
                        {/* <ClassModules.Exams /> */}
                    </Grid.Col>
                    <Grid.Col md="6">
                        <ClassModules.Subjects />
                        {/* <ClassModules.Students /> */}
                    </Grid.Col>
                </Grid.Row >
            </Route>
        </Switch></div>) :
        <Alert type="danger" isDismissible>
            <Header.H4>Confirm Delete</Header.H4>
            <p>You will lose all the data associated with this class. This is a permanent process. You can't recover your data after confirmation.</p>
            <Button.List>
                <Button color="secondary" RootComponent="button" onClick={deleteClass}>Cancel</Button>
                <Button color="danger" outline RootComponent="button" onClick={deleteClassConfirm}>Confirm Delete</Button>
            </Button.List>
        </Alert>;

    const showErrorBox = (<Alert type='danger' icon='alert-triangle'>Batch not found.</Alert>);


    const EditNameModal = <Popup open={true} onClose={() => editClass(false)} >
        <div className="modal" style={{ width: '500px', minWidth: '500px' }}>
            <a className="close-modal" onClick={() => editClass(false)}>&times;</a>
            <Card>
                <Card.Header>
                    <Icon name="calendar" /> <b><span className="card-label">Edit</span></b>
                </Card.Header>
                <Card.Body>
                    <Grid.Row>
                        <Grid.Col>
                            {showError.display ? (<Alert type={showError.type} icon={showError.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                                {showError.message}
                            </Alert>) : ''}
                            <Form.Group label="Class Division" isRequired>
                                <Form.Input name="division" value={editForm.division} onChange={handleChange.bind(this)} />
                            </Form.Group>
                            <Form.Group label="Year" isRequired>
                                <Form.Input name="year" value={editForm.year} onChange={handleChange.bind(this)} />
                            </Form.Group>
                        </Grid.Col>
                    </Grid.Row>
                </Card.Body>
                <Card.Footer>
                    <div>
                        <Button color="secondary" outline onClick={() => editClass(false)}>Cancel</Button>
                        <Button color="success" loading={showError.busy} icon="save" onClick={() => updateClassInfo()}>Save</Button>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    </Popup>;


    return (
        <Dimmer active={batch.busy} loader>
            {
                batch.success ? showBody : showErrorBox
            }
            {
                showEditName ? EditNameModal : null
            }
        </Dimmer >
    )


}

const ClassModules = {
    Overview: (props) => {
        let match = useRouteMatch();
        const [store] = useStore();
        const [editTutor, setEditTutor] = useState(false);
        const [form, setForm] = useState({ name: props.batch.teacher_name, teacher: props.batch.teacher_id });

        function toggleEditTeacher() {
            setEditTutor(!editTutor);
        }

        function saveTutor() {
            if (form.teacher == null || form.teacher == '') {
                return;
            }
            axios.post(settings.server + '/batch/tutor/update', { batch: store.batch.id, tutor: form.teacher })
                .then(res => {
                    setEditTutor(false);
                    if (res.data.data) {
                        setForm({ name: res.data.data.teacher_name, teacher: res.data.data.teacher_id });
                    }
                });
        }

        const handleForm = (event) => {
            form[event.target.name] = event.target.value;
            setForm({ ...form })
        }


        return (
            <Grid.Row cards deck>
                <Grid.Col md={4}>
                    <Card statusColor="teal">
                        <Card.Body className="common-status-card">
                            {editTutor ? <div className="wrap-btn">
                                <Button color="secondary" outline size="sm" onClick={toggleEditTeacher}>Cancel</Button>
                                <Button color="success" className="ml-3" size="sm" onClick={saveTutor}>Save</Button>
                            </div> : null}
                            {store.user.user_type == 'ADMIN' && !editTutor ? <Button className="wrap-btn" color="primary" outline size="sm" onClick={toggleEditTeacher}>Edit</Button> : null}
                            <Header.H4>Form Teacher</Header.H4>
                            {
                                editTutor ?
                                    <Form.Group className="mt-5">
                                        <Form.Select name="teacher" value={form.teacher} onChange={handleForm.bind(this)}>
                                            <option value=''>Select a Teacher</option>
                                            {
                                                store.teachers.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                            }
                                        </Form.Select>
                                    </Form.Group> : <Header.H2>{form.name}</Header.H2>
                            }
                        </Card.Body>
                    </Card>
                </Grid.Col>
                {/* <Grid.Col md={4}>
                    <Card statusColor="purple">
                        <Card.Body>
                            <Grid.Row>
                                <Grid.Col md={8}>
                                    <Header.H4>Today's Strength</Header.H4>
                                    <Header.H1 className="label-sup">{store.batch.attendance.total_present}<span>/{props.batch.students_count}</span></Header.H1>
                                </Grid.Col>
                                <Grid.Col md={2}>
                                    <div>
                                        <Card.Options>
                                            <Link to={`${match.url}/attendance`}>
                                                <Button color="primary" outline size="sm" className="ml-2">Update</Button>
                                            </Link>
                                        </Card.Options>
                                    </div>
                                </Grid.Col>
                            </Grid.Row>
                        </Card.Body>
                    </Card>
                </Grid.Col> */}
            </Grid.Row>
        )
    },
    Exams: () => {
        const history = useHistory();
        const match = useRouteMatch();
        const [store] = useStore();
        const [form, setForm] = useState({ busy: false, value: '', display: false });

        const showAddPanel = () => {
            setForm({ ...form, display: true });
        }

        const examList = (<List.Group>
            <ExamsHandler.List />
            <List.GroupItem icon="plus-square" active onClick={showAddPanel}>Add new exam</List.GroupItem>
        </List.Group>);

        const formHandler = (event) => {
            setForm({ busy: false, display: true, value: event.target.value });
        }

        const addExam = () => {
            axios.post(settings.server + '/batch/exams/add', { batch: store.batch.id, exam: form.value })
                .then(res => {
                    if (res.data.success) {
                        history.replace({ pathname: match.url + "/exam/" + res.data.data.id });
                    }
                });

        };

        return (<Card>
            <Card.Header>
                <Card.Title>Exams</Card.Title>
            </Card.Header>
            <Card.Body style={{ padding: 0 }}>
                {
                    store.batch.exams && store.batch.exams.length > 0 ? examList : (<div className="placeholder-img"><img src={imgExams} alt='no exams found' />
                        <Header.H4 className="label">You haven't created any exams yet.</Header.H4>
                    </div>)
                }
                {
                    form.display || store.batch.exams.length === 0 ?

                        (<List.Group>
                            <List.GroupItem active>
                                <Form.Group label="Add new exam">
                                    <Grid.Row gutters="xs">
                                        <Grid.Col style={{ position: 'relative' }}>
                                            <Form.Select onChange={formHandler.bind(this)} disabled={form.busy}>
                                                <option value=''>Choose a exam</option>
                                                {
                                                    store.exams.map(item => <option value={item.id} key={item.id}>{item.name}</option>)
                                                }
                                            </Form.Select>
                                        </Grid.Col>
                                        <Grid.Col auto>
                                            <Button color="success" icon="plus" onClick={addExam} disabled={form.busy} />
                                        </Grid.Col>
                                    </Grid.Row>
                                </Form.Group>
                            </List.GroupItem>
                        </List.Group>) : ''
                }
            </Card.Body>
        </Card>)
    },
    Timetable: () => {
        let urlMatch = useRouteMatch();
        const [store, updateStore] = useStore();
        const [dayPlanner, setDayPlan] = useState([]);
        const [allSlots, setSlots] = useState([]);

        useEffect(() => {
            Axios.get(settings.server + '/batch/' + store.batch.id + '/scheduler/day?date=today')
                .then(res => {
                    if (res.data.success) {
                        setDayPlan(res.data.data);
                        setSlots(res.data.slots);
                    }
                }, err => {

                });
        }, []);

        const dayList = dayPlanner.map((subject, key) =>
            <Table.Row key={key}>
                <Table.Col>{allSlots[key] ? allSlots[key].time : ''}</Table.Col>
                <Table.Col>
                    {subject.name ?
                        <span className="subject-pill" style={{ backgroundColor: subject.color }}>{subject.name}</span> : subject.id
                    }
                </Table.Col>
                <Table.Col>
                    {subject.tutor}
                    {subject.substitute ? <h5>(Substitute)</h5> : ''}
                </Table.Col>
            </Table.Row>
        );

        return (<Card>
            <Card.Header>
                <Card.Title>Daily Schedule</Card.Title>
                <Card.Options>
                    <Link to={`${urlMatch.url}/schedule`}>
                        <Button color="primary" outline size="sm">Manage</Button>
                    </Link>
                </Card.Options>
            </Card.Header>
            <Card.Body style={{ padding: 0 }}>
                {dayPlanner.length == 0 ?
                    (<div className="placeholder-img"><img src={imgSubjects} alt='Timetable not generated' />
                        <Header.H4 className="label">Get started</Header.H4>
                        <Link to={`${urlMatch.url}/schedule`}>
                            <Button color="primary">
                                Generate Timetable
                                </Button>
                        </Link>
                    </div>) : ''
                }
                {
                    dayPlanner.length > 0 ? <Table>
                        <Table.Header>
                            <Table.ColHeader>Period</Table.ColHeader>
                            <Table.ColHeader>Subject</Table.ColHeader>
                            <Table.ColHeader>Teacher</Table.ColHeader>
                        </Table.Header>
                        <Table.Body>
                            {dayList}
                        </Table.Body>
                    </Table> : ''
                }
            </Card.Body>
        </Card>);
    },
    Subjects: () => {
        const [store, updateStore] = useStore();
        const [form, setForm] = useState({ busy: false, show: false, value: '', id: '', tutor: null });
        const [showPanel, setPanel] = useState(false);

        const handleForm = (event) => {
            setForm({ show: true, value: event.target.value, id: null, tutor: null });
        };

        const setSubjectTutor = (event) => {
            setForm({ ...form, tutor: event.target.value });
        }

        const addSubject = () => {
            if (form.busy || !form.id) {
                return;
            }
            setForm({ ...form, show: false, busy: true });
            axios.post(settings.server + '/batch/subjects/add', { subject: form.id, batch: store.batch.id, tutor: form.tutor })
                .then(res => {
                    setForm({ ...form, busy: false });
                    if (res.data.success) {
                        const newsubli = [res.data.data];
                        updateStore({ ...store, batch: { ...store.batch, subjects: store.batch.subjects.concat(newsubli) } });
                        setForm({ ...form, busy: false, value: '', id: '' });
                    }
                }, err => {
                    setForm({ ...form, busy: false, show: false });
                });
        };

        const addRoom = () => {
            if (form.busy || !form.id) {
                return;
            }
            setForm({ ...form, show: false, busy: true });
            axios.post(settings.server + '/batch/amenities/add', { room: form.id, batch: store.batch.id, tutor: form.tutor })
                .then(res => {
                    setForm({ ...form, busy: false });
                    if (res.data.success) {
                        const newsubli = [res.data.data];
                        updateStore({ ...store, batch: { ...store.batch, amenities: store.batch.amenities.concat(newsubli) } });
                        setForm({ ...form, busy: false, value: '', id: '' });
                    }
                }, err => {
                    setForm({ ...form, busy: false, show: false });
                });
        };

        return (<Card><Tabs initialTab="Subjects">
            <Tab title="Subjects">
                <div style={{ right: '20px', position: 'absolute', top: '13px' }}>
                    {store.batch.subjects && store.batch.subjects.length > 0 && store.user.user_type == 'ADMIN' ?
                        <Button color="primary" outline size="sm" onClick={() => setPanel(!showPanel)}>{showPanel ? 'Hide' : 'Manage'}</Button> : ''
                    }
                </div>
                {
                    store.batch.subjects && store.batch.subjects.length > 0 ? <Subjectshandler.List controls={showPanel} /> : (<div className="placeholder-img"><img src={imgSubjects} alt='no subjects added' />
                        <Header.H4 className="label">It seems you didn't added any subjects associated with this class</Header.H4>
                    </div>)
                }
                {
                    showPanel || store.batch.subjects.length === 0 ?

                        (<List.Group>
                            <List.GroupItem active>
                                <Form.Group label="Add new subject">
                                    <Grid.Row gutters="xs">
                                        <Grid.Col style={{ position: 'relative' }}>
                                            <Form.Input placeholder="Search for..." onClick={() => setForm({ ...form, show: true })} value={form.value} onChange={handleForm.bind(this)} />
                                            {
                                                form.show ? (
                                                    <List.Group className="nav-list add-sub-list" style={{ position: 'relative' }}>
                                                        {
                                                            store.subjects.reduce((res, item) => {
                                                                if (item.name.toLowerCase().match(form.value.toLowerCase())) {
                                                                    res.push(<List.GroupItem className="hover-item" key={item.id} onClick={() => setForm({ ...form, value: item.name, id: item.id, show: false, tutor: null })}>{item.name}</List.GroupItem>);
                                                                }
                                                                return res;
                                                            }, [])
                                                        }{
                                                            store.subjects.length > 5 && !form.value ? <List.GroupItem className="item">{store.subjects.length} results</List.GroupItem> : ''
                                                        }
                                                    </List.Group>
                                                ) : ''
                                            }
                                            {
                                                form.id ? <Form.Select className="mt-4" onChange={setSubjectTutor.bind(this)} >
                                                    <option value=''>Choose Teacher</option>
                                                    {
                                                        store.teachers.reduce((acc, item) => {
                                                            if (item.subjects.filter(s => s.id == form.id).length > 0) {
                                                                acc.push(<option value={item.id} key={item.id}>{item.name}</option>)
                                                            }
                                                            return acc;
                                                        }, [])
                                                    }
                                                </Form.Select> : ''
                                            }

                                        </Grid.Col>
                                        <Grid.Col auto>
                                            <Button
                                                color="success"
                                                icon="plus" onClick={addSubject}
                                            />
                                        </Grid.Col>
                                    </Grid.Row>
                                </Form.Group>
                            </List.GroupItem>
                        </List.Group>) : ''
                }
            </Tab>
            <Tab title="Activities">
                <div style={{ right: '20px', position: 'absolute', top: '13px' }}>
                    {store.batch.amenities && store.batch.amenities.length > 0 && store.user.user_type == 'ADMIN' ?
                        <Button color="primary" outline size="sm" onClick={() => setPanel(!showPanel)}>{showPanel ? 'Hide' : 'Manage'}</Button> : ''
                    }
                </div>
                {
                    store.batch.amenities && store.batch.amenities.length > 0 ? <RoomsList.List controls={showPanel} /> : (<div className="placeholder-img"><img src={imgSubjects} alt='no rooms added' />
                        <Header.H4 className="label">It seems you didn't added any rooms associated with this class</Header.H4>
                    </div>)
                }
                {
                    showPanel || store.batch.amenities.length === 0 ?

                        (<List.Group>
                            <List.GroupItem active>
                                <Form.Group label="Add new activity">
                                    <Grid.Row gutters="xs">
                                        <Grid.Col style={{ position: 'relative' }}>
                                            <Form.Input placeholder="Search for..." onClick={() => setForm({ ...form, show: true })} value={form.value} onChange={handleForm.bind(this)} />
                                            {
                                                form.show ? (
                                                    <List.Group className="nav-list add-sub-list" style={{ position: 'relative' }}>
                                                        {
                                                            store.amenities.reduce((res, item) => {
                                                                if (item.name.toLowerCase().match(form.value.toLowerCase())) {
                                                                    res.push(<List.GroupItem className="hover-item" key={item.id} onClick={() => setForm({ ...form, value: item.name, id: item.id, show: false, tutor: null })}>{item.name}</List.GroupItem>);
                                                                }
                                                                return res;
                                                            }, [])
                                                        }{
                                                            store.amenities.length > 5 && !form.value ? <List.GroupItem className="item">{store.amenities.length} results</List.GroupItem> : ''
                                                        }
                                                    </List.Group>
                                                ) : ''
                                            }

                                            {
                                                form.id ? <Form.Select className="mt-4" onChange={setSubjectTutor.bind(this)} >
                                                    <option value=''>Choose Teacher</option>
                                                    {
                                                        store.teachers.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)
                                                    }
                                                </Form.Select> : ''
                                            }
                                        </Grid.Col>
                                        <Grid.Col auto>
                                            <Button
                                                color="success"
                                                icon="plus" onClick={addRoom}
                                            />
                                        </Grid.Col>
                                    </Grid.Row>
                                </Form.Group>
                            </List.GroupItem>
                        </List.Group>) : ''
                }
            </Tab>
        </Tabs>
        </Card>);
    },
    Students: () => {
        let urlMatch = useRouteMatch();
        const [store] = useStore();

        return (
            <Card>
                <Card.Header>
                    <Card.Title>Students</Card.Title>
                    <Card.Options>
                        {
                            store.batch.students_count > 0 ? <Link to={`${urlMatch.url}/students`}><Button color="primary" outline size="sm">Manage</Button></Link> : ''
                        }
                    </Card.Options>
                </Card.Header>
                <Card.Body style={{ padding: 0 }}>
                    {
                        store.batch.students_count > 0 ? <Studentshandler.List /> : (<div className="placeholder-img"><img src={imgStudents} alt='no students added yet' />
                            <Header.H4 className="label">Start adding students to your new class.</Header.H4>
                            <Link to={`${urlMatch.url}/students`}>
                                <Button color="primary">
                                    <Icon name="plus" /> Add Students
                                </Button>
                            </Link>
                        </div>)
                    }
                </Card.Body>
            </Card>
        );
    }

}



const ExamsHandler = {
    List: () => {
        let match = useRouteMatch();
        const [store] = useStore();

        let el = store.batch.exams.map((item, key) => (<List.GroupItem key={key}>
            <Grid.Row justifyContent="space-between">
                <Grid.Col>{item.name}</Grid.Col>
                <Grid.Col style={{ display: 'flex', justifyContent: 'flex-end' }}><Link to={`${match.url}/exam/${item.id}`}><Button color="secondary" size="sm">Manage</Button></Link></Grid.Col>
            </Grid.Row>
        </List.GroupItem>));

        return el;
    }

}

const Subjectshandler = {
    List: (props) => {
        const [store, setStore] = useStore();

        const removeItem = (id, index) => {
            Axios.post(settings.server + '/batch/subjects/remove', { subject: id, batch: store.batch.id })
                .then(res => {
                    if (res.data.success) {
                        const subjects = [...store.batch.subjects];
                        subjects.splice(index, 1);

                        setStore({
                            ...store,
                            batch: {
                                ...store.batch,
                                subjects
                            }
                        })
                    }
                });
        };

        return (
            <List.Group>
                {
                    store.batch.subjects.map((val, key) =>
                        <List.GroupItem className="hover-controls" key={key}>
                            <div>{val.name}
                                <div>Teacher: <i>{val.tutor}</i></div>
                            </div>
                            <div>
                                {
                                    props.controls ? (<Badge color="danger" className="controls cursor" onClick={() => removeItem(val.id, key)}>Delete</Badge>) : ''
                                }
                            </div>
                        </List.GroupItem>
                    )
                }
            </List.Group>
        );
    }

}



const RoomsList = {
    List: (props) => {
        const [store, setStore] = useStore();

        const removeItem = (id, index) => {
            Axios.post(settings.server + '/batch/amenities/remove', { room: id, batch: store.batch.id })
                .then(res => {
                    if (res.data.success) {
                        const amenities = [...store.batch.amenities];
                        amenities.splice(index, 1);

                        setStore({
                            ...store,
                            batch: {
                                ...store.batch,
                                amenities
                            }
                        })
                    }
                });
        };

        return (
            <List.Group>
                {
                    store.batch.amenities.map((val, key) =>
                        <List.GroupItem className="hover-controls" key={key}>
                            <div>
                                <div>{val.name}{
                                    val.shared == 1 ? <Badge color="secondary" style={{ marginLeft: '20px' }}>Shared</Badge> : ''
                                }
                                </div>
                                {val.tutor_id ? <div>Teacher: <i>{val.tutor}</i></div> : ''}
                            </div>
                            <div>
                                {
                                    props.controls ? (<Badge color="danger" className="controls cursor" onClick={() => removeItem(val.id, key)}>Delete</Badge>) : ''
                                }
                            </div>
                        </List.GroupItem>
                    )
                }
            </List.Group>
        );
    }

}

const Studentshandler = {
    List: () => {
        let match = useRouteMatch();
        const [store] = useStore();

        const listItems = store.batch.students.map((item, key) =>
            <Table.Row key={key}>
                <Table.Col>{key + 1}</Table.Col>
                <Table.Col>{item.firstname + ' ' + item.lastname}</Table.Col>
                <Table.Col>
                    <div className="view-student"><Link to={`${match.url}/students/${item.id}`}><Button color="secondary">Edit</Button></Link></div>
                </Table.Col>
            </Table.Row>
        );


        return (
            <div className="students-list">
                <Table>
                    <Table.Header>
                        <Table.ColHeader></Table.ColHeader>
                        <Table.ColHeader>Full Name</Table.ColHeader>
                        <Table.ColHeader></Table.ColHeader>
                    </Table.Header>
                    <Table.Body>
                        {listItems}
                    </Table.Body>
                </Table >
            </div >
        );
    }

}

export default Manage;