import React, { useState, useEffect } from 'react';
import { Card, Button, Dropdown, Grid, Header, Form, Alert, Icon, Table, Dimmer } from 'tabler-react';
import { useParams, Link, useRouteMatch } from "react-router-dom";
import axios from 'axios';
import settings from '../app.config';

import imgEmpty from '../assets/placeholders/no_results.svg';
import imgSearch from '../assets/placeholders/search.svg';
import { useStore } from '../utils/Store';

const Student = {
    Home: (props) => {
        let { studentID } = useParams();
        const [profile, setProfile] = useState({ busy: true, success: false, setElectives: [] });
        const [store] = useStore();
        const defaultThumb = 'https://blog.za3k.com/wp-content/uploads/2015/03/default_profile_6.png';

        let electivesSelected = [];
        useEffect(() => {
            axios.get(settings.server + '/batch/' + store.batch.id + '/student/' + studentID)
                .then(res => {
                    if (res.data.success) {
                        setProfile({ ...profile, busy: false, success: true, ...res.data.data, setElectives: res.data.data.electives });
                        electivesSelected = res.data.data.electives;
                    }
                }, err => {
                    setProfile({ busy: false, success: false });
                });
        }, profile.busy);

        const updateElective = (event) => {
            const id = event.value;
            const isChecked = event.checked;
            const index = profile.setElectives.indexOf(id);

            if (!isChecked && index > -1) {
                profile.setElectives.splice(index, 1);
            }

            if (isChecked && index === -1) {
                profile.setElectives.push(id)
            }
            setProfile({ ...profile, setElectives: profile.setElectives });
        };

        const saveElectives = () => {
            axios.post(settings.server + '/batch/student/electives/update', { student: studentID, batch: profile.batch, selection: profile.setElectives })
                .then(res => {

                });
        };

        const pageBody = (
            <Grid.Row card>
                <Grid.Col md={4}>
                    <Card title="Basic Profile">
                        <Card.Body>
                            <div className="basic-details">
                                <img className="thumb" src={profile.thumb ? profile.thumb : defaultThumb} alt="profile" />
                                <Header.H2>{profile.firstname + ' ' + profile.lastname} </Header.H2>
                                <Header.H3>Age: 18</Header.H3>
                            </div>

                        </Card.Body>
                        <Card.Footer>
                            <Button.List align="center">
                                <Link to={`/students/edit/${studentID}`}>
                                    <Button color="primary" size="sm" className="ml-2">View Profile</Button>
                                </Link>
                            </Button.List>
                        </Card.Footer>
                    </Card>
                </Grid.Col>
                <Grid.Col md={8}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Manage Subjects & Electives</Card.Title>
                            <Card.Options>
                                <Button color="success" size="sm" onClick={saveElectives}>Save</Button>
                            </Card.Options>
                        </Card.Header>
                        <Card.Body>

                            <Grid.Row>
                                <Grid.Col>
                                    <Header.H3>General Subjects</Header.H3>
                                    <Button.List>
                                        {
                                            store.batch.subjects.map(item => item.is_elective == 0 ? <Button pill color="primary">{item.name}</Button> : '')
                                        }

                                    </Button.List>
                                </Grid.Col>
                            </Grid.Row>
                        </Card.Body>
                        <Card.Footer className="footer-align-left" align="left">
                            <Grid.Row>
                                <Grid.Col>
                                    <Form.Group label="Manage Electives">
                                        <Form.SelectGroup
                                            canSelectMultiple
                                            pills
                                        >

                                            {
                                                store.batch.subjects.map(item => item.is_elective == 1 ? <Form.SelectGroupItem
                                                    label={item.name} name="language" type="checkbox" value={item.id} checked={profile.setElectives.indexOf(item.id) > -1} onClick={(event) => updateElective(event.target)} /> : '')
                                            }
                                        </Form.SelectGroup>
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>

                        </Card.Footer>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Card.Title>Custom Formulae</Card.Title>
                            <Card.Options>
                                <Button color="success" size="sm">Save</Button>
                            </Card.Options>
                        </Card.Header>
                        <Card.Body>
                            <Alert type="warning" icon="bell" isDismissible>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                </Alert>
                            <Form.Group label="Choose Formulae">
                                <Form.InputGroup>
                                    <Form.InputGroup prepend>
                                        <Button color="secondary" isDropdownToggle>Type A</Button>
                                        <Dropdown.Menu position="right">
                                            <Dropdown.Item>Type B</Dropdown.Item>
                                            <Dropdown.Item>Type C</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Form.InputGroup>
                                    <Form.Input value="A+B=C" disabled />
                                </Form.InputGroup>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Grid.Col>
            </Grid.Row>
        );

        const showError = (<Alert type='danger' icon='alert-triangle'>Student not found.</Alert>)

        return (<Dimmer active={profile.busy} loader>
            {
                profile.success ? pageBody : showError
            }
        </Dimmer >);
    },
    All: (props) => {
        let match = useRouteMatch();
        const [store, setStore] = useStore();
        const [allStudents, setStudents] = useState([]);
        const [formNew, setFormNew] = useState('');
        const [search, setSearch] = useState({ busy: false, show: false });
        const handleForm = (event) => {
            setFormNew(event.target.value);
        }
        const defaultThumb = 'https://blog.za3k.com/wp-content/uploads/2015/03/default_profile_6.png';

        const searchStudent = () => {
            if (search.busy) {
                return;
            }
            setSearch({ busy: true, show: false });
            axios.get(settings.server + '/students/search?limit=1&filter=sid&q=' + formNew)
                .then(res => {
                    setSearch({ busy: false, show: true });
                    if (res.data.success) {
                        setSearch({ busy: false, show: true, success: true, ...res.data.data[0] });
                    }
                }, err => {
                    setSearch({ busy: false, show: true });
                });
        }

        const addStudent = () => {
            axios.post(settings.server + '/batch/students/add', { batch: store.batch.id, student: search.id })
                .then(res => {
                    if (res.data.success) {
                        const list = [{ id: search.id, sid: search.sid, firstname: search.firstname, lastname: search.lastname }];
                        setStudents(list.concat(allStudents));
                        setStore({ ...store, batch: { ...store.batch, students_count: store.batch.students_count + 1, students: list.concat(store.batch.students) } });
                        setSearch({ busy: false, show: false });
                    }
                });

        }

        useEffect(() => {
            axios.get(settings.server + '/batch/students/all?batch=' + store.batch.id)
                .then(res => {
                    if (res.data.success) {
                        setStudents(res.data.data);
                    }
                });
        }, allStudents);

        return (<Grid.Row card>
            <Grid.Col md={8}>
                <Card title="All Students">
                    <Card.Body style={{ padding: 0 }}>
                        {
                            allStudents.length === 0 ? (
                                <div className="placeholder-img"><img src={imgEmpty} alt='No results found' />
                                    <Header.H4 className="label">Sorry, This space is empty.</Header.H4>
                                </div>) : ''
                        }
                        {
                            allStudents.length > 0 ? (
                                <Table>
                                    <Table.Header>
                                        <Table.ColHeader></Table.ColHeader>
                                        <Table.ColHeader>Full Name</Table.ColHeader>
                                        <Table.ColHeader></Table.ColHeader>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            allStudents.map((item, key) => (
                                                <Table.Row key={key}>
                                                    <Table.Col>{key + 1}</Table.Col>
                                                    <Table.Col>{item.firstname + ' ' + item.lastname}</Table.Col>
                                                    <Table.Col>
                                                        <div className="view-student"><Link to={`${match.url}/${item.id}`}><Button color="secondary">Edit</Button></Link></div>
                                                    </Table.Col>
                                                </Table.Row>))
                                        }
                                    </Table.Body>
                                </Table>) : ''
                        }
                    </Card.Body>
                </Card>
            </Grid.Col>
            <Grid.Col md={4}>
                <Card>
                    <Card.Header>
                        <Card.Title><Icon name="plus" /> Add New Student</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Group label="Search student by ID">
                            <Grid.Row gutters="xs">
                                <Grid.Col>
                                    <Form.Input placeholder="Search for..." value={formNew} onChange={handleForm.bind(this)} />

                                </Grid.Col>
                                <Grid.Col auto>
                                    <Button
                                        color="primary"
                                        icon="search" onClick={searchStudent}
                                    />
                                </Grid.Col>
                            </Grid.Row>
                        </Form.Group>
                        {
                            !search.busy && search.success ? (
                                <div className="basic-details">
                                    <img className="thumb" src={search.thumb ? search.thumb : defaultThumb} alt="profile" />
                                    <Header.H2>{search.firstname + ' ' + search.lastname}</Header.H2>
                                    <Header.H3>Age: 18</Header.H3>
                                    <Button color="success" onClick={addStudent}>Add Student</Button>
                                </div>
                            ) : ''
                        }
                        {
                            search.show && !search.success ? (
                                <div className="placeholder-img" style={{ minHeight: 'auto' }}><img src={imgSearch} alt='No results found' />
                                    <Header.H4 className="label">No results found.</Header.H4>
                                </div>) : ''
                        }
                    </Card.Body>
                </Card>
            </Grid.Col>
        </Grid.Row>)
    }
}

export default Student;