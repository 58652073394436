import React, { useState, useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  HashRouter,
  // useLocation
} from "react-router-dom";
import axios from 'axios';
import settings from './app.config';
import { useStore } from './utils/Store';

// icons
import UserIMG from './assets/icons/user_default.svg';
import brandLogo from './assets/brand/logo.png';

import "tabler-react/dist/Tabler.css";
import { Page, Nav, Header, Card, Grid, Form, Button, Icon, Dropdown, Badge } from "tabler-react";

import Dashboard from './dashboard/DashboardIndex';
import Students from './students/Students';
import Master from './master/Master';
import Batch from './class/Class';
import Tutors from './tutors/Tutors';
import FeedbackForm from './feedback/Index';

const Account = {
  Login: () => {
    let history = useHistory();
    const [store, updateStore] = useStore();
    const [isBusy, setBusy] = useState(false);
    const [form, setForm] = useState({ email: '', password: '' });

    useEffect(() => {
      const token = localStorage.getItem('user_token');

      if (token) {
        history.replace({ pathname: "/home" });
      }
    }, []);

    function formInput(event) {
      switch (event.target.name) {
        case 'email': setForm({ ...form, email: event.target.value }); break;
        case 'password': setForm({ ...form, password: event.target.value }); break;
      }
    }

    let doLogin = () => {
      setBusy(true);
      const params = {
        email: form.email,
        password: form.password
      };

      axios.post(settings.server + '/user/login', { data: params })
        .then(res => {
          if (res.data.success) {
            localStorage.setItem('user_token', res.data.data.id);
            updateStore({ user: res.data.data });

            setTimeout(() => history.replace({ pathname: "/home" }));

          }

          setBusy(false);
        }, err => {
          setBusy(false);
        });
    };

    return (
      <div className="login-panel">
        <div className="logo_main" style={{ marginBottom: '40px' }}><img src={brandLogo} /></div>
        <Card style={{ width: '400px' }}>
          <Card.Body>
            <Header.H3 style={{ paddingBottom: '20px', textAlign: 'center' }}>Login to your Account</Header.H3>
            <form method="post" onSubmit={doLogin}>
              <Grid.Row>
                <Grid.Col width="12">
                  <Form.Group label="Email Address" isRequired>
                    <Form.Input name="email" type="email" value={form.email} onChange={formInput.bind(this)} />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width="12">
                  <Form.Group label="Password" isRequired>
                    <Form.Input name="password" type="password" value={form.password} onChange={formInput.bind(this)} />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col className="btn-brand" >
                  <Button color="primary" disabled={isBusy} style={{ 'width': "100%" }} onClick={doLogin}>Login</Button>
                </Grid.Col>
              </Grid.Row>
            </form>
          </Card.Body>
        </Card>
      </div >

    )
  },
  Home: () => {
    let history = useHistory();
    const [store, updateStore] = useStore();
    const [ready, setReady] = useState(false);

    useEffect(() => {
      const token = localStorage.getItem('user_token');

      if (!token || token == '') {
        history.replace({ pathname: "/" });
        return;
      }
      axios.get(settings.server + '/user/data/' + token)
        .then(res => {
          if (res.data.success) {
            const response = res.data;
            let data = { ...store, user: response.profile };

            if (response.class) {
              data = { ...data, class: response.class };
            }
            if (response.sections) {
              data = { ...data, sections: response.sections };
            }
            if (response.teachers) {
              data = { ...data, teachers: response.teachers };
            }
            if (response.batch_list) {
              data = { ...data, batches: response.batch_list };
            }

            if (response.batch_governs) {
              data = { ...data, batches: data.batches.concat(response.batch_governs) };
            }

            if (response.subject_list) {
              data = { ...data, subjects: response.subject_list };
            }

            if (response.rights) {
              data = { ...data, rights: response.rights };
            }

            if (response.periods) {
              data = { ...data, periods: response.periods };
            }

            if (response.amenities) {
              data = { ...data, amenities: response.amenities };
            }

            data = { ...data, review_feedback_count: response.review_feedbacks };
            updateStore(data);
            setReady(true);
          } else {
            history.replace({ pathname: "/logout" });
          }
        });
    }, []);

    function gotoHome() {
      history.replace({ pathname: "/home" });
    }

    function userMenuNav(route) {
      history.replace({ pathname: "/" + route });
    }

    const homePage = (

      <div className="App">
        <div className="site-header">
          <Page.Content>
            <Page.Header>
              <div className="brand-panel">
                <div className="logo" onClick={gotoHome}><img src={brandLogo} /></div>
                <div className="user-menu">

                  <Dropdown>
                    <Dropdown.Trigger>
                      <Icon name="user" />  {store.user.name}
                    </Dropdown.Trigger>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => userMenuNav('logout')}>logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Page.Header>
          </Page.Content>
        </div>
        {/* site-header ends */}
        <div className="nav-header">
          <Page.Content>
            <Page.Header>
              <div className="header-panel">
                <Account.Navigation />
              </div>
            </Page.Header>
          </Page.Content>
        </div>
        <Page>
          <Page.Content>
            <Page.Main>
              <Switch>
                {
                  store.rights && store.rights.students ?
                    <PrivateRoute path="/home/students">
                      <Students.Home />
                    </PrivateRoute> : ''
                }
                {
                  store.rights && store.rights.tutor_create ? <PrivateRoute path="/home/tutors">
                    <Tutors.Home />
                  </PrivateRoute> : ''
                }
                <PrivateRoute path="/home/feedback">
                  <FeedbackForm.Home />
                </PrivateRoute>
                <PrivateRoute path="/home/class">
                  <Batch.Home />
                </PrivateRoute>
                {
                  store.rights && store.rights.master ?
                    <PrivateRoute path="/home/master">
                      <Master.Home />
                    </PrivateRoute> : ''
                }
                <PrivateRoute path="/home">
                  <Dashboard.Home />
                </PrivateRoute>
              </Switch>
            </Page.Main>
          </Page.Content>
        </Page>

      </div>
    );

    const loading = <div>Loading</div>;

    return ready ? homePage : loading;
  },
  Logout: () => {
    let history = useHistory();
    const [store, updateStore] = useStore();

    useEffect(() => {
      updateStore({});
      localStorage.setItem('user_token', '');
      history.replace({ pathname: "/" });
    }, []);

    return '...';
  },
  Navigation: () => {
    const [store] = useStore();

    const list = [
      {
        id: 'home',
        name: 'Dashboard',
        show: true,
        path: '/home',
        icon: 'home'
      },
      {
        id: 'master',
        name: 'Master',
        show: store.rights && store.rights.master,
        path: '/home/master',
        icon: 'globe'
      },
      {
        id: 'class',
        name: 'Class',
        show: true,
        path: '/home/class',
        icon: 'grid'
      },
      {
        id: 'tutors',
        name: 'Teachers',
        show: store.rights && store.rights.tutor_create,
        path: '/home/tutors',
        icon: 'users'
      },
      {
        id: 'students',
        name: 'Students',
        show: store.rights && store.rights.students && false,
        path: '/home/students',
        icon: 'user'
      },
      {
        id: 'exams',
        name: 'Exams',
        show: false,
        path: '/home/exams',
        icon: 'calendar'
      },
      {
        id: 'feedback',
        name: 'Feedback',
        show: true,
        path: '/home/feedback',
        icon: 'check',
        notification: store.review_feedback_count ? <Badge color='warning'>{store.review_feedback_count}</Badge> : ''
      },
    ];

    return (
      <Nav className="nav-items">
        {
          list.map(item =>
            item.show ? <Nav.Item className="item" key={item.id} icon={item.icon}>
              <Link to={item.path} key={item.id}>{item.name} {item.notification}</Link>
            </Nav.Item> : ''
          )
        }
      </Nav >
    );
  }
}

function App() {

  return (<HashRouter>
    <Switch>
      <PrivateRoute path="/home">
        <Account.Home />
      </PrivateRoute>
      <Route path="/logout" exact={true}>
        <Account.Logout />
      </Route>
      <Route path="/" exact={true}>
        <Account.Login />
      </Route>
    </Switch>
  </HashRouter>)
}


function PrivateRoute({ children, ...rest }) {
  const [store] = useStore();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        store.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default App;
