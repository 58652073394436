import React, { useEffect, useState } from 'react';
import { Page, Card, Table, Icon, Button, Badge, Dropdown } from "tabler-react";
import Axios from 'axios';
import { useStore } from '../utils/Store';
import settings from '../app.config';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";


const Dashboard = {
    Home: function () {
        const [store, updateStore] = useStore();
        const [overview, setData] = useState({ scheduler: [], version: {}, slots: [] });

        useEffect(() => {
            Axios.get(settings.server + '/dashboard/' + store.user.id)
                .then(res => {
                    setData({ scheduler: res.data.scheduler, version: res.data.version, slots: res.data.slots });
                });
        }, []);
        const layout = (
            <div>
                { store.user.user_type == 'TUTOR' ? <Dashboard.SchedulerTutor dayPlanner={overview.scheduler} version={overview.version} slots={overview.slots} /> : <Dashboard.SchedulerAdmin dayPlanner={overview.scheduler} version={overview.version} />
                }
            </div>
        );

        return layout;
    },
    SchedulerAdmin: function (props) {
        const [store] = useStore();
        const [activePanel, setPanel] = useState([0]);
        const [openPopup, setPopup] = useState();
        const [dayPlanner, setData] = useState([]);
        const [activeDay, setActiveDay] = useState(0);
        const daysName = ['Today', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

        useEffect(() => {
            if (props && props.dayPlanner) {
                setData(props.dayPlanner);
            }
        }, [props]);

        function panelState(pos) {
            if (activePanel.includes(pos)) {
                setPanel(activePanel.filter(i => i != pos));
            } else {
                setPanel([...activePanel, pos]);
            }
        }

        function closePopup() {
            setPopup(null);
        }

        function showPopup(id) {
            setPopup(id);
        }

        function setDay(day) {
            setData([]);
            setActiveDay(day);
            Axios.get(settings.server + '/dashboard/' + store.user.id + '?day=' + day)
                .then(res => {
                    setData(res.data.scheduler);
                });
        }


        function downloadFile(type, allClassList) {
            const fileName = allClassList.name;

            if (type === 'pdf') {
                html2canvas(document.querySelector("#element-to-export-" + allClassList)).then(canvas => {
                    const doc = new jsPDF();

                    doc.text(fileName, 10, 10);

                    console.log(canvas.height)
                    doc.addImage(canvas, 'JPEG', 10, 20, 190, 210)
                    doc.save(fileName + ".pdf");
                });

                return;
            }

            const cellsName = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

            const XlsxPopulate = require('xlsx-populate');
            XlsxPopulate.fromBlankAsync()
                .then(workbook => {

                    const sheetName = 'Sheet1';

                    workbook.sheet(sheetName).row(1).height(20);
                    workbook.sheet(sheetName).cell('A1').value(allClassList.name).style("bold", true);

                    allClassList.slots.map((slot, j) => {
                        let col;
                        if (Math.floor((j + 1) / cellsName.length) > 0) {
                            col = cellsName[Math.floor((j / (cellsName.length - 1))) - 1] + cellsName[j % (cellsName.length - 1)];
                        } else {
                            col = cellsName[(j + 1)];
                        }
                        workbook.sheet(sheetName).cell(col + '1').value(slot).style("bold", true);
                    });

                    allClassList.batches.map((item, i) => {
                        let thisRow = (i * 2) + 2;

                        const batchnameLabel = item.batch && item.batch.division ? item.batch.division : '';
                        workbook.sheet(sheetName).cell('A' + thisRow).value(batchnameLabel).style("bold", true);
                        workbook.sheet(sheetName).row(thisRow + 1).height(40);

                        item.scheduler.map((subject, j) => {
                            let col;
                            if (Math.floor((j + 1) / cellsName.length) > 0) {
                                col = cellsName[Math.floor((j / (cellsName.length - 1))) - 1] + cellsName[j % (cellsName.length - 1)];
                            } else {
                                col = cellsName[(j + 1)];
                            }

                            let thisCell = col + thisRow;

                            if (item.scheduler[j] && item.scheduler[j].short_name) {
                                workbook.sheet(sheetName).cell(thisCell).value(subject.short_name).style("fill", 'bfbfbf');
                                workbook.sheet(sheetName).cell(col + (thisRow + 1)).value(item.scheduler[j].tutor);
                            } else {
                                if (item.scheduler[j] && item.scheduler[j].id) {
                                    workbook.sheet(sheetName).cell(thisCell).value(subject.id);
                                } else {
                                    workbook.sheet(sheetName).cell(thisCell).value(' ');
                                }
                            }
                        });
                    });


                    workbook.outputAsync()
                        .then(function (blob) {
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                // If IE, you must uses a different method.
                                window.navigator.msSaveOrOpenBlob(blob, fileName + ".xlsx");
                            } else {
                                var url = window.URL.createObjectURL(blob);
                                var a = document.createElement("a");
                                document.body.appendChild(a);
                                a.href = url;
                                a.download = fileName + ".xlsx";
                                a.click();
                                window.URL.revokeObjectURL(url);
                                document.body.removeChild(a);
                            }
                        });
                });
        }

        const dayList = dayPlanner.map((allClassList, i) =>
            allClassList.batches.length ? <div key={i}>
                <div className="accordion-head" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span onClick={() => panelState(i)}>
                        {allClassList.name}
                 ({allClassList.batches.length})
                </span>
                    {allClassList && allClassList.batches.length ? <Dropdown triggerContent="Export">
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => downloadFile('excel', allClassList)}>Excel</Dropdown.Item>
                            {/* <Dropdown.Item onClick={() => downloadFile('pdf', i)}>PDF</Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown> : null}
                </div>
                <div id={'element-to-export-' + i} className={'accordion-body ' + (activePanel.includes(i) ? 'active' : '')}>
                    {
                        allClassList.batches.length ? <div className="ov-sc-row">
                            <div className="ov-sc-class">&nbsp;</div>
                            <div className="ov-sc-slot-wrap">
                                {
                                    allClassList.slots.map((slot, j) => <div className="ov-sc-slot-head" key={j}>{slot}</div>)
                                }
                            </div>
                        </div> : <div className="mb-4 pl-4">No data found</div>
                    }
                    {
                        allClassList.batches.map((item, key) =>
                            <div className="ov-sc-row-wrap" key={key}>
                                <div className="ov-sc-row">
                                    <div className="ov-sc-class"></div>
                                    {
                                        item.slots && item.slots.length ? <div className="ov-sc-slot-wrap">
                                            {
                                                item.slots.map((slot, j) => <div className="ov-sc-slot-head" key={j}>{slot}</div>)
                                            }
                                        </div>
                                            : null
                                    }
                                </div>
                                <div className="ov-sc-row" key={key}>
                                    <div className="ov-sc-class">
                                        <b>{item.batch && item.batch.division ? item.batch.division : ''}</b>
                                     ({daysName[item.day]})
                                    <br />
                                        {item.batch ? <a className="color" onClick={() => showPopup(item.batch)}>View all</a> : ''}
                                    </div>
                                    {/* <div> */}

                                    <div className="ov-sc-slot-wrap">
                                        {item.scheduler ?
                                            item.scheduler.map((slot, j) => item.scheduler[j] && item.scheduler[j].short_name ? <div key={j} className="ov-sc-slot" style={{ backgroundColor: item.scheduler[j].color }}>
                                                <div className="title">{item.scheduler[j].short_name}</div>
                                                <div className="label" title={item.scheduler[j].tutor}>{item.scheduler[j].tutor}</div>
                                            </div> : item.scheduler[j] && item.scheduler[j].id ? <div className="ov-sc-slot" key={j} >{item.scheduler[j].id}</div> : <div className="ov-sc-slot" key={j} ></div>) : null

                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div> : null
        );

        const view = (<Card>
            <Card.Body>
                {
                    dayPlanner.length > 0 ? <div className={'dashboard-wrapper common-dashboard-wrapper'}>
                        {dayList}
                    </div> : ''
                }
                {
                    !dayPlanner.length ? 'Loading...' : ''
                }
            </Card.Body >
        </Card >);

        return <>
            <Page.Title>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <Icon name="calendar" /> Schedule  <Badge color="success" className="ml-3">{props.version.name}</Badge>
                    </div>
                    <Dropdown triggerContent={daysName[activeDay]}>
                        <Dropdown.Menu>
                            {daysName.map((day, i) => <Dropdown.Item key={i} onClick={() => setDay(i)}>{day}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Page.Title>
            {view}
            {openPopup ? <Dashboard.DetailedScheduler batch={openPopup} close={closePopup} /> : ''}
        </>;
    },
    SchedulerTutor: (props) => {
        const [store] = useStore();
        const [openPopup, setPopup] = useState();
        const daysName = ['Today', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        function closePopup() {
            setPopup(null);
        }

        function showPopup(id) {
            setPopup(id);
        }
        function downloadFile(type, allClassList) {

            const fileName = store.user.name + ' - ' + allClassList.name;

            if (type === 'pdf') {
                // html2canvas(document.querySelector("#element-to-export-" + allClassList)).then(canvas => {
                //     const doc = new jsPDF();

                //     doc.text(fileName, 10, 10);

                //     console.log(canvas.height)
                //     doc.addImage(canvas, 'JPEG', 10, 20, 190, 210)
                //     doc.save(fileName + ".pdf");
                // });

                return;
            }

            const cellsName = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

            const XlsxPopulate = require('xlsx-populate');
            XlsxPopulate.fromBlankAsync()
                .then(workbook => {

                    const sheetName = 'Sheet1';

                    workbook.sheet(sheetName).row(1).height(20);
                    workbook.sheet(sheetName).cell('A1').value(allClassList.name).style("bold", true);

                    props.slots.map((slot, j) => {
                        let col = 'A' + ((j * 2) + 2);
                        workbook.sheet(sheetName).cell(col).value(slot).style("bold", true);
                    });

                    allClassList.batches.map((item, i) => {
                        let thisRow = (i * 2) + 2;
                        workbook.sheet(sheetName).column(cellsName[i]).width(25);

                        const batchnameLabel = daysName[item.day] || '';
                        workbook.sheet(sheetName).cell(cellsName[i + 1] + '1').value(batchnameLabel).style("bold", true);


                        item.scheduler.map((subject, j) => {
                            thisRow = (j * 2) + 2;
                            workbook.sheet(sheetName).row(thisRow + 1).height(40);

                            let col;
                            if (Math.floor((i + 1) / cellsName.length) > 0) {
                                col = cellsName[Math.floor((i / (cellsName.length - 1))) - 1] + cellsName[i % (cellsName.length - 1)];
                            } else {
                                col = cellsName[i + 1];
                            }

                            let thisCell = col + thisRow;

                            if (item.scheduler[j] && item.scheduler[j].short_name) {
                                workbook.sheet(sheetName).cell(thisCell).value(subject.short_name).style("fill", 'bfbfbf');
                                workbook.sheet(sheetName).cell(col + (thisRow + 1)).value(item.scheduler[j].tutor);
                            } else {
                                if (item.scheduler[j] && item.scheduler[j].id) {
                                    workbook.sheet(sheetName).cell(thisCell).value(subject.id);
                                } else {
                                    workbook.sheet(sheetName).cell(thisCell).value(' ');
                                }
                            }
                        });
                    });


                    workbook.outputAsync()
                        .then(function (blob) {
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                // If IE, you must uses a different method.
                                window.navigator.msSaveOrOpenBlob(blob, fileName + ".xlsx");
                            } else {
                                var url = window.URL.createObjectURL(blob);
                                var a = document.createElement("a");
                                document.body.appendChild(a);
                                a.href = url;
                                a.download = fileName + ".xlsx";
                                a.click();
                                window.URL.revokeObjectURL(url);
                                document.body.removeChild(a);
                            }
                        });
                });
        }

        const dayList = props.dayPlanner.map((allClassList, i) =>
            <div className="ov-sc-tutor-col" key={i}>
                <div className="ov-sc-col-name">{allClassList.name}</div>
                <div id={'element-to-export-' + i} className="dashboard-wrapper-tutor">
                    {
                        allClassList.batches.map((item, key) =>
                            <div className="ov-sc-tutor-row" key={key}>
                                <div className="ov-sc-class">
                                    <b>{item.batch && item.batch.division ? item.batch.division : ''}</b>
                                    {daysName[item.day]}
                                    <br />
                                    {item.batch ? <a className="color" onClick={() => showPopup(item.batch)}>View all</a> : ''}
                                </div>
                                {/* <div> */}

                                <div className="ov-sc-slot-wrap">
                                    {item.scheduler ?
                                        item.scheduler.map((slot, j) => item.scheduler[j] && item.scheduler[j].short_name ? <div key={j} className="ov-sc-slot" style={{ backgroundColor: item.scheduler[j].color }}>
                                            <div className="title">{item.scheduler[j].short_name}</div>
                                            <div className="label" title={item.scheduler[j].tutor}>{item.scheduler[j].tutor}</div>
                                        </div> : item.scheduler[j] && item.scheduler[j].id ? <div className="ov-sc-slot" key={j}>{item.scheduler[j].id}</div> : <div className="ov-sc-slot" key={j}></div>) : null

                                    }
                                </div>
                            </div>

                        )
                    }
                </div>
            </div>
        );

        const view = (<Card>
            <Card.Body>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {props.dayPlanner && props.dayPlanner.length ? <Dropdown triggerContent="Export">
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => downloadFile('excel', props.dayPlanner[1])}>Excel</Dropdown.Item>
                            {/* <Dropdown.Item onClick={() => downloadFile('pdf', i)}>PDF</Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown> : null}
                </div>
                {
                    props && props.dayPlanner.length > 0 ? <div className={'common-dashboard-wrapper dashboard-wrapper-tutor '}>
                        <div className="ov-sc-tutor-col">
                            <div className="ov-sc-tutor-row">
                                <div className="ov-sc-col-name"></div>
                                <div className="ov-sc-class">Time</div>
                                <div className="ov-sc-slot-wrap">
                                    {
                                        props.slots ? props.slots.map(time => <div className="ov-sc-slot" key={time}>{time}</div>) : null
                                    }
                                </div>
                            </div>
                        </div>
                        {dayList}
                    </div> : ''
                }
                {
                    props && !props.dayPlanner.length ? 'Sorry, No data found.' : ''
                }
            </Card.Body >
        </Card >);

        return <>
            <Page.Title>
                <Icon name="calendar" /> Schedule  <Badge color="success" className="ml-3">{props.version.name}</Badge>
            </Page.Title>
            {view}
            {openPopup ? <Dashboard.DetailedScheduler batch={openPopup} close={closePopup} /> : ''}
        </>;
    },
    DetailedScheduler: ({ batch, options, close }) => {
        const [store] = useStore();
        const [scheduler, setScheduler] = useState([]);
        const [slotsList, setSlots] = useState([]);

        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        useEffect(() => {
            Axios.get(settings.server + '/batch/' + batch.id + '/scheduler')
                .then(res => {
                    if (res.data.success) {
                        setScheduler(res.data.data.scheduler);
                        setSlots(res.data.data.slots);
                    }
                });
        }, []);

        return <Popup open={batch.id} onClose={close}>
            <div className="modal" style={{ width: '1000px', minWidth: '1000px' }}>
                <a className="close-modal" onClick={close}>&times;</a>
                <Card>
                    <Card.Header>
                        <Icon name="calendar" /> <b><span className="card-label">{batch.class_name} - {batch.division} : Weekly Schedule</span></b>
                    </Card.Header>
                    <Card.Body>
                        <div className="schedular-table dashboard">
                            <div className="day-col">
                                <div className="day-header">&nbsp;</div>
                                {
                                    slotsList.map((day, i) => <div className="col-header sub-col my-1" key={i}>{day.time}</div>)
                                }
                            </div>
                            {scheduler.map((day, i) => (<div className="day-col ml-3">
                                <div className="day-header"><b>{days[i]}</b></div>
                                {day.map((subject, j) =>
                                    subject && subject.name ?
                                        <div className="sub-col subject-pill m-1" style={{ backgroundColor: subject.color }}>
                                            {
                                                subject && subject.id ? <label >{subject.short_name}</label> : <Button pill color="warning" icon="alert-triangle" />
                                            }
                                            <div className="label" title={subject.tutor}>{subject.tutor}</div>
                                        </div> : <div className="sub-col sub-col-interval" key={j}>{subject.id}</div>
                                )}
                            </div>))}
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <Link to={`/home/class/view/${batch.id}/schedule`}>
                            <Button color="primary">Manage</Button>
                        </Link>
                    </Card.Footer>
                </Card>
            </div>
        </Popup>
    }
}

export default Dashboard;