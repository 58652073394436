import React, { useState, useEffect } from 'react';
import { Page, Button, Icon, Table, Form, Grid, Dropdown, Header, Card, Dimmer } from 'tabler-react';
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
import axios from 'axios';
import './Students.css';

import CreateStudent from './New';
import StudentProfile from './Profile';
import settings from '../app.config';
import imgStudents from '../assets/placeholders/student_profile.svg';

function loadStudent(cb) {
    axios.get(settings.server + '/students/all', { page: 0 })
        .then(res => {
            if (res.data.success) {
                setTimeout(cb(res.data.data));
            }
        }, err => {

        });
}

const Students = {
    Home: () => {
        let match = useRouteMatch();
        return (
            <div>
                <div className="title-bar">
                    <Page.Title>
                        <Icon name="users" /> Manage Students</Page.Title>
                    <div className="filter-results">
                        <div className="search">
                            <Form.Group>
                                <Grid.Row gutters="xs">
                                    <Grid.Col>
                                        <Form.InputGroup>
                                            <Form.InputGroup prepend>
                                                <Button
                                                    color="secondary"
                                                    isDropdownToggle
                                                >Filter By Class</Button>
                                                <Dropdown.Menu position="right">
                                                    <Dropdown.Item>Class A</Dropdown.Item>
                                                    <Dropdown.Item>Class A</Dropdown.Item>
                                                    <Dropdown.Item>Class A</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Form.InputGroup>
                                            <Form.Input placeholder="Search student" />
                                        </Form.InputGroup>
                                    </Grid.Col>
                                    <Grid.Col auto>
                                        <Button
                                            color="secondary"
                                            icon="search"
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            </Form.Group>
                        </div>
                        <Button.List align="right">
                            <Link to="/students/new">
                                <Button color="primary">
                                    <Icon name="plus" /> Add Student
                            </Button>
                            </Link>
                        </Button.List>

                    </div>
                </div>
                <div>
                    <Switch>
                        <Route path={`${match.path}/edit/:studentID`}>
                            <StudentProfile />
                        </Route>
                        <Route path={`${match.path}/new`}>
                            <CreateStudent />
                        </Route>
                        <Route path={match.path}>
                            <Students.List />
                        </Route>
                    </Switch>
                </div>
            </div>

        )
    },
    List: () => {
        let match = useRouteMatch();
        const [students, setStudents] = useState({ busy: true, data: [] });

        useEffect(() => {
            loadStudent((data) => {
                setStudents({ busy: false, data: data });
            });
        }, [students.data]);


        const listItems = students.data.map((student) =>
            <Table.Row>
                <Table.Col>1</Table.Col>
                <Table.Col>{student.firstname + ' ' + student.lastname}</Table.Col>
                <Table.Col></Table.Col>
                <Table.Col></Table.Col>
                <Table.Col>
                    <div className="view-student"><Link to={`${match.url}/edit/${student.id}`}><Button color="secondary">Manage</Button></Link></div>
                </Table.Col>
            </Table.Row>
        );

        return (
            <Dimmer active={students.busy} loader>
                <Grid.Row cards deck>
                    <Grid.Col md={8}>
                        <div className="students-list" style={{ width: '100%' }}>
                            <Table>
                                {
                                    listItems.length > 0 ? (<Table.Header>
                                        <Table.ColHeader>ID</Table.ColHeader>
                                        <Table.ColHeader>Name</Table.ColHeader>
                                        <Table.ColHeader>Current Class</Table.ColHeader>
                                        <Table.ColHeader>Address</Table.ColHeader>
                                        <Table.ColHeader></Table.ColHeader>
                                    </Table.Header>) : ''
                                }
                                <Table.Body>
                                    {listItems.length > 0 ? listItems : (
                                        <div className="placeholder-img"><img src={imgStudents} alt='no students added yet' />
                                            <Header.H4 className="label">Create or Import students.</Header.H4>
                                            <Link to='/students/new'>
                                                <Button color="primary"><Icon name="plus" /> Add Students</Button>
                                            </Link>
                                        </div>)}
                                </Table.Body>
                            </Table >
                        </div >
                    </Grid.Col>
                    <Grid.Col md={4}>
                        <Students.Import />
                    </Grid.Col>
                </Grid.Row >
            </Dimmer>
        );
    },
    Import: () => {
        return (<Card>
            <Card.Header>
                <Card.Title>
                    Import from File
            </Card.Title>
            </Card.Header>
            <Card.Body>

            </Card.Body>
        </Card>);
    }
}

export default Students;