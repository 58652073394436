









const benchMark = ['Frequently exceeds job requirements. Makes contributions well beyond job demands. Seizes initiative in development and implementation of challenging work goals. Each project or job is done thoroughly and on time. Thinks beyond details of the job, working toward the overall goals of the component.', 'Performance is what is expected of a fully qualified and experienced person in this position. All objectives and standards are met. Consistently meets all performance expectations and standards. Errors are minimal and seldom repeated. Prioritize problems and projects well. Almost always complete work or projects on schedule.', 'Objectives and standards are generally met but full results are not totally achieved. Occasionally falls short of consistently meeting performance expectations and standards. Some performance aspects were not met – needs slight improvement. Could result from being newly appointed to the position – continuing to learn all aspects of the position.', 'Objectives and standards are consistently not met. Consistently fails to meet performance expectations. Needs significant improvement. Has been on the job long enough to have shown better performance. Excessive attention by supervisor is required. Does not grasp situation when explained.  Corrective action is required. '];


export const NonExecutiveFeedBackQuestions = [
    {
        title: 'Works to Full Potential',
        description: 'Demonstrates expertise in the functional aspects of the job.',
        ratingBenchmark: benchMark
    },
    {
        title: 'Quality of Work',
        description: 'Reflects the accuracy, volume and timely manner in which work is performed.',
        ratingBenchmark: benchMark
    },
    {
        title: 'Communication',
        description: 'Clearly expresses ideas.Readily shares appropriate work- related information.',
        ratingBenchmark: benchMark
    },
    {
        title: ' Independent Work',
        description: 'Consistently adheres to set work schedule.',
        ratingBenchmark: benchMark
    },
    {
        title: ' Takes Initiative',
        description: 'Reviews facts and data, using sound judgement, to arrive at the most effective solution.',
        ratingBenchmark: benchMark
    },
    {
        title: 'Teamwork',
        description: 'Works collaboratively with fellow employees and others to achieve identified goals and objectives.',
        ratingBenchmark: benchMark
    },
    {
        title: 'Productivity',
        description: 'Ability to determine priorities and maximize efficiency.',
        ratingBenchmark: benchMark
    },
    {
        title: ' Integrity',
        description: 'Upholds the values of being honest and strong moral principles.',
        ratingBenchmark: benchMark
    },
    {
        title: 'Technical Skills',
        description: 'Proficient use of work- related equipment, tools and technology.',
        ratingBenchmark: benchMark
    },
    {
        title: 'Client Relations',
        description: 'Establishes and maintain good working relationships with both external and internal customers by understanding and responding promptly and courteously to customer needs and expectations.',
        ratingBenchmark: benchMark
    },
    {
        title: 'Punctuality',
        description: 'Arrives to work in time and submit all works before dateline.',
        ratingBenchmark: benchMark
    },
    {
        title: 'Attendance',
        description: 'Attends work regularly with full commitment.',
        ratingBenchmark: benchMark
    }
]