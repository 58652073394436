import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Card, Button, Icon, Grid, Header, Form, TabbedCard, Tab, Dimmer } from 'tabler-react';
import axios from 'axios';
import settings from '../app.config';
import './Profile.css';

import img404 from '../assets/placeholders/404.svg';

const StudentProfile = () => {

    let { studentID } = useParams();
    const [profile, setProfile] = useState({ busy: true, success: false, data: {} });
    const defaultThumb = 'https://blog.za3k.com/wp-content/uploads/2015/03/default_profile_6.png';

    useEffect(() => {
        axios.get(settings.server + '/student/' + studentID)
            .then(res => {
                if (res.data.success) {
                    setProfile({ busy: false, success: true, ...res.data.data });
                }
            }, err => {
                setProfile({ busy: false, success: false });
            });
    }, profile.busy);

    return (
        <Dimmer active={profile.busy} loader>
            {
                !profile.success && !profile.busy ? (
                    <div className="placeholder-img"><img src={img404} alt='Bummer! 404' />
                        <Header.H4 className="label">Sorry, We can't find what you are looking for.</Header.H4>
                    </div>) : ''
            }
            {
                profile.success ? (
                    <div className="profile-wrapper">
                        <div className="card-list">
                            <Card title="Basic Profile">
                                <Card.Body>
                                    <div className="basic-details">
                                        <img className="thumb" src={profile.thumb ? profile.thumb : defaultThumb} alt="profile" />
                                        <Header.H2>{profile.firstname + ' ' + profile.lastname}</Header.H2>
                                        <Header.H3>Age: 18</Header.H3>
                                    </div>

                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Appeared In</Card.Title>
                                    <Card.Options>
                                        <Button color="primary" size="sm">Manage</Button>
                                    </Card.Options>
                                </Card.Header>
                                <Card.Body>
                                    <div className="class-list">
                                        <div className="item">
                                            <div className="badge"></div> <span>Class IX</span>
                                        </div>
                                        <div className="item">
                                            <div className="badge"></div> <span>Class X</span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="edit-profile card-list">
                            <TabbedCard initialTab="Basic Information">
                                <Tab title="Basic Information">
                                    <Form.FieldSet>
                                        <Grid.Row>
                                            <Grid.Col>
                                                <Form.Group label="Student ID" isRequired>
                                                    <Form.Input name="student-id" value={profile.sid} />
                                                </Form.Group>
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Form.FieldSet>
                                    <Form.FieldSet>
                                        <Grid.Row>
                                            <Grid.Col md={6}>
                                                <Form.Group label="First name" isRequired>
                                                    <Form.Input name="first-name" value={profile.firstname} />
                                                </Form.Group>
                                            </Grid.Col>
                                            <Grid.Col md={6}>
                                                <Form.Group label="Last name" isRequired>
                                                    <Form.Input name="last-name" value={profile.lastname} />
                                                </Form.Group>
                                            </Grid.Col>
                                            <Grid.Col md={3}>
                                                <Form.Group label="Gender" isRequired>
                                                    <Form.Select value={profile.gender}>
                                                        <option>Neutral</option>
                                                        <option>Female</option>
                                                        <option>Male</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Grid.Col>
                                            <Grid.Col md={3}>
                                                <Form.Group label="Date of birth" isRequired>
                                                    <Form.DatePicker name="dob" />
                                                </Form.Group>
                                            </Grid.Col>
                                            <Grid.Col md={6}>
                                                <Form.Group label="Mother's name">
                                                    <Form.Input name="mother-name" />
                                                </Form.Group>
                                            </Grid.Col>
                                            <Grid.Col md={6}>
                                                <Form.Group label="Father's name">
                                                    <Form.Input name="father-name" />
                                                </Form.Group>
                                            </Grid.Col>
                                            <Grid.Col md={6}>
                                                <Form.Group label="Guardian">
                                                    <Form.Input name="mother-name" />
                                                </Form.Group>
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Form.FieldSet>
                                    <div className="edit-profile-footer">
                                        <Button color="success">
                                            <Icon name="save" /> Save Changes
                            </Button>
                                        <Button color="secondary">Cancel</Button>
                                    </div>
                                </Tab>
                                <Tab title="Contact Details">
                                    <Form.FieldSet>

                                        <Grid.Row>
                                            <Grid.Col md={6}>
                                                <Form.Group label="Priamry Contact" isRequired>
                                                    <Form.Input name="dob" />
                                                </Form.Group>
                                            </Grid.Col>
                                            <Grid.Col md={6}>
                                                <Form.Group label="Secondary Contact">
                                                    <Form.Input name="example-text-input" />
                                                </Form.Group>
                                            </Grid.Col>
                                            <Grid.Col md={6}>
                                                <Form.Group label="Address">
                                                    <Form.Textarea></Form.Textarea>
                                                </Form.Group>
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Form.FieldSet>
                                    <div className="edit-profile-footer">
                                        <Button color="success">
                                            <Icon name="save" /> Save Changes
                            </Button>
                                        <Button color="secondary">Cancel</Button>
                                    </div>
                                </Tab>
                            </TabbedCard>
                        </div>
                    </div>
                ) : ''
            }
        </Dimmer>
    );
};

export default StudentProfile;