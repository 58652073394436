import React, { useState, useEffect } from 'react';
import { useStore } from '../utils/Store';
import axios from 'axios';
import { Card, Icon, Button, Form, Grid, List, Header, Alert, Badge } from 'tabler-react';
import './Styles.css';
import settings from '../app.config';
import Axios from 'axios';

const Teachers = {
    Home: () => {
        const [store, updateStore] = useStore();
        useEffect(() => {
            const el = document.getElementsByClassName('master-nav-list');
            for (const element in el) {
                const node = el[element];
                if (node.classList) { node.classList.remove('active'); }
            };

            const actEl = document.getElementById('master-nav-teachers');
            if (actEl) { actEl.classList.add('active'); }
        }, []);

        function deactivateItem(id) {
            Axios.post(settings.server + '/user/update', { id, active: 0 })
                .then(res => {
                    if (res.data.success) {
                        const list = store.teachers.filter(item => item.id != id);
                        updateStore({ ...store, teachers: list });
                    }
                });
        }

        return [
            <Grid.Row gutters="xs">
                <Grid.Col>
                    <Card icon="edit">
                        <Card.Header>
                            <Card.Title><Icon name="edit" /> <span className="card-label">Manage Teachers</span></Card.Title>
                        </Card.Header>
                        <Card.Body style={{ padding: "0" }}>
                            <List.Group>
                                {
                                    store.teachers.map((item, key) =>
                                        <List.GroupItem className="hover-controls" key={key}>
                                            <span>{item.name}</span>
                                            <Badge color="danger" className="controls cursor" onClick={() => deactivateItem(item.id)}>Deactivate</Badge>
                                        </List.GroupItem>
                                    )
                                }
                            </List.Group>
                        </Card.Body>
                    </Card>
                </Grid.Col>
                <Grid.Col className="master-class-create">
                    <Teachers.New />
                    <Teachers.Deactivated />
                </Grid.Col>
            </Grid.Row>
        ];
    },
    New: () => {
        const [store, setStore] = useStore();
        const [form, setForm] = useState({ name: '', email: '', phone: '', addSubject: null, subjects: [], type: 'TUTOR' });
        const [status, setStatus] = useState({ busy: false, type: 'danger', display: false, message: 'Required fields' });

        function formChange(event) {
            const d = { ...form };
            d[event.target.name] = event.target.value;
            setForm(d);
        }

        function addSubject() {
            if (form.addSubject) {
                const n = store.subjects.filter(i => i.id == form.addSubject)[0];
                setForm({ ...form, subjects: [...form.subjects, n], addSubject: '' });
            }
        }

        function removeSubject(pos) {
            form.subjects.splice(pos, 1);
            setForm({ ...form, subjects: [...form.subjects] });
        }

        function createUser() {
            if (status.busy) {
                return;
            }
            setStatus({ ...status, busy: true });
            axios.post(settings.server + '/user/create', { name: form.name, phone: form.phone, email: form.email, type: form.type, subjects: form.subjects })
                .then(res => {
                    if (res.data.success) {
                        store.teachers.push(res.data.data);
                        setStore({ ...store, teachers: store.teachers });
                        setForm({ name: '', email: '', phone: '', subjects: [], type: 'TUTOR' });
                        setStatus({ busy: false, display: true, type: 'success', message: 'New teacher created' });
                    } else {
                        setStatus({ busy: false, display: true, type: 'danger', message: res.data.message });
                    }
                }, err => {
                    setStatus({ busy: false, display: true, type: 'danger', message: 'Network Error' })
                });
        }

        return (
            <Card icon="edit">
                <Card.Header>
                    <Card.Title><Icon name="plus" /> <span className="card-label">Add New User</span></Card.Title>
                </Card.Header>
                <Card.Body>
                    {status.display ? (<Alert type={status.type} icon={status.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                        {status.message}
                    </Alert>) : ''}
                    <Header.H4>Profile</Header.H4>
                    <Grid.Row>
                        <Grid.Col width="12">
                            <Form.Group label="Account Type">
                                <Form.Select name="type" value={form.type} onChange={formChange.bind(this)}>
                                    <option value="TUTOR">Teacher</option>
                                    <option value="ADMIN">Admin</option>
                                    <option value="HOD">HOD</option>
                                </Form.Select>
                            </Form.Group>
                        </Grid.Col>
                        <Grid.Col width="12">
                            <Form.Group label="Full Name" isRequired>
                                <Form.Input name="name" value={form.name} onChange={formChange.bind(this)} />
                            </Form.Group>
                        </Grid.Col>
                        <Grid.Col width="12">
                            <Form.Group label="Phone Number" isRequired>
                                <Form.Input name="phone" value={form.phone} onChange={formChange.bind(this)} />
                            </Form.Group>
                        </Grid.Col>
                        <Grid.Col width="12">
                            <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '1fr auto', gridGap: '20px' }}>
                                <Form.Group label="Associated Subjects">
                                    <Form.Select name="addSubject" value={form.addSubject} onChange={formChange.bind(this)}>
                                        <option value="0">Select</option>
                                        {
                                            store.subjects.map(item => form.subjects.filter(i => i.id == item.id).length == 0 ? <option value={item.id} key={item.id}>{item.name}</option> : '')
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <div style={{ paddingTop: '10px' }}>
                                    <Button color="primary" outline onClick={addSubject}>Add</Button>
                                </div>
                            </div>
                            <Button.List>
                                {
                                    form.subjects.map((i, k) => <Button pill color="primary" key={k} onClick={() => removeSubject(k)}>{i.name}</Button>)
                                }
                            </Button.List>
                        </Grid.Col>
                    </Grid.Row>
                </Card.Body>
                <Card.Footer>
                    <Grid.Row>
                        <Grid.Col width="12">
                            <Header.H4>Account Details</Header.H4>
                        </Grid.Col>
                        <Grid.Col width="12">
                            <Form.Group label="Login Email" isRequired>
                                <Form.Input name="email" type="email" value={form.email} onChange={formChange.bind(this)} />
                            </Form.Group>
                        </Grid.Col>
                        <Grid.Col width="12">
                            <Alert type="warning">Password will be generated automatically and send to the user.</Alert>
                        </Grid.Col>
                    </Grid.Row>
                </Card.Footer>
                <Card.Footer>
                    <div>
                        <Button color="success" icon="save" onClick={createUser}>Create</Button>
                    </div>
                </Card.Footer>
            </Card>
        );
    },
    Deactivated: () => {
        const [store, updateStore] = useStore();
        const [teachers, setList] = useState([]);

        useEffect(() => {
            Axios.get(settings.server + '/user/inactive/all')
                .then(res => {
                    if (res.data && res.data.success) {
                        setList(res.data.data);
                    }
                });
        }, [store.teachers.length]);


        function activateItem(data) {
            Axios.post(settings.server + '/user/update', { id: data.id, active: 1 })
                .then(res => {
                    if (res.data.success) {
                        updateStore({ ...store, teachers: [...store.teachers, data] });

                        const list = teachers.filter(item => item.id != data.id);
                        setList(list);
                    }
                });
        }

        return <Card icon="edit">
            <Card.Header>
                <Card.Title><Icon name="plus" /> <span className="card-label">Deactivated Accounts</span></Card.Title>
            </Card.Header>
            <Card.Body>
                <List.Group>
                    {
                        teachers.map((item, key) =>
                            <List.GroupItem className="hover-controls" key={key}>
                                <span>{item.name}</span>
                                <Badge color="success" className="controls cursor" onClick={() => activateItem(item)}>Re-activate</Badge>
                            </List.GroupItem>
                        )
                    }
                    {
                        teachers.length ? '' : 'No accounts found'
                    }
                </List.Group>

            </Card.Body>
        </Card>;
    }
};

export default Teachers;