import React from 'react';
import Popup from "reactjs-popup";
import { Grid, Button, Icon, Card } from 'tabler-react';

function ConfirmSubmitFeedbackDialog({ show, description, onClose }) {

    return <Popup open={show} onClose={() => onClose(false)}>
        <div className="modal" style={{ width: '500px', minWidth: '500px' }}>
            <span className="close-modal" onClick={() => onClose(false)}>&times;</span>
            <Card>
                <Card.Header>
                    <Icon name="calendar" /> <b><span className="card-label">Save changes?</span></b>
                </Card.Header>
                <Card.Body>
                    <Grid.Row>
                        <Grid.Col>
                            Are you sure to submit the changes?
                        </Grid.Col>
                        {description && <Grid.Col sm={12} md={12}>{description}</Grid.Col>}
                    </Grid.Row>
                </Card.Body>
                <Card.Footer>
                    <div>
                        <Button color="secondary" outline onClick={() => onClose(false)}>Cancel</Button>
                        <Button color="success" icon="save" onClick={() => onClose(true)}>Submit</Button>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    </Popup>;
}

export default ConfirmSubmitFeedbackDialog;