
export const adminRateScale = [
    {
        id: 2,
        label: 'Needs Improvement',
        color: 'orange'
    },
    {
        id: 3,
        label: 'Approaches Expectation',
        color: 'yellow'
    },
    {
        id: 4,
        label: 'Meets Expectation',
        color: 'lime'
    },
    {
        id: 5,
        label: 'Exceeds Expectation',
        color: 'green'
    }
];

export const hodRateScale = [
    {
        id: 1,
        label: 'Unsatisfactory',
        color: 'red'
    },
    {
        id: 2,
        label: 'Consistent',
        color: 'orange'
    },
    {
        id: 3,
        label: 'Competent',
        color: 'yellow'
    },
    {
        id: 4,
        label: 'Efficient',
        color: 'lime'
    },
    {
        id: 5,
        label: 'Exceeding',
        color: 'green'
    }
];



export const teacherRateScale = [
    {
        id: 2,
        label: 'Unsatisfactory',
        color: 'orange'
    },
    {
        id: 3,
        label: 'Developing',
        color: 'yellow'
    },
    {
        id: 4,
        label: 'Competent',
        color: 'lime'
    },
    {
        id: 5,
        label: 'Exceeding',
        color: 'green'
    }
];

export const NonExecutiveRateScale = [
    {
        id: 2,
        label: 'Needs Improvement',
        color: 'orange'
    },
    {
        id: 3,
        label: 'Approaches Expectation',
        color: 'yellow'
    },
    {
        id: 4,
        label: 'Meets Expectation',
        color: 'lime'
    },
    {
        id: 5,
        label: 'Exceeds Expectation',
        color: 'green'
    }
];