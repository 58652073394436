

export const TeacherFeedBackQuestions = [
    {
        title: 'Plans instructional objectives',
        description: 'for lessons conducted – prepare weekly lesson plan with specific Instructional objectives that is aligned with the Scheme of Work; set academic targets for students’ results.',
        ratingBenchmark: ['The teacher does not plan, or plans without adequately using the school’s curriculum, effective strategies, resources and data.', 'The teacher inconsistently uses the school’s curriculum, effective strategies, resources and data in planning to meet the needs of all students.', 'The teacher plans using the school’s curriculum, effective strategies, resources and data to meet the needs of all students.', 'The teacher actively seeks and uses alternative data and resources and consistently differentiates plans to meet the needs of all students.'
        ]
    },
    {
        title: 'Communication skills with students',
        description: 'uses skills to enhance classroom management and to develop social, moral values and physical well-being. Uses a variety of verbal and non- verbal techniques free of sarcasm.Paces learning according to students’ mastery of content.',
        ratingBenchmark: ['The teacher inadequately addresses students’ behaviour, displays a harmful attitude with students and/or ignore safety standards. Is unsuccessful at spotting and preventing discipline problems and they frequently escalate.', 'The teacher is inconsistent in using resources, routines and procedures in providing a respectful, positive, safe, student centred environment.', 'Has low control over student behaviour.Tries to prevent discipline problems but sometimes little thing escalates into a big problem.', 'The teacher uses resources, routines and procedures to provide a respectful, positive, safe, student centred environment that is conducive to learning. Tries hard to control student behaviour.Has a confident, dynamic presence and nips most discipline problems in the bud.', 'The teacher creates a dynamic learning environment that maximizes learning opportunities and minimizes disruptions within an environment in which students’ self - monitor behaviour. Able to control student discipline well.Is alert, poised, dynamic and self - assured and nips virtually all discipline problems in the bud.'
        ]
    },
    {
        title: ' Develops innovative teaching approaches',
        description: ' takes initiative to stay relevant and expand content knowledge. Adopt new approaches where appropriate. Uses a range of techniques and uses questioning to internalise concept.',
        ratingBenchmark: ['The teacher’s instruction inadequately addresses students’ learning needs.', 'The teacher uses some instructional strategies that meet individual learning needs.', 'The teacher effectively engages students in learning by using a variety of instructional strategies to meet individual learning needs.', 'The teacher optimizes students’ opportunity to learn by engaging them in higher order thinking and / or enhanced performance skills.'
        ]
    },
    {
        title: 'Assess students’ learning that can identify their weakness',
        description: 'this includes prepare a Table of Specification (TOS) before setting test / examination questions. Analyses assignment or test results to identify students’ strength and weaknesses.',
        ratingBenchmark: ['The teacher uses an inadequate variety of assessment sources, assesses infrequently.', 'The teacher uses a limited selection of assessment strategies.', 'The teacher systematically gathers, analyses and uses data to measure student progress.', 'The teacher uses a variety of informal and formal assessments based on intended learning outcomes to assess student learning '
        ]
    },
    {
        title: ' Provides timely feedback and remedial actions on students’ learning difficulties',
        description: 'uses knowledge of trends in lessons and makes effort to obtain feedback to ensure effectiveness and relevance. Gives written comments and monitor students’ progress.Provide timely feedback. Plan diagnostic remedial lesson to improve students’ progress.Provide necessary guidance to weaker students.',
        ratingBenchmark: ['The teacher does not give feedback and/or does not report on student progress in a timely manner.', 'The teacher inconsistently links assessment to intended learning outcomes, and / or does not plan / modify instruction.', 'The teacher consistently provides timely feedback and conducts remedial work.', 'The teacher is skilful in giving timely feedback and teaches students how to monitor their own academic progress.'
        ]
    },
    {
        title: 'Supportive of school’s policies, vision, mission and programmes',
        description: 'understand school rules, knows the school operating procedures and the school organisational structure. Good understanding of assigned co- curricular activities(CCA / Units) and their correlation to the school’s vision and mission.Plans appropriate activities or programmes.',
        ratingBenchmark: ['The teacher demonstrates inflexibility, a reluctance and/or disregard towards school policy and programmes.', 'The teacher inconsistently observes the school’s mission; only able to carry out school- wide programmes in classroom and according to the needs level.', 'The teacher regularly maintains a commitment to school’s mission; Design implementation of effective customized school - wide programmes.', 'The teacher always maintains a commitment to school’s mission; Lead the design and implementation of effective customized school - wide guidance programmes.'
        ]
    },
    {
        title: 'Initiates ideas for school improvement.',
        description: 'Participates and contribute regularly in the development and review of school policies, regulations. ',
        ratingBenchmark: ['Contributes (with supervision and guidance) to the implementation of school initiatives and duties which will bring about school effectiveness.', 'The teacher attends professional growth opportunities with occasional application in the classroom.', 'The teacher identifies and acts on potential problems before they escalate.', 'The teacher leads the whole school in initiative and duties which will bring about school effectiveness and development.'
        ]
    },
    {
        title: 'Work effectiveness in teams',
        description: 'builds strong relationships and communicates effectively and persuasively with others. Display a sense of team spirit by collaborating effectively with others and overcoming obstacles in achieving common goals.',
        ratingBenchmark: ['Inconsistently able to work with team members, resolve conflict under guidance and supervision.', 'Builds morale within the team and frequently able to resolve conflict.', 'Builds morale within the team and always able to resolve conflict.', 'Builds good morale within the team and empowers a sense of belonging among team members.'
        ]
    },
    {
        title: 'Encourages parental involvement',
        description: 'has good knowledge of students’ family background and establish open communication with parents to keep them informed of students’ progress and school activities.',
        ratingBenchmark: ['Communicating with parents as and when necessary. Listen to criticisms and suggestions of parents.Reluctant to improve the situations.', 'Communicating with parents when need arise. Has an open mind to criticism and suggestions of parents and sometimes work to improve the situations.', 'Communicating with parents on a regular basis. Open mind to criticism and suggestions of parents and always working towards improving the situation.', 'Work with parents to help students. Genuinely values and accepts inputs and experience.Fostering a two- way communication.'
        ]
    },
    {
        title: 'Personal conduct, self-management and development',
        description: 'Displays excellent personal conduct, upholds high standards of sincerity, integrity, respect and professionalism. Is highly trustworthy and reliable.  Pursues self- development in building own’s capacity to achieve shared goals. Seeking professional development programmes. Motivated to complete multiple duties and projects on time. Readily assume necessary non - instructional responsibilities. Able to perform well with a multitude of tasks within given deadlines',
        ratingBenchmark: ['Is frequently unethical, dishonest, uses poor judgement and/or discloses student information. The teacher usually does not observe professional ethics.Frequently acts and / or dresses in an unprofessional manner and violates boundaries. Has no interest to seek developmental programmes.', ' Sometimes uses questionable judgement, is less than completely honest, and / or discloses student information. The teacher inconsistently observes professional ethics.Occasionally acts and / or dresses in an unprofessional manner and / or violates boundaries. Seeks developmental programmes when prompted.', 'Is ethical and forthright, uses good judgement and maintains confidentiality with student records. The teacher maintains a commitment to professional ethics.Demonstrates professional conduct and maintains appropriate boundaries.Making an effort to seek developmental programmes.', 'Is invariably ethical, honest and forthright, uses impeccable judgement and respects confidentiality. The teacher continually maintains a commitment to professional ethics.Presents as an exemplary professional and always observes appropriate boundaries. Active in seeking developmental programmes.'
        ]
    },
    {
        title: ' Work Attitude and commitment',
        description: 'Does not allow personal and other commitments to affect work. Very responsible and fully committed to work.  Performs duties in a professional manner.Possesses a high degree of work ethics. Exhibits patience in all dealings.Upholds high standards of integrity at all times.',
        ratingBenchmark: [
            'Frequently skips assigned tasks, is late, makes errors in records and misses paperwork deadlines.', 'Occasionally skips assigned tasks, is late, makes errors in records and misses paperwork deadlines.', 'Is punctual and reliable with paperwork, duties and assigned tasks, keeps accurate records.', 'Carries out assigned tasks conscientiously and punctually, keeps meticulous records and never late.'
        ]
    },
    {
        title: 'Punctuality and Attendance ',
        description: 'Punctual when (a) reporting to work; (b) attending morning assembly / staff meetings; (c) going to classes to conduct lessons. Good Attendance: Absent with certified reasons only. (sick leaves with m.c., urgent leaves, etc) ',
        ratingBenchmark: ['Latecoming to school more than 4 minutes – exceeds 10 times in 1 term. Used up more than 75 % of leaves.', 'Latecoming to school more than 4 minutes – between 6 to 10 times in 1 term. Used up 50 % - 75 % of leaves.', ' Latecoming to school more than 4 minutes – between 2 to 5 times in 1 term. Used up 10 % - 49 % of leaves.', 'Latecoming to school more than 4 minutes – 0 to 1 time in 1 term.Used up 0 –9 % of leaves.'
        ]
    }
]