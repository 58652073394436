import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button, Icon, Page, Header, Table, Card, Grid, Form, Alert, Dropdown } from 'tabler-react';
import { useStore } from '../utils/Store';
import imgDefault from '../assets/placeholders/add_content.svg';
import Axios from 'axios';
import settings from '../app.config';
import Popup from 'reactjs-popup';

const Tutors = {
    Home: () => {
        const [store] = useStore();
        let match = useRouteMatch();

        return (
            <div>
                <div className="title-bar">
                    <Page.Title>
                        <Icon name="users" /> Manage Teachers</Page.Title>
                    <div className="filter-results">
                        {
                            store.rights && store.rights.tutor_create ? <Button.List align="right">
                                <Link to='/home/master/teachers'>
                                    <Button color="primary">
                                        <Icon name="plus" /> Add Teacher
                                    </Button>
                                </Link>
                            </Button.List> : ''
                        }
                    </div>
                </div>
                <Tutors.List />
                <Tutors.Inactive />
            </div >
        );
    },
    List: () => {
        const [store, updateStore] = useStore();
        const [showEdit, setEdit] = useState(null);
        const [showTimetablePop, setViewTimetable] = useState(null);

        let match = useRouteMatch();

        function deactivateItem(id) {
            Axios.post(settings.server + '/user/update', { id, active: 0 })
                .then(res => {
                    if (res.data.success) {
                        const list = store.teachers.filter(item => item.id != id);
                        updateStore({ ...store, teachers: list });
                    }
                });
        }

        function resetPass(id) {

        }

        function viewTimetable(user) {
            setViewTimetable(user);
        }

        function editProfile(user) {
            setEdit(user);
        }

        const listItems = store.teachers.map((user, key) =>
            <Table.Row>
                <Table.Col>{key + 1}</Table.Col>
                <Table.Col>{user.name}</Table.Col>
                <Table.Col>{user.email}</Table.Col>
                <Table.Col>
                    <div className="view-student">
                        <div><Button outline color="secondary" onClick={() => deactivateItem(user.id)}>Deactivate</Button></div>
                        <div className="mr-5">
                            <Button outline color="success" onClick={() => resetPass(user.id)}>Reset Password</Button>
                        </div>
                        <div className="mr-5">
                            <Button color="warning" icon="edit" onClick={() => editProfile(user)}>Edit</Button>
                        </div>
                        {user.type === 'TUTOR' ? <div className="mr-5">
                            <Button color="primary" onClick={() => viewTimetable(user)}>Timetable</Button>
                        </div> : null}
                    </div>
                </Table.Col>
            </Table.Row>
        );

        return (
            <div className="students-list">
                {
                    store.teachers.length === 0 ? (<div className="placeholder-img"><img src={imgDefault} alt='no teachers found' />
                        <Header.H4 className="label">You haven't added any teachers yet.</Header.H4>
                        {
                            store.rights && store.rights.tutor_create ? <Link to='/home/master/teachers'>
                                <Button color="primary">
                                    <Icon name="plus" /> Add Now
                                </Button>
                            </Link> : ''
                        }
                    </div>) : ''
                }
                {
                    store.teachers.length > 0 ? (<Table>
                        <Table.Header>
                            <Table.ColHeader>#</Table.ColHeader>
                            <Table.ColHeader>Name</Table.ColHeader>
                            <Table.ColHeader>Email</Table.ColHeader>
                            <Table.ColHeader></Table.ColHeader>
                        </Table.Header>
                        <Table.Body>
                            {listItems}
                        </Table.Body>
                    </Table >) : ''
                }
                {
                    showEdit ? <Tutors.EditProfile user={showEdit} close={() => editProfile(null)} /> : ''
                }
                {
                    showTimetablePop ? <Tutors.Timetable user={showTimetablePop} close={() => viewTimetable(null)} /> : ''
                }
            </div >
        );
    },
    Inactive: () => {
        const [teachers, setList] = useState([]);
        const [store, updateStore] = useStore();

        useEffect(() => {
            Axios.get(settings.server + '/user/inactive/all')
                .then(res => {
                    if (res.data && res.data.success) {
                        setList(res.data.data);
                    }
                });
        }, [store.teachers.length]);

        function activateItem(data) {
            Axios.post(settings.server + '/user/update', { id: data.id, active: 1 })
                .then(res => {
                    if (res.data.success) {
                        updateStore({ ...store, teachers: [...store.teachers, data] });

                        const list = teachers.filter(item => item.id != data.id);
                        setList(list);
                    }
                });
        }

        const listItems = teachers.map((user, key) =>
            <Table.Row>
                <Table.Col>{key + 1}</Table.Col>
                <Table.Col>{user.name}</Table.Col>
                <Table.Col>{user.email}</Table.Col>
                <Table.Col>
                    <div className="view-student">
                        <div><Button color="primary" onClick={() => activateItem(user)}>Activate</Button></div>
                    </div>
                </Table.Col>
            </Table.Row>
        );

        return (
            <div>
                <div className="title-bar">
                    <Page.Title>
                        <Icon name="users" /> Inactive Teachers
                    </Page.Title>
                </div>
                <div className="students-list">
                    {
                        teachers.length > 0 ? (<Table>
                            <Table.Header>
                                <Table.ColHeader>#</Table.ColHeader>
                                <Table.ColHeader>Name</Table.ColHeader>
                                <Table.ColHeader>Email</Table.ColHeader>
                                <Table.ColHeader></Table.ColHeader>
                            </Table.Header>
                            <Table.Body>
                                {listItems}
                            </Table.Body>
                        </Table >) : ''
                    }

                </div >
            </div>
        );
    },
    EditProfile: ({ user, close }) => {
        const [store, updateStore] = useStore();
        const [form, setForm] = useState({ name: '', email: '', phone: '', addSubject: null, addBatch: null, subjects: [], batches: [], type: 'TUTOR' });
        const [status, setStatus] = useState({ busy: false, type: 'danger', display: false, message: 'Required fields' });

        useEffect(() => {
            setForm(user);
        }, []);

        function formChange(event) {
            const d = { ...form };
            d[event.target.name] = event.target.value;
            setForm(d);
        }

        function addSubject() {
            if (form.addSubject) {
                const n = store.subjects.filter(i => i.id == form.addSubject)[0];
                setForm({ ...form, subjects: [...form.subjects, n], addSubject: '' });
            }
        }

        function addBatch() {
            if (form.addBatch) {
                const n = store.class.filter(i => i.id == form.addBatch)[0];
                setForm({ ...form, batches: [...form.batches, n], addBatch: '' });
            }
        }

        function removeSubject(pos) {
            form.subjects.splice(pos, 1);
            setForm({ ...form, subjects: [...form.subjects] });
        }

        function removeBatch(pos) {
            form.batches.splice(pos, 1);
            setForm({ ...form, batches: [...form.batches] });
        }

        function updateUser() {
            if (status.busy) {
                return;
            }
            setStatus({ ...status, busy: true });
            Axios.post(settings.server + '/user/update', { id: user.id, name: form.name, email: form.email, phone: form.phone, subjects: form.subjects, type: form.type, batches: form.batches })
                .then(res => {
                    if (res.data.success) {
                        setStatus({ busy: false, display: true, type: 'success', message: 'Profile updated' });
                        const n = store.teachers.map(i => {
                            if (i.id == user.id) {
                                return { ...user, ...form };
                            }
                            return i;
                        });
                        updateStore({ ...store, teachers: n });
                        setTimeout(() => close(), 1500);
                    }
                });
        }

        return <Popup open={true} closeOnDocumentClick={false} onClose={close}>
            <div className="modal" style={{ width: '500px' }}>
                <Card>
                    <Card.Header>
                        <Icon name="edit" /> <span className="card-label">Update Profile</span>
                    </Card.Header>
                    <Card.Body>
                        {status.display ? (<Alert type={status.type} icon={status.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                            {status.message}
                        </Alert>) : ''}
                        <Grid.Row>
                            <Grid.Col width="12">
                                <Form.Group label="Full Name" isRequired>
                                    <Form.Input name="name" value={form.name} onChange={formChange.bind(this)} />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col width="12">
                                <Form.Group label="Phone Number">
                                    <Form.Input name="phone" value={form.phone} onChange={formChange.bind(this)} />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col width="12">
                                <Form.Group label="Email" isRequired>
                                    <Form.Input name="email" value={form.email} onChange={formChange.bind(this)} />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col width="12">
                                <Form.Group label="Account Type">
                                    <Form.Select name="type" value={form.type} onChange={formChange.bind(this)}>
                                        <option value="TUTOR">Teacher</option>
                                        <option value="ADMIN">Admin</option>
                                        <option value="HOD">HOD</option>
                                    </Form.Select>
                                </Form.Group>
                            </Grid.Col>
                            {form.type == 'HOD' ? <Grid.Col width="12">
                                <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '1fr auto', gridGap: '20px' }}>
                                    <Form.Group label="Associated Levels">
                                        <Form.Select name="addBatch" value={form.addBatch} onChange={formChange.bind(this)}>
                                            <option value="0">Select</option>
                                            {
                                                store.class.map(item => form.batches.filter(i => i.id == item.id).length == 0 ? <option value={item.id} key={item.id}>{item.name}</option> : '')
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                    <div style={{ paddingTop: '10px' }}>
                                        <Button color="primary" outline onClick={addBatch}>Add</Button>
                                    </div>
                                </div>
                                <Button.List className="mb-4">
                                    {
                                        form.batches ? form.batches.map((i, k) => <Button pill color="primary" key={k} onClick={() => removeBatch(k)}>{i.name}</Button>) : null
                                    }
                                </Button.List>
                            </Grid.Col> : null}
                            <Grid.Col width="12">
                                <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '1fr auto', gridGap: '20px' }}>
                                    <Form.Group label="Associated Subjects">
                                        <Form.Select name="addSubject" value={form.addSubject} onChange={formChange.bind(this)}>
                                            <option value="0">Select</option>
                                            {
                                                store.subjects.map(item => form.subjects.filter(i => i.id == item.id).length == 0 ? <option value={item.id} key={item.id}>{item.name}</option> : '')
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                    <div style={{ paddingTop: '10px' }}>
                                        <Button color="primary" outline onClick={addSubject}>Add</Button>
                                    </div>
                                </div>
                                <Button.List>
                                    {
                                        form.subjects.map((i, k) => <Button pill color="primary" key={k} onClick={() => removeSubject(k)}>{i.name}</Button>)
                                    }
                                </Button.List>
                            </Grid.Col>
                        </Grid.Row>
                    </Card.Body>
                    <Card.Footer>
                        <div>
                            <Button color="secondary" outline onClick={close}>Cancel</Button>
                            <Button color="success" disabled={status.busy} icon="save" onClick={updateUser}>Save</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
        </Popup>
    },
    Timetable: ({ user, close }) => {
        const [overview, setData] = useState({ scheduler: [], version: {}, slots: [] });
        const daysName = ['Today', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

        useEffect(() => {
            if (user && user.id) {
                Axios.get(settings.server + '/dashboard/' + user.id)
                    .then(res => {
                        setData({ scheduler: res.data.scheduler, version: res.data.version, slots: res.data.slots });
                    });
            }
        }, []);

        function downloadFile(type, allClassList) {
            const fileName = user.name + '-' + allClassList.name;

            if (type === 'pdf') {
                // html2canvas(document.querySelector("#element-to-export-" + allClassList)).then(canvas => {
                //     const doc = new jsPDF();

                //     doc.text(fileName, 10, 10);

                //     console.log(canvas.height)
                //     doc.addImage(canvas, 'JPEG', 10, 20, 190, 210)
                //     doc.save(fileName + ".pdf");
                // });

                return;
            }

            const cellsName = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

            const XlsxPopulate = require('xlsx-populate');
            XlsxPopulate.fromBlankAsync()
                .then(workbook => {

                    const sheetName = 'Sheet1';

                    workbook.sheet(sheetName).row(1).height(20);
                    workbook.sheet(sheetName).cell('A1').value(allClassList.name).style("bold", true);

                    overview.slots.map((slot, j) => {
                        let col = 'A' + ((j * 2) + 2);
                        workbook.sheet(sheetName).cell(col).value(slot).style("bold", true);
                    });

                    allClassList.batches.map((item, i) => {
                        let thisRow = (i * 2) + 2;
                        workbook.sheet(sheetName).column(cellsName[i]).width(25);

                        const batchnameLabel = daysName[item.day] || '';
                        workbook.sheet(sheetName).cell(cellsName[i + 1] + '1').value(batchnameLabel).style("bold", true);


                        item.scheduler.map((subject, j) => {
                            thisRow = (j * 2) + 2;
                            workbook.sheet(sheetName).row(thisRow + 1).height(40);

                            let col;
                            if (Math.floor((i + 1) / cellsName.length) > 0) {
                                col = cellsName[Math.floor((i / (cellsName.length - 1))) - 1] + cellsName[i % (cellsName.length - 1)];
                            } else {
                                col = cellsName[i + 1];
                            }

                            let thisCell = col + thisRow;

                            if (item.scheduler[j] && item.scheduler[j].short_name) {
                                workbook.sheet(sheetName).cell(thisCell).value(subject.short_name).style("fill", 'bfbfbf');
                                workbook.sheet(sheetName).cell(col + (thisRow + 1)).value(item.scheduler[j].tutor);
                            } else {
                                if (item.scheduler[j] && item.scheduler[j].id) {
                                    workbook.sheet(sheetName).cell(thisCell).value(subject.id);
                                } else {
                                    workbook.sheet(sheetName).cell(thisCell).value(' ');
                                }
                            }
                        });
                    });


                    workbook.outputAsync()
                        .then(function (blob) {
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                // If IE, you must uses a different method.
                                window.navigator.msSaveOrOpenBlob(blob, fileName + ".xlsx");
                            } else {
                                var url = window.URL.createObjectURL(blob);
                                var a = document.createElement("a");
                                document.body.appendChild(a);
                                a.href = url;
                                a.download = fileName + ".xlsx";
                                a.click();
                                window.URL.revokeObjectURL(url);
                                document.body.removeChild(a);
                            }
                        });
                });
        }

        const dayList = overview.scheduler.map((allClassList, i) =>
            <div className="ov-sc-tutor-col" key={i}>
                <div className="ov-sc-col-name">{allClassList.name}</div>
                <div id={'element-to-export-' + i} className="dashboard-wrapper-tutor">
                    {
                        allClassList.batches.map((item, key) =>
                            <div className="ov-sc-tutor-row" key={key}>
                                <div className="ov-sc-class">
                                    <b>{item.batch && item.batch.division ? item.batch.division : ''}</b>
                                    {daysName[item.day]}
                                </div>

                                <div className="ov-sc-slot-wrap">
                                    {item.scheduler ?
                                        item.scheduler.map((slot, j) => item.scheduler[j] && item.scheduler[j].short_name ? <div key={j} className="ov-sc-slot" style={{ backgroundColor: item.scheduler[j].color }}>
                                            <div className="title">{item.scheduler[j].short_name}</div>
                                            <div className="label" title={item.scheduler[j].tutor}>{item.scheduler[j].tutor}</div>
                                        </div> : item.scheduler[j] && item.scheduler[j].id ? <div className="ov-sc-slot">{item.scheduler[j].id}<h5><em>{item.scheduler[j].class_name}-{item.scheduler[j].division}</em></h5></div> : <div className="ov-sc-slot"></div>) : null

                                    }
                                </div>
                            </div>

                        )
                    }
                </div>
            </div>
        );

        const view = (
            <Card.Body>
                {
                    overview && overview.scheduler.length > 0 ? <div className={'common-dashboard-wrapper dashboard-wrapper-tutor '}>
                        <div className="ov-sc-tutor-col">
                            <div className="ov-sc-tutor-row">
                                <div className="ov-sc-col-name"></div>
                                <div className="ov-sc-class">Time</div>
                                <div className="ov-sc-slot-wrap">
                                    {
                                        overview.slots ? overview.slots.map(time => <div className="ov-sc-slot">{time}</div>) : null
                                    }
                                </div>
                            </div>
                        </div>
                        {dayList}
                    </div> : ''
                }
                {
                    overview && !overview.scheduler.length ? '...' : ''
                }
            </Card.Body >);

        return <Popup open={true} closeOnDocumentClick={false} onClose={close}>
            <div className="modal" style={{ width: '1000px', minWidth: '1000px' }}>
                <a className="close-modal" onClick={close}>&times;</a>
                <Card>
                    <Card.Header>
                        <Icon name="edit" /> <span className="card-label">Weekly Timetable</span>

                        {overview && overview.scheduler.length ? <Dropdown triggerContent="Export">
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => downloadFile('excel', overview.scheduler[1])}>Excel</Dropdown.Item>
                                {/* <Dropdown.Item onClick={() => downloadFile('pdf', i)}>PDF</Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown> : null}
                    </Card.Header>
                    {view}
                    <Card.Footer>
                        <div>
                            <Button color="secondary" outline onClick={close}>Close</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
        </Popup>;
    }
}

export default Tutors;