import React, { useState, useEffect } from 'react';
import { Card, Button, Form, Grid, Table, Header, Icon, Dimmer } from 'tabler-react';
import { Calendar as CalanderPicker } from 'react-calendar';
import { useStore } from '../utils/Store';
import { Link, useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import settings from '../app.config';
import imgEmpty from '../assets/placeholders/no_results.svg';

let date = new Date();

const Attendance = {
    Update: () => {
        return (
            <Grid.Row>
                <Grid.Col md={4}>
                    <Attendance.Calander />
                </Grid.Col>
                <Grid.Col md={8}>
                    <Attendance.Mark />
                </Grid.Col>
            </Grid.Row>
        )

    },
    Calander: () => {
        const [store, setStore] = useStore();

        useEffect(() => {
            const d = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
            setStore({ ...store, utils: { date: d } });
        }, [date]);


        const updateDate = (val) => {
            const d = val.getFullYear() + '/' + (val.getMonth() + 1) + '/' + val.getDate();
            setStore({ ...store, utils: { date: d } });
        }

        return (
            <CalanderPicker value={date} onChange={updateDate} />
        )
    },
    Mark: () => {
        const [store, setStore] = useStore();
        const urlMatch = useRouteMatch();
        const [allStudents, setStudents] = useState({ busy: false, data: [] });
        const [formBusy, setFormState] = useState(false);

        useEffect(() => {
            setStudents({ busy: true, data: [] });
            axios.get(settings.server + '/batch/attendance/all?batch=' + store.batch.id + '&date=' + store.utils.date)
                .then(res => {
                    if (res.data.success) {
                        setStudents({ busy: false, data: res.data.data });
                    }
                });
        }, [store.utils.date]);

        const saveChanges = () => {
            setFormState(true);

            (function eachStudent(pos) {
                const item = allStudents.data[pos];
                axios.post(settings.server + '/batch/attendance/update', { batch: store.batch.id, student: item.id, date: store.utils.date, status: item.status })
                    .then(res => {
                        if (res.data.success) {
                            if (pos > 0) {
                                eachStudent(pos - 1);
                            } else {
                                setFormState(false);
                            }
                        }
                    }, err => {
                        setFormState(false);
                    })
            })(allStudents.data.length - 1);

        };

        const markEveryOnePresent = () => {
            const data = allStudents.data.map(item => {
                item.status = '1';
                return item;
            });
            setStudents({ busy: false, data });
        }

        const markStatus = (id, status) => {
            const data = allStudents.data.map(item => {
                if (item.id === id) {
                    item.status = status;
                }
                return item;
            });
            setStudents({ busy: false, data });
        }

        const listItems = allStudents.data.map((student, key) =>
            <Table.Row>
                <Table.Col>{key + 1}</Table.Col>
                <Table.Col>{student.firstname + ' ' + student.lastname}</Table.Col>
                <Table.Col>
                    <Form.Group>
                        <Form.SelectGroup onChange={(event) => markStatus(student.id, event.target.value)} type="radio">
                            <Form.SelectGroupItem
                                label="Full day"
                                type="radio"
                                name={student.id}
                                value="1"
                                checked={student.status === '1'}
                            />
                            <Form.SelectGroupItem
                                label="Half day"
                                type="radio"
                                name={student.id}
                                value="2"
                                checked={student.status === '2'}
                            />
                            <Form.SelectGroupItem
                                label="Absent"
                                type="radio"
                                name={student.id}
                                value="0"
                                checked={student.status === '0'}
                            />
                        </Form.SelectGroup>
                    </Form.Group>
                </Table.Col>
            </Table.Row>
        );

        const resultDOM = (<div>
            {
                allStudents.data.length === 0 ? (
                    <div className="placeholder-img"><img src={imgEmpty} alt='No results found' />
                        <Header.H4 className="label">Sorry, You didn't added any students yet.</Header.H4>
                        <Link to={`/home/class/view/${store.batch.id}/students`}>
                            <Button color="primary"><Icon name="plus" /> Add Students</Button>
                        </Link>
                    </div>) : ''
            }
            {allStudents.data.length > 0 ?
                <Card>
                    <Card.Header>
                        <Card.Title>Mark Attendance</Card.Title>
                        <Card.Options>
                            <Form.Checkbox
                                label="Mark everyone present"
                                name="example-radios"
                                value="option1"
                                onChange={markEveryOnePresent}
                            />
                        </Card.Options>
                    </Card.Header>
                    <Card.Body style={{ padding: 0 }}>
                        <Table>
                            <Table.Header>
                                <Table.ColHeader>Roll.No</Table.ColHeader>
                                <Table.ColHeader>Full Name</Table.ColHeader>
                                <Table.ColHeader>Status</Table.ColHeader>
                            </Table.Header>
                            <Table.Body>
                                {
                                    listItems
                                }
                            </Table.Body>
                        </Table >
                    </Card.Body>
                    <Card.Footer>
                        <Button color="success" loading={formBusy} disabled={formBusy} onClick={saveChanges}>Save</Button>
                    </Card.Footer>
                </Card> : ''
            }
        </div>
        );

        return (
            <Dimmer active={allStudents.busy} loader>{resultDOM}</Dimmer>
        );
    }
}


export default Attendance;