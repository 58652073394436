import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Grid, Card, Form, Badge, Dropdown } from "tabler-react";
import settings from '../app.config';
import { useStore } from "../utils/Store";
import { useParams } from "react-router-dom";
import { AdminFeedBackQuestions } from './form-admin.data';
import { adminRateScale, hodRateScale, NonExecutiveRateScale, teacherRateScale } from './rateScale.data';
import { HodFeedBackQuestions } from './form-hod.data';
import { TeacherFeedBackQuestions } from './form-teacher.data';
import { NonExecutiveFeedBackQuestions } from './form-non-executive.data';

export function ViewFeedbackComponent() {
    const { id } = useParams();
    const [getUserStore] = useStore();
    const [getFeedbackData, setFeedbackData] = useState(null);
    const [getFeedbackQuestions, setFeedbackQuestions] = useState([]);
    const [getRating, setRating] = useState([]);
    const [rateScale, setRateScale] = useState([]);

    useEffect(() => {
        Axios.get(settings.server + '/feedback/' + id)
            .then(res => {
                if (res.data.success) {
                    setFeedbackData(res.data.data);
                    setRating(res.data.reviews);
                    switch (res.data.data.type) {
                        case 'ADMIN':
                            setFeedbackQuestions(AdminFeedBackQuestions);
                            setRateScale(adminRateScale);
                            break;
                        case 'TEACHER':
                            setFeedbackQuestions(TeacherFeedBackQuestions);
                            setRateScale(teacherRateScale);
                            break;
                        case 'HOD':
                            setFeedbackQuestions(HodFeedBackQuestions);
                            setRateScale(hodRateScale);
                            break;
                        case 'NONEXEC':
                            setFeedbackQuestions(NonExecutiveFeedBackQuestions);
                            setRateScale(NonExecutiveRateScale);
                            break;
                    }

                }
            }, err => {
                setFeedbackData(null);
            });
    }, []);

    function feedbackTypeLabel(id) {
        switch (id) {
            case 'ADMIN': return 'ADMIN'; break;
            case 'TEACHER': return 'TEACHER'; break;
            case 'HOD': return 'HOD'; break;
            case 'NONEXEC': return 'NON EXECUTIVE'; break;
        }
    }


    function downloadFile() {
        const XlsxPopulate = require('xlsx-populate');

        const cellsName = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        const fileName = 'Review Feedback  - ' + getFeedbackData.submitted_by;

        XlsxPopulate.fromBlankAsync()
            .then(workbook => {
                const sheetName = 'Sheet1';

                workbook.sheet(sheetName).row(1).height(20);
                workbook.sheet(sheetName).column(1).width(60);
                workbook.sheet(sheetName).cell('A1').value('Review Feedback - Submitted by ' + getFeedbackData.submitted_by).style("bold", true);

                rateScale.forEach((rateScaleItem, i) => {
                    workbook.sheet(sheetName).cell(cellsName[i + 1] + '2').value(rateScaleItem.label);
                    workbook.sheet(sheetName).column(i + 2).width(30);
                });

                getFeedbackQuestions.forEach((item, i) => {
                    const currentRow = (i + 3);
                    workbook.sheet(sheetName).row(currentRow).height(100);
                    workbook.sheet(sheetName).cell('A' + currentRow).value(item.title + '-' + item.description);

                    rateScale.forEach((rateScaleItem, j) => {
                        let cellContent = [];
                        getRating.forEach(rating => {
                            if (rating.rating['q' + i] === rateScaleItem.id) {
                                cellContent.push(rating.submitted_by.name);
                            }
                        });
                        workbook.sheet(sheetName).cell(cellsName[j + 1] + currentRow).value(cellContent.join(','));
                    });
                });

                workbook.outputAsync()
                    .then(function (blob) {
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            // If IE, you must uses a different method.
                            window.navigator.msSaveOrOpenBlob(blob, fileName + ".xlsx");
                        } else {
                            var url = window.URL.createObjectURL(blob);
                            var a = document.createElement("a");
                            document.body.appendChild(a);
                            a.href = url;
                            a.download = fileName + ".xlsx";
                            a.click();
                            window.URL.revokeObjectURL(url);
                            document.body.removeChild(a);
                        }
                    });
            });
    }

    return <Grid>
        <Grid.Row>
            <Grid.Col sm={12} md={12} >
                {getFeedbackData && <Card>
                    <Card.Header>
                        <Grid>
                            <Grid.Row justifyContent='space-between' alignItems='center' style={{ width: '100%' }}>
                                <Grid.Col>
                                    <Badge color="success" className="mr-4">{feedbackTypeLabel(getFeedbackData.type)}</Badge> Feedback Submitted on&nbsp;<b>{getFeedbackData.ts}</b>
                                </Grid.Col>
                                <Grid.Col style={{ flexGrow: 0 }}>
                                    <Dropdown triggerContent="Export">
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => downloadFile()}>Excel</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid>
                    </Card.Header>
                    <Card.Body>
                        <Grid.Row>
                            <Grid.Col sm={12} md={12}>
                                <Form.Group label="Submitted To">
                                    <Form.Input value={getFeedbackData.user_name} disabled />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col sm={12} md={6}>
                                <Form.Group label="Assessment Year">
                                    <Form.Input value={getFeedbackData.year} disabled />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col sm={12} md={6}>
                                <Form.Group label="Period of Assessment">
                                    <Form.Input value={getFeedbackData.period_of_assessment} disabled />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col sm={12} md={6}>
                                <Form.Group label="NRIC Number (Last 4 characters)">
                                    <Form.Input value={getFeedbackData.nricNo} disabled />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col sm={12} md={6}>
                                <Form.Group label="Length of Service">
                                    <Form.Input value={getFeedbackData.lengthOfService} disabled />
                                </Form.Group>
                            </Grid.Col>
                        </Grid.Row>
                    </Card.Body>
                </Card>
                }
            </Grid.Col>
        </Grid.Row>
        <Grid.Row>
            {getFeedbackQuestions.map((item, i) => <Grid.Col sm={12} md={12} key={i}>
                <Card>
                    <Card.Header><div><b>{i + 1}. {item.title}</b> -  {item.description}</div></Card.Header>
                    <Card.Body>
                        {rateScale && rateScale.map((rateScaleItem, j) => <Form.Group label={rateScaleItem.label}>
                            <Grid>
                                <Grid.Row gutters='sm'>
                                    <Grid.Col sm={12}>
                                        {item.ratingBenchmark[j]}
                                    </Grid.Col>
                                    <Grid.Col sm={12}>
                                        <Form.ColorCheck  >
                                            {getRating.map(rating => rating.rating['q' + i] === rateScaleItem.id ? <div style={{ display: 'flex', alignItems: 'center' }}><Form.ColorCheckItem disabled outline color={rateScaleItem.color} checked={true} /> By {rating.submitted_by.name} </div> : null)}
                                        </Form.ColorCheck>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Form.Group>
                        )}
                    </Card.Body>
                </Card>
            </Grid.Col>)}
        </Grid.Row>
    </Grid>
}