import React, { useEffect, useState } from 'react';
import { Grid, Card, List, Icon, Table, Form, Button, Header, Dimmer, Alert } from 'tabler-react';
import { Link, useRouteMatch, useParams } from "react-router-dom";
import { useStore } from '../utils/Store';
import axios from 'axios';
import settings from '../app.config';

import imgEmpty from '../assets/placeholders/no_results.svg';

let activeSubject = null;

const Exam = {
    Home: () => {
        const [store, updateStore] = useStore();
        const [exam, setExam] = useState({ busy: true, success: false, data: null, subjects: [] });
        let { examID } = useParams();

        useEffect(() => {

            axios.get(settings.server + '/exam/' + examID).then((res) => {

                if (res.data.success) {
                    setExam({ success: true, busy: false, data: res.data.data, subjects: res.data.subjects });
                    if (res.data.subjects[0] && !activeSubject) {
                        updateStore({ ...store, utils: { ...store.utils, reload: false, subjectID: res.data.subjects[0].id } });
                    } else {
                        updateStore({ ...store, utils: { ...store.utils, reload: false } });
                    }
                }

            }, err => {
                setExam({ ...exam, busy: false })
            });

        }, [exam.busy, store.utils.reload]);

        const resultDOM = (
            <Grid>
                <Exam.Overview exam={exam} />
                <Grid.Row>
                    <Grid.Col md={3}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Subjects</Card.Title>
                            </Card.Header>
                            <Card.Body style={{ padding: 0 }}>
                                <List.Group className="nav-list">
                                    <Exam.Subjects subjects={exam.subjects} />
                                </List.Group>
                            </Card.Body>
                        </Card>
                    </Grid.Col>
                    <Grid.Col md={9}>
                        <Exam.Students exam={exam} />
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        );

        const showError = (<Alert type='danger' icon='alert-triangle'>Exam not found.</Alert>)

        return (
            <Dimmer active={exam.busy} loader>{exam.success ? resultDOM : showError}</Dimmer>
        );
    },
    Overview: (props) => {
        return (
            <Grid.Row cards deck>
                <Grid.Col md={6}>
                    <Card statusColor="yellow">
                        <Card.Body>
                            <Header.H4>Examination Name</Header.H4>
                            <Header.H2>{props.exam.data.exam_name}</Header.H2>
                        </Card.Body>
                    </Card>
                </Grid.Col>
                <Grid.Col md={3}>
                    <Card statusColor="red">
                        <Card.Body>
                            <Header.H4>Date</Header.H4>
                            <Header.H2>11/4/2019</Header.H2>
                        </Card.Body>
                    </Card>
                </Grid.Col>
                <Grid.Col md={3}>
                    <Card statusColor="red">
                        <Card.Body>
                            <Header.H4>Performace</Header.H4>
                            <Header.H2></Header.H2>
                        </Card.Body>
                    </Card>
                </Grid.Col>
            </Grid.Row>
        );
    },
    Subjects: (props) => {
        const [store, updateStore] = useStore();

        const setSubject = (id) => {
            activeSubject = id;
            updateStore({ ...store, utils: { ...store.utils, subjectID: id } });
        };

        const list = props.subjects.map(item => (
            <List.GroupItem action active={item.id === store.utils.subjectID} id="master-nav-class" className="item master-nav-list" onClick={() => { setSubject(item.id) }} >
                {item.name}
                {item.completed ? <Button pill color="success" icon="check" /> : <Button pill color="warning" icon="alert-triangle" />}
            </List.GroupItem>)
        );

        return list;
    },
    Students: (props) => {
        const [store, updateStore] = useStore();
        const [allStudents, setStudents] = useState([]);
        const [formBusy, setFormState] = useState(false);
        const [pageBusy, setPageState] = useState(false);

        useEffect(() => {
            setPageState(true);
            axios.get(settings.server + '/exam/' + props.exam.data.id + '/marks?batch=' + store.batch.id + '&subject=' + store.utils.subjectID)
                .then(res => {
                    if (res.data.success) {
                        setStudents(res.data.data);
                        setPageState(false);
                    }
                }, err => {
                    setStudents([]);
                    setPageState(false);
                });
        }, store.utils.subjectID);

        const updateMark = (id, status, mark) => {
            const data = allStudents.map(student => {
                if (student.id === id) {
                    if (mark) { student.mark = mark; }
                    if (status) { student.status = status; }
                }
                return student;
            });

            setStudents(data);
        }

        const saveData = () => {
            setFormState(true);
            (function eachStudent(pos) {
                const param = allStudents[pos];
                axios.post(settings.server + '/exam/mark/update', { exam: props.exam.data.id, student: param.id, subject: store.utils.subjectID, status: param.status, mark: param.mark })
                    .then(res => {
                        if (res.data.success) {
                            if (pos > 0) {
                                eachStudent(pos - 1);
                            } else {
                                setFormState(false);
                                updateStore({ ...store, utils: { ...store.utils, reload: true } });
                            }
                        }
                    }, err => {
                        alert('Failed. try again');
                        setFormState(false);
                    });
            })(allStudents.length - 1);
        }
        const listItems = allStudents.map((student, key) =>
            <Table.Row>
                <Table.Col>{key + 1}</Table.Col>
                <Table.Col>{student.firstname + ' ' + student.lastname}</Table.Col>
                <Table.Col>
                    <Form.Group>
                        <Form.SelectGroup onChange={(event) => updateMark(student.id, event.target.value, false)}>
                            <Form.SelectGroupItem
                                label="Present"
                                name={student.id}
                                value="1"
                                checked={student.status === '1'}
                            />
                            <Form.SelectGroupItem
                                label="Absent"
                                name={student.id}
                                value="0"
                                checked={student.status === '0'}
                            />
                        </Form.SelectGroup>
                    </Form.Group>
                </Table.Col>
                <Table.Col>
                    <Form.Input value={student.mark && student.status === '1' ? student.mark : 0} onChange={(event) => updateMark(student.id, false, event.target.value)} disabled={student.status === '1' ? false : true} />
                </Table.Col>
            </Table.Row>
        );

        const cardDOM = (<Card>
            <Card.Header>
                <Card.Title>Score Sheet</Card.Title>
            </Card.Header>
            <Card.Body style={{ padding: 0 }}>
                <Dimmer active={pageBusy} loader>
                    <Table>
                        <Table.Header>
                            <Table.ColHeader>Roll.No</Table.ColHeader>
                            <Table.ColHeader>Full Name</Table.ColHeader>
                            <Table.ColHeader>Status</Table.ColHeader>
                            <Table.ColHeader>Score</Table.ColHeader>
                        </Table.Header>
                        <Table.Body>
                            {listItems}
                        </Table.Body>
                    </Table>
                </Dimmer>
            </Card.Body>
            <Card.Footer>
                <Button color="success" disabled={formBusy} loading={formBusy} onClick={saveData}>Save</Button>
            </Card.Footer>
        </Card>);

        const emptyPlaceholder = (<div className="placeholder-img"><img src={imgEmpty} alt='No results found' />
            <Header.H4 className="label">Sorry, You didn't added any students yet.</Header.H4>
            <Link to={`/home/class/view/${store.batch.id}/students`}>
                <Button color="primary"><Icon name="plus" /> Add Students</Button>
            </Link>
        </div>);

        return allStudents.length === 0 ? emptyPlaceholder : cardDOM;
    }
}




export default Exam;