import React, { useState, useEffect } from 'react';
import { Card, Icon, Button, Form, Grid, List, Header, Alert, Badge } from 'tabler-react';
import './Styles.css';
import { useStore } from '../utils/Store';
import axios from 'axios';
import settings from '../app.config';

import imgSubjects from '../assets/placeholders/config_default.svg';
import Popup from 'reactjs-popup';


const Subjects = {
    Home: () => {
        const [store, updateStore] = useStore();
        const [openEditModal, setEditModal] = useState(null);
        const [openDelModal, setDelModal] = useState(null);

        useEffect(() => {
            const el = document.getElementsByClassName('master-nav-list');
            for (const element in el) {
                const node = el[element];
                if (node.classList) { node.classList.remove('active'); }
            };

            const actEl = document.getElementById('master-nav-subject');
            if (actEl) { actEl.classList.add('active'); }
        }, []);

        function editItem(item) {
            setEditModal(item);
        }

        function confirmDelDialog(item) {
            setDelModal(item);
        }

        function removeItem(item) {
            if (item && item.id) {
                axios.post(settings.server + '/master/subject/remove', { id: item.id })
                    .then(res => {
                        if (res.data.success) {
                            const newList = store.subjects.filter(i => item.id !== i.id);
                            updateStore({ ...store, subjects: newList });
                        }
                    }, err => {

                    });
            }

            setDelModal(null);
        }

        const list = (<List.Group>
            {
                store.subjects.map(item => (<List.GroupItem className="hover-controls">
                    <div>
                        <Popup
                            trigger={
                                <span className="subject-pill" style={{ backgroundColor: item.color }}>{item.name}</span>
                            }
                            on={['hover', 'focus']} position={'right center'} arrow="true"
                        >
                            {item.short_name}
                        </Popup></div>
                    <div>
                        <Badge color="warning" className="controls cursor mr-4" onClick={() => editItem(item)}>Edit</Badge>
                        <Badge color="danger" className="controls cursor" onClick={() => confirmDelDialog(item)}>Delete</Badge>
                    </div></List.GroupItem>))
            }
        </List.Group>);

        return (
            <div>
                <Grid.Row gutters="xs">
                    <Grid.Col>
                        <Card icon="edit">
                            <Card.Header>
                                <Card.Title><Icon name="edit" /> <span className="card-label">Manage Subjects</span></Card.Title>
                            </Card.Header>
                            <Card.Body style={{ padding: "0" }}>
                                {
                                    store.subjects && store.subjects.length > 0 ? list : (<div className="placeholder-img"><img src={imgSubjects} alt='no subjects added' />
                                        <Header.H4 className="label">You can manage your Subjects here</Header.H4>
                                    </div>)
                                }
                            </Card.Body>
                        </Card>
                    </Grid.Col>
                    <Grid.Col className="master-class-create">
                        <Subjects.NewSubject />
                    </Grid.Col>
                </Grid.Row>
                <Subjects.EditSubject data={openEditModal} close={() => editItem(null)} />
                <Subjects.DelSubject data={openDelModal} close={(data) => removeItem(data)} />
            </div>
        )
    },
    NewSubject: () => {
        const [store, setStore] = useStore();
        const [form, setForm] = useState({ busy: false, message: null, name: '', shortName: '', isElective: 0 });

        const handleForm = (event) => {
            const d = { ...form };
            d[event.target.name] = event.target.value;
            setForm(d);
        };


        const saveSubject = () => {
            if (form.busy) {
                return;
            }
            setForm({ ...form, busy: true, message: null });
            axios.post(settings.server + '/subject/create', { name: form.name, short_name: form.shortName, is_elective: form.isElective })
                .then(res => {
                    setForm({ ...form, busy: false });
                    if (res.data.success) {
                        store.subjects.push(res.data.data);
                        setStore({ ...store, subjects: store.subjects });
                        setForm({ ...form, busy: false, alert: 'success', name: '', short_name: '', message: 'Subject added!', isElective: 0 });
                    }
                }, err => {
                    setForm({ ...form, busy: false, message: 'Network Error', alert: 'danger', });
                });
        };

        return (
            <Card icon="edit">
                <Card.Header>
                    <Card.Title><Icon name="plus" /> <span className="card-label">Add New Subject</span></Card.Title>
                </Card.Header>
                <Card.Body>
                    <Grid.Row>
                        <Grid.Col width="12">
                            {form.message ? (<Alert type={form.alert} icon={form.alert === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                                {form.message}
                            </Alert>) : ''}

                            <Form.Group label="Subject name" isRequired>
                                <Form.Input name="name" value={form.name} onChange={handleForm.bind(this)} />
                            </Form.Group>

                            <Form.Group label="Short name" isRequired>
                                <Form.Input name="shortName" value={form.shortName} onChange={handleForm.bind(this)} />
                            </Form.Group>

                            <Form.Group label="Type">
                                <Form.Select name="isElective" value={form.isElective} onChange={handleForm.bind(this)}>
                                    <option value='0'>Academic</option>
                                    <option value='1'>Arabic</option>
                                    <option value='2'>Islamic</option>
                                    <option value='3'>IT</option>
                                    <option value='4'>PE</option>
                                    <option value='5'>Enrichment</option>
                                </Form.Select>
                            </Form.Group>
                        </Grid.Col>
                    </Grid.Row>

                </Card.Body>
                <Card.Footer>
                    <div>
                        <Button color="success" icon="save" onClick={saveSubject}>Create</Button>
                    </div>
                </Card.Footer>
            </Card>
        );
    },
    EditSubject: ({ data, close }) => {
        const [store, updateStore] = useStore();
        const [showError, setError] = useState({ display: false, busy: false, type: 'danger', message: '' });

        const [form, setForm] = useState({ name: '', shortName: '', isElective: 0 });
        useEffect(() => {
            if (data && data.id) {
                setForm({ name: data.name, shortName: data.short_name, isElective: data.is_elective });
            } else {
                setForm({ name: '', shortName: '', isElective: '' });
            }
        }, [data]);


        function handleChange(event) {
            const d = { ...form };
            d[event.target.name] = event.target.value;
            setForm(d);
        }


        function updateSubject() {
            if (showError.busy) {
                return;
            }
            if (form.name.length > 1) {
                setError({ ...showError, busy: true });
                axios.post(settings.server + '/master/subject/update', { name: form.name, id: data.id, section: form.section, color: data.color, short_name: form.shortName, is_elective: form.isElective })
                    .then(res => {
                        if (res.data.success) {
                            const newd = store.subjects.map(item => {
                                if (item.id == data.id) {
                                    return res.data.data;
                                }
                                return item;
                            });
                            updateStore({ ...store, subjects: newd });
                            setTimeout(() => close(), 1500);
                            setError({ display: true, busy: false, type: 'success', message: 'Changes saved' });
                        } else {
                            setError({ display: true, busy: false, type: 'danger', message: res.data.message });
                        }
                    }, err => {
                        setError({ display: true, busy: false, type: 'danger', message: 'Network Error. Try again' });
                    });
            } else {
                setError({ display: true, busy: false, type: 'danger', message: 'Enter atleast 2 characters' });
            }
        }

        return <Popup open={data && data.id ? true : false} closeOnDocumentClick={false} onClose={close}>
            <div className="modal">
                <Card>
                    <Card.Header>
                        <Card.Title><Icon name="edit" /> <span className="card-label">Update</span></Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Grid.Row>
                            <Grid.Col>
                                {showError.display ? (<Alert type={showError.type} icon={showError.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                                    {showError.message}
                                </Alert>) : ''}
                                <Form.Group label="Subject name" isRequired>
                                    <Form.Input name="name" value={form.name} onChange={handleChange.bind(this)} />
                                </Form.Group>
                                <Form.Group label="Short name" isRequired>
                                    <Form.Input name="shortName" value={form.shortName} onChange={handleChange.bind(this)} />
                                </Form.Group>

                                <Form.Group label="Type">
                                    <Form.Select name="isElective" value={form.isElective} onChange={handleChange.bind(this)}>
                                        <option value='0'>Academic</option>
                                        <option value='1'>Arabic</option>
                                        <option value='2'>Islamic</option>
                                        <option value='3'>IT</option>
                                        <option value='4'>PE</option>
                                        <option value='5'>Enrichment</option>
                                    </Form.Select>
                                </Form.Group>
                            </Grid.Col>
                        </Grid.Row>
                    </Card.Body>
                    <Card.Footer>
                        <div>
                            <Button color="secondary" outline onClick={close}>Cancel</Button>
                            <Button color="success" loading={showError.busy} icon="save" onClick={() => updateSubject()}>Save</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
        </Popup>
    },
    DelSubject: ({ data, close }) => {

        function deleteConfirm() {
            close(data);
        }

        return <Popup open={data && data.id ? true : false} closeOnDocumentClick={false} onClose={close}>
            <div className="modal" style={{ width: '500px' }}>
                <Card>
                    <Alert type="danger" style={{ marginBottom: '0px' }}>
                        <Header.H4>Confirm Delete</Header.H4>
                        <p>You will lose all the data associated with this Subject. This is a permanent process. You can't recover your data after confirmation.</p>
                        <Button.List>
                            <Button color="secondary" RootComponent="button" onClick={close}>Cancel</Button>
                            <Button color="danger" outline RootComponent="button" onClick={deleteConfirm}>Confirm Delete</Button>
                        </Button.List>
                    </Alert>
                </Card>
            </div>
        </Popup>;
    }

}


export default Subjects;