import React, { useState, useEffect } from 'react';
import { Card, Icon, Button, Form, Grid, List, Alert, Badge, Header } from 'tabler-react';
import './Styles.css';
import axios from 'axios';
import settings from '../app.config';
import { useStore } from '../utils/Store';
import Popup from 'reactjs-popup';

const Class = {
    Home: () => {
        let [data, updateStore] = useStore();
        const [openEditModal, setEditModal] = useState(null);
        const [openDelModal, setDelModal] = useState(null);

        useEffect(() => {
            const el = document.getElementsByClassName('master-nav-list');
            for (const element in el) {
                const node = el[element];
                if (node.classList) { node.classList.remove('active'); }
            };

            const actEl = document.getElementById('master-nav-class');
            if (actEl) { actEl.classList.add('active'); }
        }, []);

        function confirmDelDialog(item) {
            setDelModal(item);
        }

        const removeItem = (item) => {
            if (item && item.id) {
                Class.removeClass(item.id, () => {
                    const newList = data.class.filter(i => item.id !== i.id);
                    updateStore({ ...data, class: newList });
                });

            }

            setDelModal(null);
        }

        function editItem(data) {
            setEditModal(data);
        }

        return (
            <div>
                <Grid.Row gutters="xs">
                    <Grid.Col>
                        <Card icon="edit">
                            <Card.Header>
                                <Card.Title><Icon name="edit" /> <span className="card-label">Manage Levels ({data.class.length})</span></Card.Title>
                            </Card.Header>
                            <Card.Body style={{ padding: "0" }}>
                                <List.Group>
                                    {
                                        data.class.map((val) =>
                                            <List.GroupItem className="hover-controls">
                                                <div>
                                                    <span>{val.name}</span>&nbsp;
                                                     ({val.section_name})
                                                </div>
                                                <div>
                                                    <Badge color="warning" className="controls cursor mr-4" onClick={() => editItem(val)}>Edit</Badge>
                                                    <Badge color="danger" className="controls cursor" onClick={() => confirmDelDialog(val)}>Delete</Badge>
                                                </div>
                                            </List.GroupItem>
                                        )
                                    }
                                </List.Group>
                            </Card.Body>
                        </Card>
                    </Grid.Col>
                    <Grid.Col className="master-class-create">
                        <Class.NewClass />
                        <Class.NewSection />
                    </Grid.Col>
                </Grid.Row>
                <Class.EditClass data={openEditModal} close={() => editItem(null)} />
                <Class.DelConfirm data={openDelModal} close={(data) => removeItem(data)} />
            </div>
        )
    },
    NewClass: () => {
        const [store, updateStore] = useStore();
        const [showError, setError] = useState({ display: false, busy: false, type: 'danger', message: '' });

        const [form, setForm] = useState({ name: '', section: '' });

        function handleChange(event) {
            const prop = { ...form };
            prop[event.target.name] = event.target.value;
            setForm(prop);
        }


        function newClass() {
            if (showError.busy) {
                return;
            }
            if (form && form.section && form.name.length > 1) {
                setError({ ...showError, busy: true });
                axios.post(settings.server + '/class/create', { ...form })
                    .then(res => {
                        if (res.data.success) {
                            store.class.push(res.data.data);
                            updateStore({ ...store, class: store.class });
                            setForm({ name: '', section: ' ' });
                            setError({ display: true, busy: false, type: 'success', message: 'Level added' });
                        } else {
                            setError({ display: true, busy: false, type: 'danger', message: res.data.message });
                        }
                    }, err => {
                        setError({ display: true, busy: false, type: 'danger', message: 'Network Error. Try again' });
                    });
            } else {
                setError({ display: true, busy: false, type: 'danger', message: 'Enter atleast 2 characters' });
            }
        }

        return (
            <Card>
                <Card.Header>
                    <Card.Title><Icon name="plus" /> <span className="card-label">Add New Level</span></Card.Title>
                </Card.Header>
                <Card.Body>
                    <Grid.Row>
                        <Grid.Col>
                            {showError.display ? (<Alert type={showError.type} icon={showError.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                                {showError.message}
                            </Alert>) : ''}
                            <Form.Group label="Level name" isRequired>
                                <Form.Input name="name" value={form.name} onChange={handleChange.bind(this)} />
                            </Form.Group>
                            <Form.Group label="Section" isRequired>
                                <Form.Select name="section" value={form.section} onChange={handleChange.bind(this)}>
                                    <option value=''>Choose a section</option>
                                    {
                                        store.sections.map(item => <option value={item.id} key={item.id}>{item.name}</option>)
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Grid.Col>
                    </Grid.Row>
                </Card.Body>
                <Card.Footer>
                    <div>
                        <Button color="success" loading={showError.busy} icon="save" onClick={() => newClass()}>Create</Button>
                    </div>
                </Card.Footer>
            </Card>
        );
    },
    EditClass: ({ data, close }) => {
        const [store, updateStore] = useStore();
        const [showError, setError] = useState({ display: false, busy: false, type: 'danger', message: '' });

        const [form, setForm] = useState({ name: '', section: '' });
        useEffect(() => {
            if (data && data.id) {
                setForm({ name: data.name, section: data.section_id });
            } else {
                setForm({ name: '', section: '' });
            }
        }, [data]);

        function handleChange(event) {
            const d = { ...form };
            d[event.target.name] = event.target.value;
            setForm(d);
        }


        function updateClass() {
            if (showError.busy) {
                return;
            }
            if (form.name.length > 1) {
                setError({ ...showError, busy: true });
                axios.post(settings.server + '/master/class/update', { name: form.name, id: data.id, section: form.section })
                    .then(res => {
                        if (res.data.success) {
                            const newd = store.class.map(item => {
                                if (item.id == data.id) {
                                    return res.data.data;
                                }
                                return item;
                            });
                            updateStore({ ...store, class: newd });
                            setTimeout(() => close(), 1500);
                            setError({ display: true, busy: false, type: 'success', message: 'Changes saved' });
                        } else {
                            setError({ display: true, busy: false, type: 'danger', message: res.data.message });
                        }
                    }, err => {
                        setError({ display: true, busy: false, type: 'danger', message: 'Network Error. Try again' });
                    });
            } else {
                setError({ display: true, busy: false, type: 'danger', message: 'Enter atleast 2 characters' });
            }
        }

        return <Popup open={data && data.id ? true : false} closeOnDocumentClick={false} onClose={close}>
            <div className="modal">
                <Card>
                    <Card.Header>
                        <Card.Title><Icon name="edit" /> <span className="card-label">Update</span></Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Grid.Row>
                            <Grid.Col>
                                {showError.display ? (<Alert type={showError.type} icon={showError.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                                    {showError.message}
                                </Alert>) : ''}
                                <Form.Group label="Level name" isRequired>
                                    <Form.Input name="name" value={form.name} onChange={handleChange.bind(this)} />
                                </Form.Group>
                                <Form.Group label="Section" isRequired>
                                    <Form.Select name="section" value={form.section} onChange={handleChange.bind(this)}>
                                        <option value=''>Choose a section</option>
                                        {
                                            store.sections.map(item => <option value={item.id} key={item.id}>{item.name}</option>)
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Grid.Col>
                        </Grid.Row>
                    </Card.Body>
                    <Card.Footer>
                        <div>
                            <Button color="secondary" outline onClick={close}>Cancel</Button>
                            <Button color="success" loading={showError.busy} icon="save" onClick={() => updateClass()}>Save</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </div>
        </Popup>;
    },
    NewSection: () => {
        const [store, updateStore] = useStore();
        const [showError, setError] = useState({ display: false, busy: false, type: 'danger', message: '' });

        const [form, setForm] = useState('');

        function handleChange(event) {
            setForm(event.target.value)
        }


        function newClass(data) {
            if (showError.busy) {
                return;
            }
            if (form && form.length > 1) {
                setError({ ...showError, busy: true });
                axios.post(settings.server + '/master/section/create', { name: form })
                    .then(res => {
                        if (res.data.success) {
                            store.sections.push(res.data.data);
                            updateStore({ ...store, sections: store.sections });
                            setForm('');
                            setError({ display: true, busy: false, type: 'success', message: 'Section added' });
                        } else {
                            setError({ display: true, busy: false, type: 'danger', message: res.data.message });
                        }
                    }, err => {
                        setError({ display: true, busy: false, type: 'danger', message: 'Network Error. Try again' });
                    });
            } else {
                setError({ display: true, busy: false, type: 'danger', message: 'Enter atleast 2 characters' });
            }
        }

        return (
            <Card>
                <Card.Header>
                    <Card.Title><Icon name="plus" /> <span className="card-label">Add New Section</span></Card.Title>
                </Card.Header>
                <Card.Body>
                    <Grid.Row>
                        <Grid.Col>
                            {showError.display ? (<Alert type={showError.type} icon={showError.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                                {showError.message}
                            </Alert>) : ''}
                            <Form.Group label="Section name" isRequired>
                                <Form.Input name="class-name" placeholder="Eg: Lower Primary" value={form} onChange={handleChange.bind(this)} />
                            </Form.Group>
                        </Grid.Col>
                    </Grid.Row>
                </Card.Body>
                <Card.Footer>
                    <div>
                        <Button color="success" loading={showError.busy} icon="save" onClick={() => newClass(form)}>Create</Button>
                    </div>
                </Card.Footer>
            </Card>
        );
    },
    removeClass: (id, cb) => {
        axios.post(settings.server + '/class/remove', { id: id })
            .then(res => {
                if (res.data.success) {
                    setTimeout(cb, 100);
                }
            }, err => {

            });
    },
    DelConfirm: ({ data, close }) => {

        function deleteConfirm() {
            close(data);
        }

        return <Popup open={data && data.id ? true : false} closeOnDocumentClick={false} onClose={close}>
            <div className="modal" style={{ width: '500px' }}>
                <Card>
                    <Alert type="danger" style={{ marginBottom: '0px' }}>
                        <Header.H4>Confirm Delete</Header.H4>
                        <p>You will lose all the data associated with this Level. This is a permanent process. You can't recover your data after confirmation.</p>
                        <Button.List>
                            <Button color="secondary" RootComponent="button" onClick={close}>Cancel</Button>
                            <Button color="danger" outline RootComponent="button" onClick={deleteConfirm}>Confirm Delete</Button>
                        </Button.List>
                    </Alert>
                </Card>
            </div>
        </Popup>;
    }
}


export default Class;