import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Grid, Card, Form, List, Button, Badge, Alert, Dropdown } from "tabler-react";
import settings from '../app.config';
import { useStore } from "../utils/Store";
import { useParams } from "react-router-dom";
import { AdminFeedBackQuestions } from './form-admin.data';
import { adminRateScale, hodRateScale, NonExecutiveRateScale, teacherRateScale } from './rateScale.data';
import { HodFeedBackQuestions } from './form-hod.data';
import { TeacherFeedBackQuestions } from './form-teacher.data';
import { NonExecutiveFeedBackQuestions } from './form-non-executive.data';
import ConfirmSubmitFeedbackDialog from './ConfirmSubmitFeedbackDialog';


export function ReviewFeedbackComponent() {
    const { id } = useParams();
    const [getUserStore, setUserStore] = useStore();
    const [getFeedbackData, setFeedbackData] = useState(null);
    const [getFeedbackQuestions, setFeedbackQuestions] = useState([]);
    const [getRating, setRating] = useState([]);
    const [getEnableReview, setEnableReview] = useState(false);
    const [getMyRating, setMyRating] = useState({});
    const [form, setForm] = useState({});
    const [getProfileList, setProfileList] = useState([]);
    const [getSearchProfileList, setSearchProfileList] = useState([]);
    const [showError, setError] = useState({ display: false, busy: false, type: 'danger', message: '' });
    const [rateScale, setRateScale] = useState([]);
    const [getConfirmDialog, setConfirmDialog] = useState(false);

    useEffect(() => {
        loadTeachersList();
        Axios.get(settings.server + '/feedback/' + id)
            .then(res => {
                if (res.data.success) {
                    setFeedbackData(res.data.data);
                    setRating(res.data.reviews);

                    const isAlreadySubmitted = res.data.reviews.find(i => i.submitted_by.id == getUserStore.user.id);
                    setEnableReview(!isAlreadySubmitted);
                    switch (res.data.data.type) {
                        case 'ADMIN':
                            setRateScale(adminRateScale);
                            setFeedbackQuestions(AdminFeedBackQuestions);
                            break;
                        case 'TEACHER':
                            setFeedbackQuestions(TeacherFeedBackQuestions);
                            setRateScale(teacherRateScale);
                            break;
                        case 'HOD':
                            setFeedbackQuestions(HodFeedBackQuestions);
                            setRateScale(hodRateScale);
                            break;
                        case 'NONEXEC':
                            setFeedbackQuestions(NonExecutiveFeedBackQuestions);
                            setRateScale(NonExecutiveRateScale); break;
                    }

                }
            }, err => {
                setFeedbackData(null);
            });
    }, []);

    function loadTeachersList() {
        Axios.get(settings.server + '/user/all')
            .then(res => {
                if (res.data.success) {
                    setProfileList(res.data.data)
                }
            }, err => {

            });
    }


    function feedbackTypeLabel(id) {
        switch (id) {
            case 'ADMIN': return 'ADMIN'; break;
            case 'TEACHER': return 'TEACHER'; break;
            case 'HOD': return 'HOD'; break;
            case 'NONEXEC': return 'NON EXECUTIVE'; break;
        }
    }

    function setRatingData(id, score) {
        setMyRating({ ...getMyRating, ['q' + id]: score });
    }

    function searchProfile(event) {
        const query = event.target.value;
        form[event.target.name] = query;
        setForm({ ...form, profileId: null });
        if (query) {
            setSearchProfileList(getProfileList.filter(item => item.name.toLowerCase().includes(query.toLowerCase())));
        } else {
            setSearchProfileList([]);
        }
    }

    function setProfile(profile) {
        setForm({ ...form, searchName: profile.name, profileId: profile.id });
        setSearchProfileList([]);
    }

    function confirmSubmit(proceed) {
        setConfirmDialog(false);
        if (proceed) {
            saveForm();
        }
    }

    function saveForm() {
        if (Object.values(getMyRating).length !== getFeedbackQuestions.length) {
            setError({ display: true, busy: false, type: 'danger', message: 'You have missed few items to review' });
            return;
        }
        setError({ ...showError, busy: true });
        Axios.post(settings.server + '/feedback/review/update', {
            feedbackId: getFeedbackData.id,
            profileId: getUserStore.user.id,
            reviewUser: form.profileId,
            feedback: getMyRating,
        })
            .then(res => {
                if (res.data.success) {
                    setForm({});
                    setUserStore({ ...getUserStore, review_feedback_count: getUserStore.review_feedback_count - 1 });
                    setError({ display: true, busy: false, type: 'success', message: 'Feedback submitted' });
                } else {
                    setError({ display: true, busy: false, type: 'danger', message: res.data.message });
                }
            }, err => {
                setError({ display: true, busy: false, type: 'danger', message: 'Network Error. Try again' });
            });
    }

    function downloadFile() {
        const XlsxPopulate = require('xlsx-populate');

        const cellsName = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        const fileName = 'Review Feedback  - ' + getFeedbackData.submitted_by;

        XlsxPopulate.fromBlankAsync()
            .then(workbook => {
                const sheetName = 'Sheet1';

                workbook.sheet(sheetName).row(1).height(20);
                workbook.sheet(sheetName).column(1).width(60);
                workbook.sheet(sheetName).cell('A1').value('Review Feedback - Submitted by ' + getFeedbackData.submitted_by).style("bold", true);

                rateScale.forEach((rateScaleItem, i) => {
                    workbook.sheet(sheetName).cell(cellsName[i + 1] + '2').value(rateScaleItem.label);
                    workbook.sheet(sheetName).column(i + 2).width(30);
                });

                getFeedbackQuestions.forEach((item, i) => {
                    const currentRow = (i + 3);
                    workbook.sheet(sheetName).row(currentRow).height(100);
                    workbook.sheet(sheetName).cell('A' + currentRow).value(item.title + '-' + item.description);

                    rateScale.forEach((rateScaleItem, j) => {
                        let cellContent = [];
                        getRating.forEach(rating => {
                            if (rating.rating['q' + i] === rateScaleItem.id) {
                                cellContent.push(rating.submitted_by.name);
                            }
                        });
                        workbook.sheet(sheetName).cell(cellsName[j + 1] + currentRow).value(cellContent.join(','));
                    });
                });

                workbook.outputAsync()
                    .then(function (blob) {
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            // If IE, you must uses a different method.
                            window.navigator.msSaveOrOpenBlob(blob, fileName + ".xlsx");
                        } else {
                            var url = window.URL.createObjectURL(blob);
                            var a = document.createElement("a");
                            document.body.appendChild(a);
                            a.href = url;
                            a.download = fileName + ".xlsx";
                            a.click();
                            window.URL.revokeObjectURL(url);
                            document.body.removeChild(a);
                        }
                    });
            });
    }

    return [<Grid>
        <Grid.Row>
            <Grid.Col sm={12} md={12} >
                {getFeedbackData && <Card>
                    <Card.Header>
                        <Grid.Row justifyContent='space-between' alignItems='center' style={{ width: '100%' }}>
                            <Grid.Col>
                                <Badge color="success" className="mr-4">{feedbackTypeLabel(getFeedbackData.type)}</Badge> Review Feedback - Submitted by&nbsp;<b>{getFeedbackData.submitted_by}</b>.
                            </Grid.Col>
                            <Grid.Col style={{ flexGrow: 0 }}>
                                <Dropdown triggerContent="Export">
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => downloadFile()}>Excel</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Grid.Col>
                        </Grid.Row>
                    </Card.Header>
                    <Card.Body>
                        <Grid.Row>
                            <Grid.Col sm={12} md={12}>
                                <Form.Group label="Select your Superior (Leave empty only if you are the final reviewer)">
                                    <Form.Input disabled={!getEnableReview} placeholder="Search for..." value={form['searchName']} name="searchName" onChange={searchProfile.bind(this)} />

                                    <List.Group className="nav-list add-sub-list" style={{ position: 'relative' }}>
                                        {
                                            getSearchProfileList.map(item => <List.GroupItem className="hover-item" key={item.id} onClick={() => setProfile(item)}>{item.name}</List.GroupItem>)
                                        }
                                    </List.Group>
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col sm={12} md={6}>
                                <Form.Group label="Assessment Year">
                                    <Form.Input value={getFeedbackData.year} disabled />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col sm={12} md={6}>
                                <Form.Group label="Period of Assessment">
                                    <Form.Input value={getFeedbackData.period_of_assessment} disabled />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col sm={12} md={6}>
                                <Form.Group label="NRIC Number (Last 4 characters)">
                                    <Form.Input maxLength='4' value={getFeedbackData.nricNo} disabled />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col sm={12} md={6}>
                                <Form.Group label="Length of Service">
                                    <Form.Input value={getFeedbackData.lengthOfService} disabled />
                                </Form.Group>
                            </Grid.Col>
                        </Grid.Row>
                    </Card.Body>
                </Card>
                }
            </Grid.Col>
        </Grid.Row>
        <Grid.Row>
            {getFeedbackQuestions.map((item, i) => <Grid.Col sm={12} md={12} key={i}>
                <Card>
                    <Card.Header><div><b>{i + 1}. {item.title}</b> -  {item.description}</div></Card.Header>
                    <Card.Body>
                        {rateScale && rateScale.map((rateScaleItem, j) => <Form.Group label={rateScaleItem.label}>
                            <Grid>
                                <Grid.Row gutters='sm'>
                                    {getEnableReview && <Grid.Col sm={1}>
                                        <Form.ColorCheck onChange={() => setRatingData(i, rateScaleItem.id)}>
                                            <Form.ColorCheckItem outline color={rateScaleItem.color} checked={getMyRating['q' + i] === rateScaleItem.id} />
                                        </Form.ColorCheck>
                                    </Grid.Col>
                                    }
                                    <Grid.Col sm={11}>
                                        <div>{item.ratingBenchmark[j]}</div>
                                        <Form.ColorCheck  >
                                            {getRating.map(rating => rating.rating['q' + i] === rateScaleItem.id ? <div style={{ display: 'flex', alignItems: 'center' }}><Form.ColorCheckItem disabled outline color='gray' checked={true} /> By {rating.submitted_by.name} </div> : null)}
                                        </Form.ColorCheck>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Form.Group>
                        )}
                    </Card.Body>
                </Card>
            </Grid.Col>)}
        </Grid.Row>
        <Grid.Row>
            {showError.display ? (<Grid.Col><Alert type={showError.type} icon={showError.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                {showError.message}
            </Alert></Grid.Col>) : ''}
        </Grid.Row>
        <Grid.Row justifyContent='end'>
            <Grid.Col>
                <Card>
                    <Card.Footer>
                        <div>
                            <Button disabled={!getEnableReview} color="success" loading={showError.busy} icon="save" onClick={() => setConfirmDialog(true)}>Submit</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </Grid.Col>
        </Grid.Row>
    </Grid>,
    <ConfirmSubmitFeedbackDialog show={getConfirmDialog} onClose={confirmSubmit} description={!form.profileId ? 'Confirm that you are final reviewer' : null} />]
}