import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Table, Button } from "tabler-react";
import settings from '../app.config';
import { useStore } from "../utils/Store";
const moment = require('moment-timezone');

export function MyFeedbacksComponent() {
    const [getUserStore] = useStore();
    const [getFeedbackList, setFeedbackList] = useState([]);
    let match = useRouteMatch();

    useEffect(() => {
        Axios.get(settings.server + '/user/feedback/' + getUserStore.user.id)
            .then(res => {
                if (res.data.success) {
                    setFeedbackList(res.data.data);
                }
            }, err => {
                setFeedbackList([]);
            });
    }, []);

    return <div className="students-list"><Table>
        <Table.Header>
            <Table.ColHeader>#</Table.ColHeader>
            <Table.ColHeader>Submitted Date</Table.ColHeader>
            <Table.ColHeader>Submitted to</Table.ColHeader>
            <Table.ColHeader></Table.ColHeader>
        </Table.Header>
        <Table.Body>
            {!getFeedbackList.length && <div className="p-5">No records found.</div>}
            {getFeedbackList.map((row, i) => <Table.Row key={row.id}>
                <Table.Col>{i + 1}</Table.Col>
                <Table.Col>{moment(row.ts).tz("Singapore").format('lll')}</Table.Col>
                <Table.Col>{row.user_name}</Table.Col>
                <Table.Col><Link to={`${match.url}/view/${row.id}`}><Button color="success" >View</Button></Link></Table.Col>
            </Table.Row>)
            }
        </Table.Body>
    </Table ></div>
}