import React, { useState, useEffect } from 'react';
import { Icon, Page, Button, Grid, List, Badge } from 'tabler-react';
import AdminFeedbackForm from './AdminFeedbackForm';
import { Switch, Route, Link, useRouteMatch, useHistory } from "react-router-dom";
import { MyFeedbacksComponent } from './MyFeedbacks';
import { PendingFeedbacksComponent } from './PendingReview';
import { ViewFeedbackComponent } from './Feedback';
import { ReviewFeedbackComponent } from './ReviewFeedback';
import { useStore } from '../utils/Store';

const FeedbackForm = {
    Home: () => {
        let history = useHistory();
        let match = useRouteMatch();
        const [store] = useStore();

        function openSection(path) {
            history.replace({ pathname: '/home' + path });
        }

        const routes = [
            {
                id: 'fb-my-reviews',
                route: '/',
                home: FeedbackForm.MyReviews
            },
            {
                id: 'fb-pending-reviews',
                route: '/pending',
                home: FeedbackForm.PendingReviews
            },
            {
                id: 'fb-new',
                route: '/new',
                home: FeedbackForm.CreateFeedback
            },
            {
                id: 'fb-view',
                route: '/view/:id',
                home: FeedbackForm.ViewFeedback
            },
            {
                id: 'fb-submit-review',
                route: '/pending/review/:id',
                home: FeedbackForm.ReviewFeedback
            }
        ]

        const menuList = [
            {
                name: 'Feedbacks',
                id: 'fb-my-reviews',
                path: '/feedback',
            },
            {
                name: 'Reviews',
                id: 'fb-pending-reviews',
                path: '/feedback/pending',
                count: store.review_feedback_count ? <Badge color='warning'>{store.review_feedback_count}</Badge> : ''
            }
        ]

        return <div>
            <div className="title-bar">
                <Page.Title>
                    <Icon name="check" /> Feedback Form
                </Page.Title>
                <Button.List align="right">
                    <Link to='/home/feedback/new'>
                        <Button color="primary">
                            <Icon name="plus" /> New Feedback
                        </Button>
                    </Link>
                </Button.List>
            </div>
            <Grid>
                <Grid.Row className="master-container">
                    <Grid.Col>
                        <List.Group className="nav-list">
                            {menuList.map(item =>
                                <List.GroupItem action active id={item.id} key={item.id} className="item feedback-nav-list" onClick={() => openSection(item.path)}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                        <div>{item.name}</div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {item.count}
                                            <Icon name="chevron-right" className="hover-icon" />
                                        </div>
                                    </div>
                                </List.GroupItem>
                            )
                            }
                        </List.Group>
                    </Grid.Col>
                    <Grid.Col>
                        <Switch>
                            {routes.map(item =>
                                <Route exact path={`${match.path}${item.route}`}>
                                    <item.home />
                                </Route>
                            )}
                        </Switch>
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </div>
    },
    CreateFeedback: () => {
        useEffect(() => {
            const el = document.getElementsByClassName('feedback-nav-list');
            for (const element in el) {
                const node = el[element];
                if (node.classList) { node.classList.remove('active'); }
            };
        }, []);
        return <div>
            <AdminFeedbackForm.Home />
        </div>
    },
    MyReviews: () => {
        useEffect(() => {
            const el = document.getElementsByClassName('feedback-nav-list');
            for (const element in el) {
                const node = el[element];
                if (node.classList) { node.classList.remove('active'); }
            };

            const actEl = document.getElementById('fb-my-reviews');
            if (actEl) { actEl.classList.add('active'); }
        }, []);

        return <div>
            <MyFeedbacksComponent />
        </div>
    },
    PendingReviews: () => {
        useEffect(() => {
            const el = document.getElementsByClassName('feedback-nav-list');
            for (const element in el) {
                const node = el[element];
                if (node.classList) { node.classList.remove('active'); }
            };

            const actEl = document.getElementById('fb-pending-reviews');
            if (actEl) { actEl.classList.add('active'); }
        }, []);

        return <div>
            <PendingFeedbacksComponent />
        </div>
    },
    ViewFeedback: () => {
        return <div>
            <ViewFeedbackComponent />
        </div>
    },
    ReviewFeedback: () => {
        return <div>
            <ReviewFeedbackComponent />
        </div>
    }
}

export default FeedbackForm;