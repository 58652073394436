import React, { useState, useEffect } from 'react';
import { Grid, Button, Page, Icon, Card, Form, List, Alert } from 'tabler-react';
import { useStore } from '../utils/Store';
import axios from 'axios';
import settings from '../app.config';
import { Link } from 'react-router-dom';
import { AdminFeedBackQuestions } from './form-admin.data';
import { adminRateScale, hodRateScale, NonExecutiveRateScale, teacherRateScale } from './rateScale.data';
import { HodFeedBackQuestions } from './form-hod.data';
import { TeacherFeedBackQuestions } from './form-teacher.data';
import { NonExecutiveFeedBackQuestions } from './form-non-executive.data';
import ConfirmSubmitFeedbackDialog from './ConfirmSubmitFeedbackDialog';



const AdminFeedbackForm = {
    Home: () => {
        const [store, updateStore] = useStore();
        const [getRating, setRating] = useState({});
        const [form, setForm] = useState({});
        const [getProfileList, setProfileList] = useState([]);
        const [getSearchProfileList, setSearchProfileList] = useState([]);
        const [showError, setError] = useState({ display: false, busy: false, type: 'danger', message: '' });
        const [getFeedbackQuestions, setFeedbackQuestions] = useState([]);
        const [rateScale, setRateScale] = useState({});
        const [getConfirmDialog, setConfirmDialog] = useState(false);

        useEffect(() => {
            loadTeachersList();
        }, []);

        function loadTeachersList() {
            axios.get(settings.server + '/user/all')
                .then(res => {
                    if (res.data.success) {
                        setProfileList(res.data.data)
                    }
                }, err => {

                });
        }

        function setRatingData(id, score) {
            setRating({ ...getRating, ['q' + id]: score });
        }

        function updateFormControl(event) {
            const formType = event.target.value;
            const controlName = event.target.name;
            form[controlName] = formType;
            setForm({ ...form });

            if (controlName === 'type') {
                setFeedbackQuestions([]);
                setRateScale({});

                switch (formType) {
                    case 'ADMIN':
                        setFeedbackQuestions(AdminFeedBackQuestions);
                        setRateScale(adminRateScale);
                        break;
                    case 'TEACHER':
                        setFeedbackQuestions(TeacherFeedBackQuestions);
                        setRateScale(teacherRateScale);
                        break;
                    case 'HOD':
                        setFeedbackQuestions(HodFeedBackQuestions);
                        setRateScale(hodRateScale);
                        break;
                    case 'NONEXEC':
                        setFeedbackQuestions(NonExecutiveFeedBackQuestions);
                        setRateScale(NonExecutiveRateScale);
                        break;
                }
            }
        }

        function searchProfile(event) {
            const query = event.target.value;
            form[event.target.name] = query;
            setForm({ ...form, profile: null })
            if (query) {
                setSearchProfileList(getProfileList.filter(item => item.name.toLowerCase().includes(query.toLowerCase())));
            } else {
                setSearchProfileList([]);
            }

        }

        function setProfile(profile) {
            setForm({ ...form, searchName: profile.name, profile });
            setSearchProfileList([]);
        }

        function confirmSubmit(proceed) {
            setConfirmDialog(false);
            if (proceed) {
                saveForm();
            }
        }

        function saveForm() {
            if (!form.profile) {
                setError({ display: true, busy: false, type: 'danger', message: 'Please select a reviewer' });
                return;
            }
            if (Object.values(getRating).length !== getFeedbackQuestions.length) {
                setError({ display: true, busy: false, type: 'danger', message: 'You have missed few items to review' });
                return;
            }
            setError({ ...showError, busy: true });
            axios.post(settings.server + '/feedback/create', {
                profileId: store.user.id,
                reviewUser: form.profile.id,
                profileName: form.profile.name,
                assessmentYear: form.assessmentYear,
                periodOfAssessment: form.periodOfAssessment,
                nricNo: form.nricNo,
                lengthOfService: form.lengthOfService,
                feedback: getRating,
                type: form.type
            })
                .then(res => {
                    if (res.data.success) {
                        setForm({});
                        setRating({});
                        setError({ display: true, busy: false, type: 'success', message: 'Feedback added' });
                    } else {
                        setError({ display: true, busy: false, type: 'danger', message: res.data.message });
                    }
                }, err => {
                    setError({ display: true, busy: false, type: 'danger', message: 'Network Error. Try again' });
                });
        }

        return [<div>
            <Grid>
                <Grid.Row>
                    {showError.display ? (<Grid.Col sm={12} md={12} ><Alert type={showError.type} icon={showError.type === 'success' ? 'check' : 'alert-triangle'} isDismissible>
                        {showError.message}
                    </Alert></Grid.Col>) : ''}
                </Grid.Row>
                <Grid.Row>
                    <Grid.Col sm={12} md={12} >
                        <Card>
                            <Card.Header>Submit Feedback</Card.Header>
                            <Card.Body>
                                <Grid.Row>
                                    <Grid.Col sm={12} md={6}>
                                        <Form.Group label="Select a Feedback Form">
                                            <Form.Select value={form.type} name="type" onChange={updateFormControl.bind(this)}>
                                                <option value=''>Choose a form type</option>
                                                <option value='ADMIN'>Admin</option>
                                                <option value='HOD'>HOD</option>
                                                <option value='TEACHER'>Teacher</option>
                                                <option value='NONEXEC'>Non Executive</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col sm={12} md={6}>
                                        <Form.Group label="Select your Superior">
                                            <Form.Input placeholder="Search for..." value={form['searchName']} name="searchName" onChange={searchProfile.bind(this)} />

                                            <List.Group className="nav-list add-sub-list" style={{ position: 'relative' }}>
                                                {
                                                    getSearchProfileList.map(item => <List.GroupItem className="hover-item" key={item.id} onClick={() => setProfile(item)}>{item.name}</List.GroupItem>)
                                                }
                                            </List.Group>
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col sm={12} md={6}>
                                        <Form.Group label="Assessment Year">
                                            <Form.Input value={form['assessmentYear']} name="assessmentYear" onChange={updateFormControl.bind(this)} />
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col sm={12} md={6}>
                                        <Form.Group label="Period of Assessment">
                                            <Form.Input value={form['periodOfAssessment']} name="periodOfAssessment" onChange={updateFormControl.bind(this)} />
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col sm={12} md={6}>
                                        <Form.Group label="NRIC Number (Last 4 characters)">
                                            <Form.Input value={form['nricNo']} maxLength='4' name="nricNo" onChange={updateFormControl.bind(this)} />
                                        </Form.Group>
                                    </Grid.Col>
                                    <Grid.Col sm={12} md={6}>
                                        <Form.Group label="Length of Service">
                                            <Form.Input value={form['lengthOfService']} name="lengthOfService" onChange={updateFormControl.bind(this)} />
                                        </Form.Group>
                                    </Grid.Col>
                                </Grid.Row>
                            </Card.Body>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
                {form.profile && form.profile.id && form.type && [<Grid.Row>
                    {getFeedbackQuestions.map((item, i) => <Grid.Col sm={12} md={12} key={i}>
                        <Card>
                            <Card.Header><div><b>{i + 1}. {item.title}</b> -  {item.description}</div></Card.Header>
                            <Card.Body>
                                {rateScale.map((rateScaleItem, j) => <Form.Group label={rateScaleItem.label}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Col sm={1}>
                                                <Form.ColorCheck onChange={() => setRatingData(i, rateScaleItem.id)}>
                                                    <Form.ColorCheckItem outline color={rateScaleItem.color} checked={getRating['q' + i] === rateScaleItem.id} />
                                                </Form.ColorCheck>
                                            </Grid.Col>
                                            <Grid.Col sm={11}>
                                                {item.ratingBenchmark[j]}
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Grid>
                                </Form.Group>
                                )}
                            </Card.Body>
                        </Card>
                    </Grid.Col>)}
                </Grid.Row>,
                <Grid.Row justifyContent='end'>
                    <Grid.Col>
                        <Card>
                            <Card.Footer>
                                <div>
                                    <Button color="success" loading={showError.busy} icon="save" onClick={() => setConfirmDialog(true)}>Submit</Button>
                                </div>
                            </Card.Footer>
                        </Card>
                    </Grid.Col>
                </Grid.Row>]
                }
            </Grid>
        </div>,
        <ConfirmSubmitFeedbackDialog show={getConfirmDialog} onClose={confirmSubmit} />
        ]
    }
}


export default AdminFeedbackForm;